import { LanguageSets, getLangCode } from '../../utils/getLangCode.js'

const yearStartingOnMonday = 2024

const getMonthNames = (locale: string) => {
  const format = new Intl.DateTimeFormat(locale, { month: 'long' })
  return Array.from({ length: 12 }, (_, month) => format.format(new Date(yearStartingOnMonday, month, 1, 0, 0, 0)))
}

// ISO week based order (starting with Monday)
const getWeekdayNames = (locale: string): weekdayName[] => {
  const shortWeekday = new Intl.DateTimeFormat(locale, { weekday: 'short' })
  const longWeekday = new Intl.DateTimeFormat(locale, { weekday: 'long' })
  return Array.from({ length: 7 }, (_, i) => {
    const dateBasis = new Date(yearStartingOnMonday, 0, 1 + i, 0, 0, 0)
    return {
      short: shortWeekday.format(dateBasis),
      long: longWeekday.format(dateBasis)
    }
  })
}

export type LanguageSet = {
  datePicker: string
  calendar: string
  months: string[]
  week: string
  weekdays: weekdayName[]
  todaysDate: string
  notSelectable: string

  noDateSelected: string
  noDateRangeSelected: string
  selectedDate: string
  selectedDateRange: string
  selectionOfDateRangeStarted: string

  clear: string
  preview: {
    isoFormatPlaceholder: string
    dateRangeStart: string
    dateRangeEnd: string
    date: string
  }
}

export type weekdayName = {
  short: string
  long: string
}

const languages: LanguageSets<LanguageSet> = {
  en: {
    datePicker: 'Date picker',
    calendar: 'Calendar',
    months: getMonthNames('en'),
    week: 'Week',
    weekdays: getWeekdayNames('en'),
    todaysDate: "Today's date",
    notSelectable: 'Not selectable',

    noDateSelected: 'No date selected',
    noDateRangeSelected: 'No date range selected',
    selectedDate: 'Selected date',
    selectedDateRange: 'Selected date range',
    selectionOfDateRangeStarted: 'Selection of date range started, selected first date',

    clear: 'Clear',
    preview: {
      isoFormatPlaceholder: 'YYYY-MM-DD',
      dateRangeStart: 'Date range start',
      dateRangeEnd: 'Date range end',
      date: 'Date'
    }
  },

  sv: {
    datePicker: 'Datumväljare',
    calendar: 'Kalender',
    months: getMonthNames('sv'),
    week: 'Vecka',
    weekdays: getWeekdayNames('sv'),
    todaysDate: 'Dagens datum',
    notSelectable: 'Ej valbar',

    noDateSelected: 'Inget datum valt',
    noDateRangeSelected: 'Inget datumintervall valt',
    selectedDate: 'Valt datum',
    selectedDateRange: 'Valt datumintervall',
    selectionOfDateRangeStarted: 'Val av datumintervall påbörjat, valt första datum',

    clear: 'Rensa',
    preview: {
      isoFormatPlaceholder: 'ÅÅÅÅ-MM-DD',
      dateRangeStart: 'Datumintervall start',
      dateRangeEnd: 'Datumintervall slut',
      date: 'Datum'
    }
  },

  nb: {
    datePicker: 'Datovelger',
    calendar: 'Kalender',
    months: getMonthNames('nb'),
    week: 'Uke',
    weekdays: getWeekdayNames('nb'),
    todaysDate: 'Dagens dato',
    notSelectable: 'Ikke valgbar',

    noDateSelected: 'Ingen dato valgt',
    noDateRangeSelected: 'Ingen datoperiode valgt',
    selectedDate: 'Valgt dato',
    selectedDateRange: 'Valgt datoperiode',
    selectionOfDateRangeStarted: 'Valg av datoperiode startet, første dato valgt',

    clear: 'Klar',
    preview: {
      isoFormatPlaceholder: 'ÅÅÅÅ-MM-DD',
      dateRangeStart: 'Datoperiode start',
      dateRangeEnd: 'Datoperiode slutt',
      date: 'Dato'
    }
  },

  fi: {
    datePicker: 'Päivämäärän valitsin',
    calendar: 'Kalenteri',
    months: getMonthNames('fi'),
    week: 'Viikko',
    weekdays: getWeekdayNames('fi'),
    todaysDate: 'Tämän päivän päivämäärä',
    notSelectable: 'Ei valittavissa',

    noDateSelected: 'Päivämäärää ei ole valittu',
    noDateRangeSelected: 'Ajanjaksoa ei ole valittu',
    selectedDate: 'Valittu päivämäärä',
    selectedDateRange: 'Valittu ajanjakso',
    selectionOfDateRangeStarted: 'Ajanjakson valinta aloitettu, ensimmäinen päivämäärä valittu',

    clear: 'Selkeä',
    preview: {
      isoFormatPlaceholder: 'VVVV-KK-PP',
      dateRangeStart: 'Ajanjakson alku',
      dateRangeEnd: 'Ajanjakson loppu',
      date: 'Päivämäärä'
    }
  },

  da: {
    datePicker: 'Datovælger',
    calendar: 'Kalender',
    months: getMonthNames('da'),
    week: 'Uge',
    weekdays: getWeekdayNames('da'),
    todaysDate: 'Dagens dato',
    notSelectable: 'Ikke valgbar',

    noDateSelected: 'Ingen dato valgt',
    noDateRangeSelected: 'Intet datointerval valgt',
    selectedDate: 'Valgt dato',
    selectedDateRange: 'Valgt datointerval',
    selectionOfDateRangeStarted: 'Valg af datointerval startet, første dato valgt',

    clear: 'Klar',
    preview: {
      isoFormatPlaceholder: 'ÅÅÅÅ-MM-DD',
      dateRangeStart: 'Datointerval start',
      dateRangeEnd: 'Datointerval slut',
      date: 'Dato'
    }
  }
}

export const getLanguage = (element: Element): { translations: LanguageSet; lang: string } => {
  const closestLang = getLangCode(element)
  const translations = languages[closestLang]

  return { translations, lang: closestLang }
}
