import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { styleMap } from 'lit/directives/style-map.js'
import { OneUxElement } from '../../OneUxElement.js'
import { FocusableFactory } from '../../mixins/Focusable.js'
import { Implicit } from '../../mixins/Implicit.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { classMap } from 'lit/directives/class-map.js'

import { style } from './style.js'
import { getLanguage } from './lang.js'
import { toastIcon } from './types.js'
import { SlotController } from '../../controllers/SlotController.js'
import { Label } from '../../mixins/Label.js'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)

const BaseClass = Label(Implicit(Focusable(Styled(OneUxElement))))

/**
 * A brief message displayed in the corner of the screen, can include simple actions.
 */
@customElement('one-ux-toast')
export class OneUxToastElement extends BaseClass {
  @property({ type: String })
  public accessor type!: 'confirmation' | 'info' | 'warning'

  @property({ type: Number })
  public accessor progress!: number

  constructor() {
    super()
    this.width = 'max'
  }

  protected guardedRender() {
    const { translations } = getLanguage(this)
    const icon = this.#getIconConfig()

    return html`<div
      role="alert"
      aria-live="assertive"
      class=${classMap({
        'one-ux-element--root toast': true,
        'has-body-slot-content': this.#slots.hasDefaultSlot(),
        'has-actions-slot-content': this.#slots.hasNamedSlot('action')
      })}
    >
      <div class="toast--contents">
        <div class="toast--icon">
          <one-ux-icon
            size="400"
            icon=${icon.icon}
            set=${icon.set}
            style=${styleMap({ color: `var(${icon.palette})` })}
            pdr-test-hook=${icon.pdrTestHook}
          ></one-ux-icon>
        </div>
        <div class="toast--header one-ux-typography--heading-300" role="heading">${this.label}</div>
        <div class="toast--body one-ux-typography--body-300">
          <slot></slot>
        </div>

        <one-ux-widget-button
          class="toast--close"
          pdr-test-hook="close-button"
          @click=${() => this.dispatchEvent(new CustomEvent('close'))}
          icon="close"
          accessible
          label=${translations.close}
        ></one-ux-widget-button>

        <div class="toast--actions">
          <slot name="action"></slot>
        </div>
      </div>

      <div class="toast--progress" style=${styleMap({ width: `${this.progress ?? 0}%` })}></div>
    </div>`
  }

  #getIconConfig(): toastIcon {
    switch (this.type) {
      case 'confirmation':
        return {
          set: 'status',
          icon: 'done',
          palette: '--one-ux-palette--mint-400',
          pdrTestHook: 'icon-confirmation'
        }
      case 'info':
        return {
          set: 'default',
          icon: 'info',
          palette: '--one-ux-palette--brand-neutral-600',
          pdrTestHook: 'icon-info'
        }
      case 'warning':
        return {
          set: 'default',
          icon: 'warning',
          palette: '--one-ux-palette--red-500',
          pdrTestHook: 'icon-warning'
        }
      default:
        return {
          set: 'status',
          icon: 'done',
          palette: '--one-ux-palette--mint-400',
          pdrTestHook: 'icon-confirmation'
        }
    }
  }

  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['action']
  })
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-toast': OneUxToastElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-toast': OneUxToastElement
    }
  }
}
