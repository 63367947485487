import { OneUxElement } from '../../OneUxElement.js'
import { Constructor } from '../../utils.js'
import { property } from 'lit/decorators.js'

export interface IFieldSetProps {
  columns: number
}

export const FieldSetProps = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class FieldSetPropsClass extends SuperClass {
    /**
     * Defines the number of columns available in the field set.
     */
    @property({ type: Number, reflect: false })
    public accessor columns = 1
  }
  return FieldSetPropsClass as Constructor<IFieldSetProps> & TSuperClass
}
