import { html, TemplateResult } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import type { GroupData, OptionData } from '../types.js'
import { Icon } from './Icon.js'
import type { MenuItemOptions } from './MenuItem.js'
import { spread } from '@open-wc/lit-helpers'
import { keyCodes } from '../../../utils.js'
import { OneUxMenuElement } from '../OneUxMenuElement.js'
import { KeyboardNavigationHandler } from '../KeyboardNavigationHandler.js'

export function Option(this: OneUxMenuElement, { item, itemTypeIndex }: MenuItemOptions<OptionData>): TemplateResult {
  const isDisabled = item.disabled || this.disabled

  const handleAction = () => {
    if (isDisabled) return

    item.action?.()

    this.dispatchEvent(new CustomEvent('option', { detail: item.value, composed: true }))
    this._popoutContext.closePopout()
  }

  const handleKeydown = (event: KeyboardEvent & { target: HTMLElement }) => {
    if (this.disabled) return

    const navigationHandler = new KeyboardNavigationHandler(this, event)

    const tryGoToLink = () => {
      if (item.url) {
        event.target.click()
        event.preventDefault()
        event.stopPropagation()
      }
    }

    switch (event.code) {
      case keyCodes.SPACE:
        return tryGoToLink()
      case keyCodes.UP:
        return navigationHandler.tryStep('up')
      case keyCodes.DOWN:
        return navigationHandler.tryStep('down')
      case keyCodes.LEFT:
        return navigationHandler.tryCloseGroup(item, 'preventAlways')
      case keyCodes.ESCAPE:
        return navigationHandler.tryCloseGroup(item, 'preventOnClose')
    }
  }

  const handleMouseMove = (event: MouseEvent & { target: HTMLElement }) => {
    if (this._hasFocus) {
      event.target?.focus()
    }

    this._collapseItems()

    let group: GroupData | undefined = item.parent
    while (group) {
      group.expanded = true
      group = group.parent
    }

    this.requestUpdate()
  }

  const attrs = {
    role: 'menuitem',
    tabindex: '-1',
    'one-ux-tooltip': item.tooltip || undefined,
    'one-ux-tooltip-fixed': !!item.tooltip,
    'one-ux-tooltip-placement': item.tooltip ? 'after' : undefined,
    'aria-disabled': isDisabled,
    'pdr-test-hook': `one-ux-menu-option-${itemTypeIndex}`,
    '@keydown': handleKeydown,
    '@mousedown': (event: MouseEvent) => {
      if (isDisabled) event.preventDefault()
    },
    '@mousemove': handleMouseMove
  }

  return item.url
    ? html`<a
        href=${ifDefined(isDisabled ? undefined : item.url)}
        target=${ifDefined(item.urlTarget === 'new' ? '_blank' : undefined)}
        ${spread(attrs)}
      >
        ${Icon({ name: 'external-link' })} ${item.text}
      </a>`
    : html`<button type="button" @click=${() => !isDisabled && handleAction()} ${spread(attrs)}>
        ${Icon(item.icon)} ${item.text}
      </button>`
}
