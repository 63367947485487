import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { createRef, Ref, ref } from 'lit/directives/ref.js'
import { OneUxElement } from '../../../OneUxElement.js'
import { scrollElementIntoView } from '../../../utils.js'
import { Optional } from '../../../types.js'

import { NodeData, RootCallbacks } from './types.js'
import { style } from './style.js'

import { TreeNode } from './fragments/TreeNode.js'
import { treeContext } from '../ITreeContext.js'
import { Implicit } from '../../../mixins/Implicit.js'
import { StyledFactory } from '../../../mixins/Styled.js'
import { Focusable } from '../../../mixins/Focusable.js'
import { Disabled } from '../../../mixins/Disabled.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { TreeKeyboardHandler } from './TreeKeyboardHandler.js'
import { ContextConsumer } from '@lit/context'
import { defer } from '../../../utils/function-utils.js'

const Styled = StyledFactory(style)
const BaseClass = Disabled(Focusable(Implicit(Styled(OneUxElement))))

export type RootRefs = {
  $tree: Ref<HTMLElement>
}

/**
 * A tree building block primarily intended for internal use.
 * Can be used externally, but requires a context.
 * See `<one-ux-context-provider>` for details.
 */
@customElement('contextual-one-ux-tree')
export class ContextualOneUxTreeElement extends BaseClass {
  #state = new ContextConsumer(this, {
    context: treeContext,
    subscribe: true
  })

  /*
   * Allows you to override the default content render of tree nodes.
   */
  @property({ attribute: false })
  public accessor nodeContentOverride: Optional<(node: NodeData) => unknown>

  protected render() {
    if (!this.#state.value) {
      return
    }

    const keyboardHandler = new TreeKeyboardHandler({
      $tree: this.#refs.$tree,
      activeNode: this.#state.value!.activeNode,
      tree: this.#state.value!.tree,
      callbacks: this.#callbacks
    })
    return html`
      <one-ux-scroll class="one-ux-element--root">
        <div
          ${ref(this.#refs.$tree)}
          role="tree"
          class="tree"
          aria-activedescendant=${ifDefined(this.#state.value!.activeNode ? 'active-tree-node' : undefined)}
          tabindex=${ifDefined(!this.disabled ? 0 : undefined)}
          @keydown=${keyboardHandler.handleKeydown}
        >
          ${this.#state.value!.tree.map((node) =>
            TreeNode({
              refs: this.#refs,
              callbacks: this.#callbacks,
              node,
              depth: 0,
              multiple: this.#state.value!.multiple,
              treeDisabled: this.disabled,
              activeNode: this.#state.value!.activeNode,
              contentOverride: this.nodeContentOverride || null
            })
          )}
        </div>
      </one-ux-scroll>
    `
  }

  protected updated() {
    this.#scrollToActiveNode()
  }

  #nodeSelect = (node: NodeData) => {
    if (node.value && !node.disabled) {
      this.#state.value!.setValue(node)
      this.dispatchEvent(new Event('input'))
    }
  }

  #nodeExpand = (node: NodeData) => {
    if (!node.expanded && !node.children?.length) {
      return
    }
    node.expanded = !node.expanded
    this.requestUpdate()
  }

  #nodeActive = (node: NodeData) => {
    this.#state.value!.setActiveNode(node)
  }

  #scrollToActiveNode = () => {
    defer(() => {
      if (this.#refs.$tree.value) {
        const $active: HTMLElement | null = this.#refs.$tree.value!.querySelector(`#active-tree-node > .tree-node--row`)
        if ($active) {
          const $scroll = $active.closest('one-ux-scroll')
          if ($scroll) {
            scrollElementIntoView($scroll, $active)
          }
        }
      }
    })
  }

  #refs: RootRefs = {
    $tree: createRef()
  }

  #callbacks: RootCallbacks = {
    onNodeToggleExpand: this.#nodeExpand,
    onNodeToggleSelect: this.#nodeSelect,
    onChangeActiveNode: this.#nodeActive
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'contextual-one-ux-tree': ContextualOneUxTreeElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'contextual-one-ux-tree': ContextualOneUxTreeElement
    }
  }
}
