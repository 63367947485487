import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Weight } from '../../mixins/Weight.js'
import { Optional } from '../../types.js'
import { PurposeFactory } from '../../mixins/Purpose.js'

const Styled = StyledFactory(style)
const Purpose = PurposeFactory({
  purposes: ['default', 'main', 'caution', 'promote'],
  deprecatedDefault: 'promote'
})

const BaseClass = Purpose(Weight(Styled(OneUxElement)))

const NUMBER_CAP_VALUE = 999

@customElement('one-ux-pill')
export class OneUxPillElement extends BaseClass {
  @property({ type: Number })
  public accessor number: Optional<number>

  @property({ type: Boolean })
  public accessor pulse: boolean = false

  render() {
    const text = this.#outputNumber || (this.purpose === 'caution' ? '!' : '')

    return html`<div
      class=${classMap({
        'one-ux-element--root': true,
        'is-empty': !text,
        'is-single-character': text.length === 1,
        pulse: this.pulse
      })}
    >
      <span aria-hidden="true">${text}</span>
      <span class="one-ux-accessibility--screen-reader">${this.#accessabilityOutput}</span>
    </div>`
  }

  get #hasValidNumber() {
    return this.number != null && this.number > 0
  }

  get #outputNumber() {
    if (!this.#hasValidNumber) return ''
    return this.number! > NUMBER_CAP_VALUE ? `${NUMBER_CAP_VALUE}+` : String(this.number!)
  }

  get #accessabilityOutput() {
    if (this.purpose == 'caution') return `(${this.#hasValidNumber ? this.number! : ''}!)`
    return `(${this.#hasValidNumber ? this.number! : '*'})`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-pill': OneUxPillElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-pill': OneUxPillElement
    }
  }
}
