import { Version } from './Version.js'

export class NullVersion extends Version {
  constructor() {
    super('', '')
  }
  fulfills(): boolean {
    return false
  }
}
