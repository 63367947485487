import { html } from 'lit'

export interface IPromptModalData {
  lang: 'sv' | 'en'
  type: 'alert' | 'confirm'
  header: string
  content: string | HTMLElement
  ok: string
  okIcon: string
  cancel?: string
  cancelIcon?: string
  open: boolean
  danger?: boolean
}

export function PromptModal(
  options: IPromptModalData & {
    onClose: (result: boolean) => void
  }
) {
  const confirm = options.type === 'confirm'

  return html`<one-ux-dialog
    id="__PDR_prompt_modal__"
    style="top: 20%; width: 95%; max-width: 480px; max-height: 60%;"
    @close=${() => options.onClose(false)}
  >
    <one-ux-text slot="header" lang="${options.lang}">${options.header || html`&nbsp;`}</one-ux-text>
    <div slot="content">
      ${options.content instanceof HTMLElement
        ? options.content
        : html`<p style="white-space: pre-wrap">${options.content}</p>`}
    </div>
    ${!confirm
      ? null
      : html`<one-ux-button
          slot="footer-end"
          lang=${options.lang}
          label=${options.cancel!}
          @click=${() => {
            options.onClose(false)
          }}
        >
          ${ButtonContent(options.cancel!, options.cancelIcon)}
        </one-ux-button>`}
    <one-ux-button
      slot="footer-end"
      lang="${options.lang}"
      purpose=${confirm && options.danger ? 'caution' : 'main'}
      label=${options.ok}
      @click=${() => {
        options.onClose(true)
      }}
    >
      ${ButtonContent(options.ok, options.okIcon)}
    </one-ux-button>
  </one-ux-dialog>`
}

function ButtonContent(text: string, icon?: string | { sheet: string; icon: string }) {
  if (!icon) {
    return text
  }
  if (typeof icon === 'string') {
    icon = { sheet: 'default', icon: icon }
  }
  return html`<one-ux-icon set="${icon!.sheet as any}" icon="${icon!.icon as any}" slot="start"></one-ux-icon>${text}`
}
