import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    --one-ux-interactive-content-element--indicator-thickness: var(--one-ux-border-width--500);

    border-radius: var(--one-ux-radius--300) var(--one-ux-radius--300) var(--one-ux-radius--100)
      var(--one-ux-radius--100);

    transition-property: background, border-radius;
    transition-duration: var(--one-ux-duration--400);
    position: relative;
    padding-bottom: var(--one-ux-interactive-content-element--indicator-thickness);
  }

  .one-ux-element--root.empty {
    background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.03);
  }

  :host([state-keyboard-focus]) .one-ux-element--root:focus-within {
    background: var(--one-ux-palette--keyboard-focus-200);
  }

  .indicator {
    --one-ux-interactive-content-element--done-opacity-end: 0;
    --one-ux-interactive-content-element--indicator-color-during-animation: var(--one-ux-palette--brand-neutral-300);

    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    position: absolute;
    pointer-events: none;
    opacity: 0;
    transition-property: opacity, background;
    transition-duration: var(--one-ux-duration--400);
    transition-timing-function: var(--one-ux-easing--default);

    height: var(--one-ux-interactive-content-element--indicator-thickness);
    bottom: 0;
    left: 0;
    right: 0;

    .one-ux-element--root:is(.empty, :hover, :focus-within, .errors) & {
      opacity: 1;
    }

    .one-ux-element--root.empty & {
      --one-ux-interactive-content-element--done-opacity-end: 1;
      background: var(--one-ux-palette--brand-neutral-300);
    }

    .one-ux-element--root.errors & {
      --one-ux-interactive-content-element--done-opacity-end: 1;
      background: var(--one-ux-palette--red-400) !important;
    }

    .one-ux-element--root:hover & {
      background: var(--one-ux-palette--brand-neutral-400);
    }

    .one-ux-element--root:focus-within & {
      background: var(--one-ux-palette--focus-400);
    }

    :host([state-keyboard-focus]) .one-ux-element--root:focus-within & {
      background: var(--one-ux-palette--keyboard-focus-400);
    }

    .one-ux-element--root.busy & {
      --one-ux-interactive-content-element--indicator-color-during-animation: var(--one-ux-palette--focus-400);
      animation:
        busy-opacity 1.5s linear infinite,
        busy-edges 3s var(--one-ux-easing--default) infinite;
    }

    .one-ux-element--root.done & {
      --one-ux-interactive-content-element--indicator-color-during-animation: var(--one-ux-palette--mint-400);
      animation: done 1s var(--one-ux-easing--default);
    }
  }

  .one-ux-element--root:not(:hover, :focus-within) .errors-popout {
    visibility: hidden;
    pointer-events: none;
  }

  ::slotted(*) {
    margin: 0 !important;
  }

  @keyframes busy-opacity {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes busy-edges {
    0% {
      background: var(--one-ux-interactive-content-element--indicator-color-during-animation);
      left: 0%;
      right: 100%;
    }
    8% {
      left: 0%;
    }
    35% {
      right: 0%;
    }
    50% {
      left: 100%;
      right: 0%;
    }
    58% {
      right: 0%;
    }
    85% {
      left: 0%;
    }
    100% {
      background: var(--one-ux-interactive-content-element--indicator-color-during-animation);
      left: 0%;
      right: 100%;
    }
  }

  @keyframes done {
    0% {
      background: var(--one-ux-interactive-content-element--indicator-color-during-animation);
      left: 50%;
      right: 50%;
      opacity: 0;
    }
    50% {
      left: 0%;
      right: 0%;
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      background: var(--one-ux-interactive-content-element--indicator-color-during-animation);
      opacity: var(--one-ux-interactive-content-element--done-opacity-end);
    }
  }
`
