import { OneUxToastElement } from '../../../one-ux/elements/one-ux-toast/OneUxToastElement.js'
import { Toast } from '../Toast.js'
import { animationFrameTimer } from './animation-frame-timing.js'

export const animate = async (toast: Toast) => {
  const $toastElement = toast.$ref.value as OneUxToastElement
  if (!$toastElement) {
    return false
  }

  const duration = Number(toast.duration)
  await animationFrameTimer((elapsed) => {
    $toastElement.progress = Math.min((elapsed / duration) * 100, 100)
    return $toastElement.isConnected
  }, duration)

  return $toastElement.isConnected
}
