import { LanguageSets } from '../../utils/getLangCode.js'

export type LanguageSet = {
  automatic: string
  transparent: string
}

export const lang: LanguageSets<LanguageSet> = {
  en: {
    automatic: 'Automatic color selection',
    transparent: 'Transparent'
  },
  sv: {
    automatic: 'Automatiskt färgval',
    transparent: 'Genomskinlig'
  },
  nb: {
    automatic: 'Automatisk fargevalg',
    transparent: 'Gjennomsiktig'
  },
  fi: {
    automatic: 'Automaattinen värin valinta',
    transparent: 'Läpinäkyvä'
  },
  da: {
    automatic: 'Automatisk farvevalg',
    transparent: 'Gennemsigtig'
  }
}
