export class InternalValueChangedEvent extends Event {
  static get eventName() {
    return 'internal-one-ux-value-changed'
  }

  constructor() {
    super(InternalValueChangedEvent.eventName, {
      bubbles: true,
      cancelable: false,
      composed: true
    })
  }
}
