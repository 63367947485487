import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement.js'
import { Constructor } from '../utils.js'
import { LanguageSets, getLangCode, langCode } from '../utils/getLangCode.js'

export declare class IBusy {
  busy: boolean
  get done(): boolean
}

type LanguageSet = {
  processes: string
  finished: string
}

const languageSets: LanguageSets<LanguageSet> = {
  en: {
    processes: 'Processes',
    finished: 'Finished'
  },
  sv: {
    processes: 'Bearbetar',
    finished: 'Färdig'
  },
  nb: {
    processes: 'Prosesser',
    finished: 'Ferdig'
  },
  fi: {
    processes: 'Prosessit',
    finished: 'Valmis'
  },
  da: {
    processes: 'Processer',
    finished: 'Færdig'
  }
}

const $screenReaderFeedback = document.createElement('div')
$screenReaderFeedback.id = 'one-ux-screen-reader-busy-feedback'
$screenReaderFeedback.classList.add('one-ux-accessibility--screen-reader')

if (document.body) {
  document.body.appendChild($screenReaderFeedback)
} else {
  document.addEventListener(
    'DOMContentLoaded',
    () => {
      document.body.appendChild($screenReaderFeedback)
    },
    { once: true }
  )
}

let cleanupRequest: ReturnType<typeof setTimeout>
const screenReaderFeedback = (message: string, langCode: langCode) => {
  const $message = document.createElement('div')
  $message.setAttribute('role', 'alert')
  $message.setAttribute('lang', langCode)
  $message.textContent = message
  $screenReaderFeedback.appendChild($message)

  /*
    Remove the messages after some time when the feedback has been idle. 
    The screen reader should have picked up and read the message by then,
    so its no longer needed in the DOM.
  */
  clearTimeout(cleanupRequest)
  cleanupRequest = setTimeout(() => {
    $screenReaderFeedback.innerHTML = ''
  }, 60_000)
}

export const Busy = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  // TODO: remove any
  if (!(SuperClass as any).__one_ux_mixin_label__) {
    throw new Error(`Can only apply Busy mixin if Label mixin is already applied. `)
  }

  class BusyClass extends SuperClass {
    #busy = false
    #done = false

    @property({ type: Boolean, noAccessor: true })
    public set busy(busy: boolean) {
      const newValue = !!busy // Typing is not enforced at runtime, ensure we have an actual boolean to work with.
      if (newValue === this.#busy) {
        return
      }

      const langCode = getLangCode(this)
      const language = languageSets[langCode]

      const oldValue = this.#busy
      this.#busy = newValue
      this.#done = !this.#busy
      this.requestUpdate('busy', oldValue)

      // Wait before checking label value
      this.updateComplete.then(() => {
        const state = busy ? language.processes : language.finished
        const label = (this as any).label // TODO: remove any
        const message = label ? `${state}: "${label}"` : state
        screenReaderFeedback(message, langCode)
      })
    }

    public get busy() {
      return this.#busy
    }

    public get done() {
      return this.#done
    }
  }
  return BusyClass as Constructor<IBusy> & TSuperClass
}
