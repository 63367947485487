const TABBABLE_TARGETS = [
  'button:not(disabled)',
  '[href]',
  'input:not(disabled)',
  'select:not(disabled)',
  'textarea:not(disabled)',
  '[tabindex]:not([tabindex="-1"])',
  '[one-ux-focusable]:not([disabled])'
]
export const TABBABLE_TARGETS_SELECTOR = TABBABLE_TARGETS.join(', ')

const FOCUSABLE_TARGETS = ['button', '[href]', 'input', 'select', 'textarea', '[tabindex]', '[one-ux-focusable]']
export const FOCUSABLE_TARGETS_SELECTOR = FOCUSABLE_TARGETS.join(', ')
