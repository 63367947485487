import { html, nothing } from 'lit'
import { customElement } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Focusable } from '../../mixins/Focusable.js'
import { Implicit } from '../../mixins/Implicit.js'
import { Weight } from '../../mixins/Weight.js'
import { OneUxElement } from '../../OneUxElement.js'
import { DelegateAria } from '../../mixins/DelegateAria.js'
import { createRef, ref } from 'lit/directives/ref.js'
import { Label } from '../../mixins/Label.js'
import { Required } from '../../mixins/Required.js'
import { Compact } from '../../mixins/Compact.js'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import { Errors } from '../../mixins/Errors.js'
import { keyCodes } from '../../utils.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { style } from './style.js'
import { FieldSetProps } from './FieldSetProps.js'
import { ErrorsPopout } from '../../fragments/ErrorsPopout.js'
import { FOCUSABLE_TARGETS_SELECTOR } from '../../utils/focusable.js'
import { log } from '../../utils/log.js'

const Styled = StyledFactory(style)
const BaseClass = FieldSetProps(
  Styled(Errors(Compact(Required(Label(DelegateAria(Disabled(Focusable(Implicit(Weight(OneUxElement))))))))))
)
/**
 * A base component that is used internally to create field sets.
 * This is not recommended for use, you should use one of the field sets available instead.
 */
@customElement('one-ux-field-set')
export class OneUxFieldSetElement extends BaseClass {
  #grid = {
    template: null as string | null,
    indexToPos: {} as Record<number, { column: number; row: number }>,
    posToIndex: {} as Record<string, number>
  }

  #slotElement = createRef<HTMLSlotElement>()
  protected guardedRender() {
    this.#updateGrid()

    return html`
      <div class="one-ux-element--root">
        ${!this.compact
          ? html`<span class="label">
              ${this.label} ${this.required ? html` <span class="asterisk">*</span> ` : nothing}
            </span>`
          : nothing}

        <div
          role="group"
          aria-label="${this.label}"
          aria-required=${ifDefined(this.required ? 'true' : undefined)}
          aria-disabled=${ifDefined(this.disabled ? 'true' : undefined)}
          ${this._ariaTarget()}
          class=${classMap({
            'field-set': true,
            error: this.errors ? this.errors.length : false,
            disabled: this.disabled
          })}
          style=${styleMap({
            'grid-template': this.#grid.template
          })}
          @keydown=${this.#handleKeydown}
        >
          <slot
            ${ref(this.#slotElement)}
            @slotchange=${() => {
              this.requestUpdate()
            }}
          ></slot>
        </div>

        ${ErrorsPopout({
          reference: 'previous',
          errors: this.errors,
          hidden: this.hideErrors
        })}
      </div>
    `
  }

  #handleKeydown = (event: KeyboardEvent) => {
    const $assignedElements = this.#slotElement.value!.assignedElements({
      flatten: true
    }) as HTMLElement[]
    const $focusable = $assignedElements.reduce((result: HTMLElement[], $el: HTMLElement) => {
      if ($el.matches(FOCUSABLE_TARGETS_SELECTOR)) {
        result.push($el)
      } else {
        const $child = $el.querySelector<HTMLElement>(FOCUSABLE_TARGETS_SELECTOR)
        if ($child) {
          result.push($child)
        } else {
          log.warning({
            title: 'Invalid <one-ux-fieldset>',
            message:
              'An element was slotted to <one-ux-fieldset> that could not be focused or had children that could be focused. Keyboard navigation will be broken.',
            details: {
              fieldSet: this,
              element: $el
            }
          })
          result.push($el)
        }
      }
      return result
    }, [] as HTMLElement[])
    const currentElementIndex = $focusable.indexOf(event.target as HTMLElement)
    const currentPosition = this.#grid.indexToPos[currentElementIndex]
    const getElementFromPosition = (column: number, row: number) =>
      $focusable[this.#grid.posToIndex[`${column}-${row}`]]

    switch (event.code) {
      case keyCodes.UP:
        event.preventDefault()
        $focusable[Math.max(currentElementIndex - 1, 0)].focus()
        break
      case keyCodes.DOWN:
        event.preventDefault()
        $focusable[Math.min(currentElementIndex + 1, $focusable.length - 1)].focus()
        break
      case keyCodes.HOME:
        event.preventDefault()
        $focusable[0].focus()
        break
      case keyCodes.END:
        event.preventDefault()
        $focusable[$focusable.length - 1].focus()
        break
      case keyCodes.LEFT: {
        event.preventDefault()
        const $prevElement =
          getElementFromPosition(currentPosition.column - 1, currentPosition.row) ||
          getElementFromPosition(this.columns, currentPosition.row - 1)
        $prevElement?.focus()
        break
      }
      case keyCodes.RIGHT: {
        event.preventDefault()
        const $nextElement =
          getElementFromPosition(currentPosition.column + 1, currentPosition.row) ||
          getElementFromPosition(1, currentPosition.row + 1)
        $nextElement?.focus()
        break
      }
    }
  }

  #updateGrid() {
    this.#grid = {
      template: null,
      indexToPos: {},
      posToIndex: {}
    }

    if (!this.#slotElement.value) {
      return
    }

    const slottedElements = this.#slotElement.value!.assignedElements({
      flatten: true
    }) as HTMLElement[]
    const elementCount = slottedElements.length
    const numberOfColumns = this.columns
    const numberOfRows = Math.ceil(slottedElements.length / numberOfColumns)

    this.#grid.template = `repeat(${numberOfRows}, auto) / repeat(${numberOfColumns}, auto)`

    let currentColumn = 1
    let currentRow = 1
    slottedElements.forEach(($el, index) => {
      $el.style.setProperty('--contextual-one-ux-field-set-element--grid-column', currentColumn + '')
      $el.style.setProperty('--contextual-one-ux-field-set-element--grid-row', currentRow + '')

      this.#grid.indexToPos[index] = { column: currentColumn, row: currentRow }
      this.#grid.posToIndex[`${currentColumn}-${currentRow}`] = index

      const remainingElements = elementCount - (index + 1)
      const remainingColumns = numberOfColumns - currentColumn

      if (currentRow < numberOfRows && remainingElements > remainingColumns) {
        currentRow++
      } else {
        currentRow = 1
        currentColumn++
      }
    })
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-field-set': OneUxFieldSetElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-field-set': OneUxFieldSetElement
    }
  }
}
