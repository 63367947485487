import { PDRConfig, SessionModuleWebViewConfig, SessionModuleAuthConfig, PDRWebviewConfig } from '../types.js'

export class SessionSettings {
  static build(config: PDRWebviewConfig | PDRConfig) {
    if (config.env === 'webView') {
      if (config.webView) {
        return new CustomSettings(config.webView)
      }
    } else {
      if (config.auth) {
        return new OIDCSettings(config.auth)
      }
    }
  }
}

export class OIDCSettings {
  constructor(private config: SessionModuleAuthConfig) {}

  get maxRedirectsAttempts() {
    return this.config.maxRedirectsAttempts
  }
  get baseRedirectUri() {
    return this.config.baseRedirectUri
  }
  get authorityUrl() {
    return this.config.authorityUrl
  }
  get postLogoutRedirectUrl() {
    return this.config.postLogoutRedirectUrl
  }
  get redirectPath() {
    return this.config.redirectPath
  }
  get popupRedirectPath() {
    return this.config.popupRedirectPath
  }
  get silentRedirectPath() {
    return this.config.silentRedirectPath
  }
  get clientId() {
    return this.config.clientId
  }
  get responseType() {
    return this.config.responseType
  }
  get scope() {
    return this.config.scope
  }
  get acrValues() {
    return this.config.acrValues
  }
  get clockSkew() {
    return this.config.clockSkew
  }
  get loadUserInfo() {
    return this.config.loadUserInfo
  }
  get validateSubOnSilentRenew() {
    return this.config.validateSubOnSilentRenew
  }
  get filterProtocolClaims() {
    return this.config.filterProtocolClaims
  }
  get responseMode() {
    return this.config.responseMode
  }
}

export class CustomSettings {
  constructor(private config: SessionModuleWebViewConfig) {}

  get apiRoot() {
    return this.config.apiRoot
  }

  get getUser() {
    return this.config.getUser
  }
}
