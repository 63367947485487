import { createContext } from '@lit/context'
import { weight } from '../../mixins/Weight.js'
import { OneUxTabNextElement } from '../one-ux-tab-next/OneUxTabNextElement.js'

export interface ITabsNextContext {
  weight: weight
  implicit: boolean
  isTablistFocused: boolean
  updateTab($tab: OneUxTabNextElement): void
  changeTab($tab: OneUxTabNextElement): void
  isFocused($tab: OneUxTabNextElement): boolean
  onTabBlur(event: FocusEvent): void
}

const contextKey = Symbol('one-ux-tabs-context')

export const defaultTabsNextContext: ITabsNextContext = {
  weight: 'normal',
  implicit: false,
  isTablistFocused: false,
  updateTab() {},
  changeTab() {},
  isFocused() {
    return false
  },
  onTabBlur() {}
}

export const tabsNextContext = createContext<ITabsNextContext>(contextKey)
