import { MicrofrontendDependency, MicrofrontendModulesConfig, PDRConfig, PDRWebviewConfig } from '../types.js'

export class MicrofrontendsSettings {
  #config
  constructor(config: MicrofrontendModulesConfig) {
    this.#config = config
  }

  static build(config: PDRConfig | PDRWebviewConfig) {
    if (config.microfrontends) {
      return new MicrofrontendsSettings(config.microfrontends)
    }
  }
  get baseUrl(): string {
    if (!this.#config.baseUrl) {
      this.invalidConfig('baseUrl is required')
    }
    if (this.#config.baseUrl.endsWith('/')) {
      return this.#config.baseUrl
    }
    // For Webpack 5 publicPath: 'auto' it is relevant that the URL always ends with a / otherwise following chunk loads will fail.
    // The baseUrl http://example.com/{package}/{version} will result in the public path http://example.com/{package}/ and chunks will fail to load.
    // In contrast the url http://example.com/{package}/{version}/ will maintain the version in the public path.
    return this.#config.baseUrl + '/'
  }

  get mode(): 'dynamic' | 'restricted' | 'static' {
    return this.#config.mode || 'dynamic'
  }

  get dependencies(): MicrofrontendDependency[] {
    if (this.#config.dependencies) {
      if (Array.isArray(this.#config.dependencies)) {
        return this.#config.dependencies
      }
      this.invalidConfig('dependencies has to be an array')
    }
    return []
  }

  invalidConfig(reason: string) {
    throw new Error(`'Invalid dependencies configuration passed to PDR.init: ${reason}!`)
  }
}
