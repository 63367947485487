import { createContext } from '@lit/context'

export interface ILabelContext {
  label: string
}

const contextKey = Symbol('one-ux-label-context')

export const defaultLabelContext = {
  label: ''
} satisfies ILabelContext

export const labelContext = createContext<ILabelContext>(contextKey)
