import { render } from 'lit'

import { Toast } from './Toast.js'

import { ToastList } from './components/ToastList.js'
import * as ToastAnimations from './animations/toast-animations.js'
import * as ProgressAnimations from './animations/progress-animations.js'

const toasts: Toast[] = []

export const add = async (toast: Toast) => {
  toasts.unshift(toast)
  update()

  await ToastAnimations.show(toast)

  if (Number.isInteger(toast.duration)) {
    const isProgressCompleted = await ProgressAnimations.animate(toast)
    if (isProgressCompleted) {
      toast.close()
    }
  }
}

export const remove = async (toast: Toast) => {
  const index = toasts.indexOf(toast)
  if (index < 0) {
    return
  }

  toasts.splice(index, 1)
  await ToastAnimations.hide(toast)

  update()
}

export const copy = async (toast: Toast) => {
  if (toast.isCopied || !navigator.clipboard) {
    return
  }

  await navigator.clipboard.writeText(toast.description)
  toast.isCopied = true
  update()

  return new Promise((resolve) => {
    setTimeout(async () => {
      await remove(toast)
      resolve(toast)
    }, 1500)
  })
}

export const update = () => {
  const rootId = '__PDR_toast_root__'
  let $root = document.getElementById(rootId)
  if (!$root) {
    $root = document.createElement('div')
    $root.id = rootId
    document.body.appendChild($root)
  }
  render(ToastList(toasts), $root)
}
