import { Branded } from '../../types.js'

// A string that follows strict "YYYY-MM-DD" format and has been validated as a proper date
// Casting to this type is *ONLY* allowed in the dedicated "constructor" function in order to ensure type safety
export type isoDateString = Branded<string, 'ISO8601-DateString'>

class IsoDate {
  #toIsoDateString(year: number, month: number, day: number): isoDateString {
    const paddedYear = year.toString().padStart(4, '0')
    const paddedMonth = month.toString().padStart(2, '0')
    const paddedDay = day.toString().padStart(2, '0')

    // This is the *ONLY* casting allowed to this type
    return `${paddedYear}-${paddedMonth}-${paddedDay}` as isoDateString
  }

  #toIsoDateStringFromDate(date: Date): isoDateString {
    return this.#toIsoDateString(date.getFullYear(), date.getMonth() + 1, date.getDate())
  }

  #tryCreateIsoDateString(year: number, month: number, day: number): isoDateString | undefined {
    const date = this.#toDate(year, month, day)
    const isValid = date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day
    return isValid ? this.#toIsoDateString(year, month, day) : undefined
  }

  // Improve readability by using month number instead of zero-based month index
  #toDate(year: number, month: number, day: number) {
    return new Date(year, month - 1, day)
  }

  tryParse(value: unknown): isoDateString | undefined {
    if (typeof value !== 'string') return undefined
    const trimmedValue = value.trim()

    const isoFormat = /^\d{4}-\d{2}-\d{2}$/
    return isoFormat.test(trimmedValue)
      ? this.#tryCreateIsoDateString(
          parseInt(trimmedValue.substring(0, 4), 10),
          parseInt(trimmedValue.substring(5, 7), 10),
          parseInt(trimmedValue.substring(8, 10), 10)
        )
      : undefined
  }

  clamp(isoDate: isoDateString, minIsoDate?: isoDateString, maxIsoDate?: isoDateString): isoDateString {
    if (minIsoDate && maxIsoDate && minIsoDate > maxIsoDate) {
      maxIsoDate = minIsoDate
    }

    if (minIsoDate && isoDate < minIsoDate) {
      isoDate = minIsoDate
    }
    if (maxIsoDate && isoDate > maxIsoDate) {
      isoDate = maxIsoDate
    }
    return isoDate
  }

  isInRange(isoDate: isoDateString, startIsoDate?: isoDateString, endIsoDate?: isoDateString): boolean {
    return (!startIsoDate || isoDate >= startIsoDate) && (!endIsoDate || isoDate <= endIsoDate)
  }

  today(): isoDateString {
    return this.#toIsoDateStringFromDate(new Date())
  }

  getFirstDateInMonth(isoDate: isoDateString): isoDateString {
    const [year, month] = this.getParts(isoDate)
    return this.#toIsoDateString(year, month, 1)
  }

  getAllDatesInMonth(isoDate: isoDateString): isoDateString[] {
    const [year, month] = this.getParts(isoDate)
    // Go to start of next month and step back one day to get last day in previous month
    const daysInMonth = this.#toDate(year, month + 1, 0).getDate()
    return Array.from({ length: daysInMonth }, (_, index) => this.#toIsoDateString(year, month, index + 1))
  }

  addYears(isoDate: isoDateString, delta: number): isoDateString {
    return this.addMonths(isoDate, delta * 12)
  }

  addMonths(isoDate: isoDateString, delta: number): isoDateString {
    const [year, month, day] = this.getParts(isoDate)
    const date = this.#toDate(year, month + delta, day)

    if (date.getDate() < day) {
      // We have overflowed into next month, change back into last day of previous month
      date.setDate(0)
    }

    return this.#toIsoDateStringFromDate(date)
  }

  addDays(isoDate: isoDateString, delta: number): isoDateString {
    const [year, month, day] = this.getParts(isoDate)
    const date = this.#toDate(year, month, day + delta)

    return this.#toIsoDateStringFromDate(date)
  }

  getParts(isoDate: isoDateString): [number, number, number] {
    const [year, month, day] = isoDate.split('-').map((x) => parseInt(x, 10))
    return [year, month, day]
  }

  // Monday is index 0, Sunday is index 6
  getIsoWeekdayIndex(isoDate: isoDateString): number {
    const date = this.#toDate(...this.getParts(isoDate))
    return (date.getDay() + 6) % 7
  }

  // https://weeknumber.com/how-to/javascript
  getIsoWeek(isoDate: isoDateString): number {
    const date = this.#toDate(...this.getParts(isoDate))
    date.setHours(0, 0, 0, 0)
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
    const week1 = new Date(date.getFullYear(), 0, 4)
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
  }
}

export const isoDate = new IsoDate()
