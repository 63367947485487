export class AddEvent extends Event {
  static get eventName() {
    return 'add'
  }

  constructor() {
    super(AddEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    })
  }
}

export class TabEvent extends Event {
  detail: string

  static get eventName() {
    return 'tab'
  }

  constructor(name: string) {
    super(TabEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    })

    this.detail = name
  }
}

export class BeforeTabEvent extends Event {
  detail: string

  static get eventName() {
    return 'beforetab'
  }

  constructor(name: string) {
    super(BeforeTabEvent.eventName, {
      bubbles: false,
      cancelable: true,
      composed: false
    })

    this.detail = name
  }
}
