import { createContext } from '@lit/context'

export interface IPopoutContext {
  closePopout(skipAutomaticFocus?: boolean): void
  openPopout(skipAutomaticFocus?: boolean): void
  isOpen: boolean
}

const contextKey = Symbol('one-ux-popout-context')

export const defaultPopoutContext: IPopoutContext = {
  closePopout() {},
  openPopout() {},
  isOpen: false
}

export const popoutContext = createContext<IPopoutContext>(contextKey)
