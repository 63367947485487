import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement.js'
import { Constructor } from '../utils.js'
import { InternalValidityChangedEvent } from '../events/internal/InternalValidityChangedEvent.js'
import { InternalValidityResetEvent } from '../events/internal/InternalValidityResetEvent.js'
import { InternalValidityVisibleEvent } from '../events/internal/InternalValidityVisibleEvent.js'

export declare class IErrors {
  errors: string[]
  hideErrors: boolean
}

export const Errors = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class ErrorsClass extends SuperClass {
    // TODO: Make this possible for children to set in PDR 11
    /**
     * Forces the errors popout to be hidden.
     * Can be useful for custom implementations that show other popouts that interfere with the errors.
     */
    @property({ attribute: 'hide-errors', type: Boolean })
    accessor hideErrors = false

    /**
     * The errors to display, if any.
     */
    @property({ type: Array })
    public set errors(errors: string[]) {
      this.#customErrors = errors ?? []
      this.updateErrors()
    }
    public get errors() {
      return this.#errors
    }

    #errors = [] as string[]
    #customErrors = [] as string[]
    #validityErrors = [] as string[]

    #displayValidityErrors = false

    constructor(...args: any[]) {
      super(...args)

      this.addEventListener(InternalValidityChangedEvent.eventName, (event) => {
        const { valid, errors } = (event as CustomEvent).detail
        this.#validityErrors = valid ? [] : errors
        const oldValue = this.#errors
        this.updateErrors()
        this.requestUpdate('errors', oldValue)
      })

      this.addEventListener(InternalValidityResetEvent.eventName, () => {
        this.#displayValidityErrors = false
        const oldValue = this.#errors
        this.updateErrors()
        this.requestUpdate('errors', oldValue)
      })

      this.addEventListener(InternalValidityVisibleEvent.eventName, () => {
        if (!this.#displayValidityErrors) {
          this.#displayValidityErrors = true
          const oldValue = this.#errors
          this.updateErrors()
          this.requestUpdate('errors', oldValue)
        }
      })
    }

    private updateErrors() {
      this.#errors = this.#displayValidityErrors ? [...this.#customErrors, ...this.#validityErrors] : this.#customErrors
    }
  }

  return ErrorsClass as Constructor<IErrors> & TSuperClass
}
