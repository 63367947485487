type OverflowDirection = 'none' | 'left' | 'both' | 'right'

export const setOverflow = ($tabs: HTMLElement) => {
  const direction = getOverflowDirection($tabs.querySelector('[role="tablist"]') as HTMLElement)
  for (const className of $tabs.className.split(' ').filter((x) => x.startsWith('state-overflow-'))) {
    $tabs.classList.remove(className)
  }
  for (const $btn of $tabs.querySelectorAll('.tabs--navigation-button')) {
    $btn.toggleAttribute('hidden', direction === 'none')
  }
  $tabs.classList.add(`state-overflow-${direction}`)
}

const getOverflowDirection = ($tablist: HTMLElement): OverflowDirection => {
  if (!$tablist || $tablist.scrollWidth <= $tablist.clientWidth) {
    return 'none'
  }

  const maxScrollLeft = $tablist.scrollWidth - $tablist.clientWidth
  if ($tablist.scrollLeft === 0) {
    return 'left'
  } else if (Math.ceil($tablist.scrollLeft) === maxScrollLeft) {
    return 'right'
  } else {
    return 'both'
  }
}
