import { PDRModule } from './PDRModule.js'
import { PDRConfig, PDRWebviewConfig } from './types.js'

import { ApiModule } from './api/ApiModule.js'
import { DebugModule } from './debug/DebugModule.js'
import { MicrofrontendsModule } from './microfrontends/MicrofrontendsModule.js'
import { PromptModule } from './prompt/PromptModule.js'
import { ToastModule } from './toast/ToastModule.js'
import { AvatarModule } from './avatar/AvatarModule.js'
import { TestModule } from './test/TestModule.js'
import { SessionModule } from './session/SessionModule.js'
import { UtilsModule } from './utils/UtilsModule.js'
import { LibrariesModule } from './libraries/LibrariesModule.js'
import { LogModule } from './log/LogModule.js'

declare global {
  interface Window {
    PDR: PDRLibrary
    PDR_runtime: PDRLibrary
  }
  const PDR: PDRLibrary
  const PDR_runtime: PDRLibrary
}

export class PDRLibrary {
  _started = false
  _initialized = false

  #debug = new DebugModule()
  get debug() {
    return this.#debug
  }

  #utils = new UtilsModule()
  get utils() {
    return this.#utils
  }

  #prompt = new PromptModule()
  get prompt() {
    return this.#prompt
  }

  #mfe = new MicrofrontendsModule()
  get mfe() {
    return this.#mfe
  }

  #session = new SessionModule()
  get session() {
    return this.#session
  }

  #api = new ApiModule()
  get api() {
    return this.#api
  }

  #toast = new ToastModule()
  get toast() {
    return this.#toast
  }

  #avatar = new AvatarModule()
  get avatar() {
    return this.#avatar
  }

  #test = new TestModule()
  get test() {
    return this.#test
  }

  #libraries = new LibrariesModule()
  get libraries() {
    return this.#libraries
  }

  #log = new LogModule()
  get log() {
    return this.#log
  }

  start() {
    if (this._started) {
      return
    }
    if (window.PDR_runtime || window.PDR) {
      throw new Error('Only a single instance of the PDR runtime can be started!')
    }
    window.PDR = this
    window.PDR_runtime = this
    PDRModule.notify('started', this)
    this._started = true
  }

  version() {
    return VERSION
  }

  init(jsonConfig: PDRConfig | PDRWebviewConfig) {
    if (!this._started) {
      throw new Error("Can't initialize PDR if it is not started. Please call .start() before .init().")
    }
    if (this._initialized) {
      return
    }
    PDRModule.notify('configured', jsonConfig)
    this._initialized = true
  }
}
