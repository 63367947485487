import { Version } from '../Version.js'
import { SpecialVersion } from './SpecialVersion.js'

const keyword = 'latest'
export class LatestVersion extends SpecialVersion {
  constructor() {
    super(keyword)
  }

  fulfills(version: Version) {
    return version instanceof LatestVersion
  }

  static matches(versionString: string) {
    return versionString === keyword
  }
}
