import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Focusable } from '../../mixins/Focusable.js'
import { Placeholder } from '../../mixins/Placeholder.js'
import { IValue, ValueFactory } from '../../mixins/Value.js'
import { FormAssociated } from '../../mixins/FormAssociated.js'
import { getLangCode } from '../../utils/getLangCode.js'
import { IRequired, Required } from '../../mixins/Required.js'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../../mixins/Validated.js'
import { live } from 'lit/directives/live.js'
import { labelContext, defaultLabelContext } from '../../contexts/LabelContext.js'
import { consume } from '@lit/context'

const Styled = StyledFactory(style)
type valueType = string
const Value = ValueFactory<valueType>({
  type: String,
  isEmpty(value) {
    return typeof value === 'string' ? value.length === 0 : value == null
  }
})
const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldIsRequired } = getFormValidationLanguage(this)
    const valid = !this.empty
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldIsRequired]
    }
  }
})

const BaseClass = FormAssociated(Validated(Required(Value(Placeholder(Disabled(Focusable(Styled(OneUxElement))))))))

type Format = 'text' | 'number' | 'password'

/**
 * A single line text input field
 */
@customElement('one-ux-input')
export class OneUxInputElement extends BaseClass {
  constructor() {
    super()
    this.width = 'max'
  }

  @consume({ context: labelContext, subscribe: true })
  labelContext = defaultLabelContext

  /*
   * Visually present value as text, number or password
   */
  @property({ type: String, reflect: true })
  public accessor format!: Format

  #valueBeforeChange?: string
  protected render() {
    return html`
      <input
        class="one-ux-element--root"
        .value=${live(this.empty ? '' : this.value)}
        .placeholder=${this.placeholder || ''}
        .disabled=${this.disabled}
        type=${ifDefined(this.format === 'password' ? 'password' : undefined)}
        inputmode=${ifDefined(this.format === 'number' ? 'numeric' : undefined)}
        aria-required=${!!this.required}
        aria-label=${ifDefined(this.labelContext.label || undefined)}
        @beforeinput=${this.#handleBeforeInput}
        @input=${this.#handleInput}
        @focus=${() => {
          this.#valueBeforeChange = this.value
        }}
        @blur=${this.#handleChange}
      />
    `
  }

  protected updated(): void {
    this.setAttribute('lang', getLangCode(this))
  }

  #handleBeforeInput(event: InputEvent) {
    event.stopPropagation()
    if (this.format !== 'number') {
      return
    }
    if (event.data && /[^0-9-.,]/.test(event.data)) {
      event.preventDefault()
    }
  }

  #handleInput(event: Event) {
    event.stopPropagation()
    const $target = event.target as HTMLInputElement
    const beforeInputEvent = new InputEvent('beforeinput', {
      bubbles: true,
      composed: true,
      cancelable: true,
      data: $target.value
    })
    if (this.dispatchEvent(beforeInputEvent)) {
      this._applyUserValue($target.value)
      const inputEvent = new InputEvent('input', {
        bubbles: true,
        composed: true,
        cancelable: false
      })
      this.dispatchEvent(inputEvent)
    } else {
      $target.value = this.value ?? ''
    }
  }

  #handleChange() {
    if (this.#valueBeforeChange !== this.value) {
      this.dispatchEvent(
        new Event('change', {
          bubbles: true,
          composed: true
        })
      )
    }
    this.#valueBeforeChange = undefined
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-input': OneUxInputElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-input': OneUxInputElement
    }
  }
}
