import { LanguageSets, getLangCode } from '../../utils/getLangCode.js'

export type LanguageSet = {
  search: string
}

const languages: LanguageSets<LanguageSet> = {
  en: {
    search: 'Search'
  },
  sv: {
    search: 'Sök'
  },
  nb: {
    search: 'Søk'
  },
  fi: {
    search: 'Hae'
  },
  da: {
    search: 'Søg'
  }
}

export const getLanguage = (element: Element) => {
  const closestLang = getLangCode(element)
  const translations = languages[closestLang]

  return { translations, lang: closestLang }
}
