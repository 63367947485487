import { PDRModule } from '../PDRModule.js'
import { MicrofrontendsSettings } from './MicrofrontendsSettings.js'
import { Microfrontend, MicrofrontendConfig } from './types/Microfrontend.js'
import { log } from './log.js'
import { Implementation, ImplementationConfig } from './types/Implementation.js'
import { Utils } from './Utils.js'
import { MicrofrontendsContainer } from './MicrofrontendsContainer.js'
import { MicrofrontendDependency } from '../types.js'

const mfeCacheParameter = 'cache'
const mfeCacheKey = '9fcd1275-90de-41e7-9bcd-26b89ca78c55'
const mfeCacheQuery = `${mfeCacheParameter}=${mfeCacheKey}`

const globalCacheKey = '__pdr_mfe_cache_query__'
if (!Object.prototype.hasOwnProperty.call(window, globalCacheKey)) {
  Object.defineProperty(window, globalCacheKey, {
    get() {
      return mfeCacheQuery
    },
    enumerable: false,
    configurable: false
  })
}

declare global {
  interface Window {
    __pdr_mfe_cache_query__: string
  }
  const __pdr_mfe_cache_query__: string
}

type MFEProvided = {
  source: string
  name: string
  value: Promise<unknown>
  version?: string
}

type MFEInjected = {
  from: string
  name: string
  version?: string
}

export class MicrofrontendsModule extends PDRModule<MicrofrontendsSettings> {
  #microfrontends
  #utils = new Utils()
  #providers = new Map()
  #configs = new Map()

  constructor() {
    super(MicrofrontendsSettings.build)
    this.#microfrontends = this._configured.then((settings) => {
      Microfrontend.setBaseUrl(settings.baseUrl)
      return new MicrofrontendsContainer(settings)
    })
  }

  async register(...configs: MicrofrontendDependency[]) {
    const microfrontends = await this.#microfrontends
    configs.forEach((config) => {
      const microfrontend = new Microfrontend(config)
      log.debug(`Attempting to register ${microfrontend.tag}`)
      microfrontends.register(microfrontend)
    })
  }

  async load(config: MicrofrontendConfig | string) {
    const microfrontends = await this.#microfrontends
    const isShorthand = typeof config === 'string'
    const tag = isShorthand ? config : config.tag
    log.debug({
      title: `Attempting to load ${tag}.`,
      message: isShorthand
        ? 'The shorthand notation of PDR.mfe.load() was used, the stacktrace below shows where the load occurs.'
        : 'The object syntax of PDR.mfe.load() was used, the stacktrace below shows where the load occurs.'
    })
    const microfrontend = isShorthand ? microfrontends.resolve(tag) : new Microfrontend(config)
    if (!microfrontends.contains(microfrontend.tag)) {
      const availableMicrofrontendVersion = this.#utils.available(microfrontend.tag)
      if (availableMicrofrontendVersion) {
        log.warning({
          title: `The microfrontend ${microfrontend.tag} is already available with version "${availableMicrofrontendVersion}" but not managed.`,
          message: `This means that the microfrontend ${microfrontend.tag} was included on the page without PDR.mfe.load() method. Consider tracking that down and replace with PDR.mfe.load() as it allows for better control and performance optimizations. Attempted to load the with the following configuration.`,
          details: microfrontend
        })
        return Promise.resolve(true)
      }
    }

    const required = isShorthand || typeof config.required !== 'boolean' || config.required

    try {
      await microfrontends.load(microfrontend)
    } catch (error) {
      if (required) {
        throw error
      }
      log.warning({
        title: `Could not load optional microfrontend ${microfrontend.tag}.`,
        message: `An optional microfrontend could not be loaded. This means that some parts of the application might not be available. If this is not the intended functionality remove the "required: false" setting.`,
        details: {
          configuration: microfrontend.asObject(),
          error
        }
      })
      return false
    }
    return true
  }

  async list() {
    const microfrontends = await this.#microfrontends
    return microfrontends.list()
  }

  async install(config: ImplementationConfig) {
    const microfrontends = await this.#microfrontends
    const implementation = new Implementation(config)
    log.debug(`Attempting to install ${implementation.tag}`)
    await this.register(...implementation.dependencies)
    return microfrontends.install(implementation)
  }

  inject({ from, name, version = '' }: MFEInjected) {
    const provider = this.#providers.get(from)
    if (provider) {
      const key = version ? `${name}|${version}` : name
      if (!provider.has(key)) {
        return null
      }
      return provider.get(key)
    }

    return null
  }

  config(tag: string, data = null as unknown) {
    if (!this.#configs.has(tag)) {
      this.#configs.set(tag, {})
    }

    const config = this.#configs.get(tag)
    if (data) {
      Object.assign(config, data)
    }

    return config
  }

  async provide({ source, name, value, version = '' }: MFEProvided) {
    if (!this.#providers.has(source)) {
      this.#providers.set(source, new Map())
    }

    const provider = this.#providers.get(source)
    const resolvedValue = await value
    provider.set(name, resolvedValue)
    if (version) {
      provider.set(`${name}|${version}`, resolvedValue)
    }
  }
}
