import { InternalValueChangedEvent } from '../events/internal/InternalValueChangedEvent.js'
import { OneUxElement } from '../OneUxElement.js'

import { Constructor } from '../utils.js'
import { IValue } from './Value.js'
import { InternalValidityResetEvent } from '../events/internal/InternalValidityResetEvent.js'
import { InternalValidityChangedEvent } from '../events/internal/InternalValidityChangedEvent.js'
import { InternalValidityVisibleEvent } from '../events/internal/InternalValidityVisibleEvent.js'

export type formAssociatedOptions = {
  reset?: () => void
}

export type formAssociatedFactoryOptions<TValue, TExtraProperties = void> = ThisType<
  OneUxElement & IValue<TValue> & TExtraProperties
> &
  formAssociatedOptions

export const FormAssociatedFactory =
  <TValue, TExtraProperties = void>(options?: formAssociatedFactoryOptions<TValue, TExtraProperties>) =>
  <TSuperClass extends Constructor<OneUxElement> & Constructor<IValue<TValue>>>(SuperClass: TSuperClass) =>
    FormAssociated<TSuperClass, TValue>(SuperClass, options)

export const FormAssociated = <
  TSuperClass extends Constructor<OneUxElement> & Constructor<IValue<TValue>>,
  TValue = unknown
>(
  SuperClass: TSuperClass,
  options = {} as formAssociatedOptions
) => {
  if (!(SuperClass as any).__one_ux_mixin_value__) {
    throw new Error(`Can only apply FormAssociated mixin if Value mixin is already applied. `)
  }

  class FormAssociatedClass extends SuperClass {
    static formAssociated = true
    elementInternals!: ElementInternals

    constructor(...args: any[]) {
      super(...args)
      this.elementInternals = this.attachInternals()

      this.addEventListener(
        'invalid',
        (e: Event) => {
          e.preventDefault()
          this.dispatchEvent(new InternalValidityVisibleEvent())
        },
        true
      )

      this.addEventListener(InternalValidityChangedEvent.eventName, (e: Event) => {
        const event = e as InternalValidityChangedEvent
        const { flags, errors } = event.detail
        this.elementInternals.setValidity(flags, errors.join(' | '))
      })

      this.addEventListener(InternalValueChangedEvent.eventName, () => {
        this.elementInternals.setFormValue(this.value as string)
      })

      this.updateComplete.then(() => {
        this.elementInternals.setFormValue(this.value as string)
      })
    }

    formResetCallback() {
      this.value = this.initialValue
      options?.reset?.call(this)
      this.dispatchEvent(new InternalValidityResetEvent())
    }
  }
  return FormAssociatedClass as TSuperClass
}
