import { html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'

import './TooltipAttributeHandler.js'
import { ShowTooltipEvent } from './events/ShowTooltipEvent.js'
import { HideTooltipEvent } from './events/HideTooltipEvent.js'
import { Optional } from '../../types.js'

const Styled = StyledFactory(style)

const BaseClass = Styled(OneUxElement)
/**
 * A tooltip component that requires manual management.
 * Not very useful stand alone, should be used in conjunction with the custom attribute `one-ux-tooltip`.
 */
@customElement('one-ux-tooltip')
export class OneUxTooltipElement extends BaseClass {
  static get events() {
    return {
      ShowTooltipEvent,
      HideTooltipEvent
    }
  }

  /**
   * Controls the visibility of the tooltip.
   */
  @property({ type: Boolean })
  public accessor visible = false

  /**
   * Defines the reference element. Required in order to render a tooltip.
   */
  @property({ type: Object, attribute: false })
  public accessor reference: Optional<Element>

  /**
   * Controls the preferred placement of the tooltip in relation to the reference element.
   */
  @property({ type: String })
  public accessor placement: Optional<'above' | 'below' | 'before' | 'after'>

  /**
   * Popout will show up at current cursor position within viewport.
   */
  @property({ type: Boolean, attribute: 'use-cursor-position' })
  public accessor useCursorPosition = false

  /**
   * Will render the tooltip at a fixed position relative to the reference element rather than follow the cursor.
   */
  @property({ type: Boolean, attribute: 'fixed' })
  public accessor fixed = false

  @state()
  private accessor _hasContent = false

  get #valid() {
    return this.visible && this.reference
  }

  render() {
    if (!this.#valid) {
      return
    }
    const { direction, placement } = this.#tooltipPosition()
    // IMPORTANT: ".tooltip" cannot contain white-space or newline in template as that will result in an empty extra line in Firefox when using shorthand notation.
    return html`<one-ux-popout
      .reference=${this.useCursorPosition ? 'locked-cursor' : this.reference}
      direction=${direction}
      placement=${placement}
      implicit
      indent="none"
      alignment=${this.fixed ? 'center' : 'cursor'}
      prevent-overlap
      contain-to-viewport
    >
      <div class="tooltip"><slot @slotchange=${this.#handleSlotChange}></slot></div>
    </one-ux-popout>`
  }

  protected updated(): void {
    // Have to set attribute as FF does not reflect .role
    if (this.#valid && this._hasContent) {
      this.setAttribute('role', 'tooltip')
    } else {
      this.removeAttribute('role')
    }
  }

  #tooltipPosition() {
    switch (this.placement) {
      case 'below':
        return { direction: 'vertical', placement: 'after' } as const
      case 'before':
        return { direction: 'horizontal', placement: 'before' } as const
      case 'after':
        return { direction: 'horizontal', placement: 'after' } as const
      case 'above':
      default:
        return { direction: 'vertical', placement: 'before' } as const
    }
  }

  #handleSlotChange(event: Event) {
    const $slot = event.target as HTMLSlotElement
    const validNodes = $slot.assignedNodes().filter((node) => {
      switch (node.nodeName) {
        case '#text':
          return !!node.textContent?.trim()
        case 'ONE-UX-ICON':
          return true
        case 'ONE-UX-TEXT':
          return true
        default:
          return false
      }
    })

    const $tooltip = this.shadowRoot?.querySelector('.tooltip') as HTMLElement
    if ($tooltip) {
      let layout = '1fr'

      if (validNodes.at(0)?.nodeName === 'ONE-UX-ICON') {
        layout = 'auto ' + layout
      }

      if (validNodes.at(-1)?.nodeName === 'ONE-UX-ICON') {
        layout += ' auto'
      }
      $tooltip.style.setProperty('--one-ux-tooltip--grid-layout', layout)
    }
    this._hasContent = !!validNodes.length
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tooltip': OneUxTooltipElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tooltip': OneUxTooltipElement
    }
  }
}
