import { property } from 'lit/decorators.js'
import type { OneUxElement } from '../OneUxElement.js'
import { Constructor } from '../utils.js'
import { log } from '../utils/log.js'
import { Optional } from '../types.js'

export declare class ILabel {
  label: string
}

export function hasInvalidLabel($el: OneUxElement, label: Optional<string>) {
  if (!label?.trim()) {
    log.error({
      title: 'The property "label" is required.',
      message:
        'Including a label is critical for accessibility and the component will not render if the label is omitted. ',
      details: {
        element: $el
      }
    })
    return true
  }
  return false
}

export const Label = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class LabelClass extends SuperClass {
    static get __one_ux_mixin_label__() {
      return true as const
    }

    constructor(...args: any[]) {
      super(...args)
      this._renderGuards.push(() => hasInvalidLabel(this, this.label))
    }

    /**
     * A label is used to support assistive technologies.
     * It is a required property that will prevent rendering if omitted.
     */
    @property({ type: String })
    public accessor label!: string
  }
  return LabelClass as Constructor<ILabel> & TSuperClass
}
