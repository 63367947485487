export type Constructor<T> = new (...args: any[]) => T

export const keyCodes = {
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  ESCAPE: 'Escape',
  RETURN: 'Enter',
  NUMPADRETURN: 'NumpadEnter',
  SPACE: 'Space',
  PAGEUP: 'PageUp',
  PAGEDOWN: 'PageDown',
  END: 'End',
  HOME: 'Home',
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown'
}

export function findPreviousBefore<T>(items: T[], startItem: T, critera: (item: T) => boolean) {
  let index = items.indexOf(startItem)
  if (index < 0) {
    return null
  }
  while (--index >= 0) {
    const candidate = items[index]
    if (critera(candidate)) {
      return candidate
    }
  }
  return null
}

export function findNextAfter<T>(items: T[], startItem: T, critera: (item: T) => boolean) {
  let index = items.indexOf(startItem)
  if (index < 0) {
    return null
  }
  while (++index < items.length) {
    const candidate = items[index]
    if (critera(candidate)) {
      return candidate
    }
  }
  return null
}

export function traverseNodes<T>(nodes: T[], callback: (node: T) => void, childrenPropertyName = 'children') {
  if (!nodes) {
    return
  }
  nodes.forEach((node) => {
    callback(node)
    traverseNodes((node as any)[childrenPropertyName], callback)
  })
}

export function scrollElementIntoView(
  $scroll?: Element,
  $element?: Element,
  direction: 'vertical' | 'horizontal' = 'vertical',
  scrollPadding: number = 0
) {
  if (!$scroll || !$element) {
    return
  }

  const isVerticalScroll = direction === 'vertical'

  const scrollDirection = isVerticalScroll ? 'scrollTop' : 'scrollLeft'
  const directionStart = isVerticalScroll ? 'top' : 'left'
  const directionEnd = isVerticalScroll ? 'bottom' : 'right'

  const elementRect = $element.getBoundingClientRect()
  const scrollRect = $scroll.getBoundingClientRect()

  if (elementRect[directionEnd] > scrollRect[directionEnd] - scrollPadding) {
    $scroll[scrollDirection] += elementRect[directionEnd] - scrollRect[directionEnd] + scrollPadding
  } else if (elementRect[directionStart] < scrollRect[directionStart] + scrollPadding) {
    $scroll[scrollDirection] -= scrollRect[directionStart] - elementRect[directionStart] + scrollPadding
  }
}
