import { css } from 'lit'

export const style = css`
  :host {
    vertical-align: middle;
  }

  ::slotted(one-ux-icon) {
    font-size: var(--one-ux-size--icon-200) !important;
    width: var(--one-ux-size--icon-200) !important;
    height: var(--one-ux-size--icon-200) !important;
    overflow: hidden !important;
    flex-shrink: 0 !important;
  }

  slot[name='popout'] {
    --one-ux-element-internal--popout-constraint-max-height: 200px;
    --one-ux-element-internal--popout-constraint-width: 100%;
  }

  .adornment {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is-empty {
      display: none;
    }
  }

  #button {
    color: var(--one-ux-palette--brand-neutral-600);
    background-color: var(--one-ux-palette--brand-neutral-100);
    border-radius: var(--one-ux-radius--300);
    border: var(--one-ux-border-width--500) solid var(--one-ux-palette--brand-neutral-300);

    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);

    min-height: 32px;
    min-width: 74px;
    padding: 5px 14px;
    position: relative;

    cursor: pointer;

    line-height: normal;

    box-sizing: border-box;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--one-ux-spacing--small);

    transition-property: background-color, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  one-ux-icon {
    font-size: var(--one-ux-size--icon-200);
  }

  .button-text {
    text-align: start;
  }

  #button.compact {
    height: 32px;
    min-width: 32px;
    padding: 0 !important;
  }

  :host([weight='low']) #button {
    min-height: 24px;
    padding: 1px 10px;
  }

  :host([weight='low']) #button.compact {
    height: 24px;
    min-width: 24px;
  }

  :host([weight='high']) #button {
    border-radius: var(--one-ux-radius--700);
    font: var(--one-ux-font--body-variant-300);
    letter-spacing: var(--one-ux-letter-spacing--body-variant-300);
  }

  #button:hover {
    background: var(--one-ux-palette--brand-neutral-200);
  }

  #button:is(:active, [aria-pressed='true']) {
    background: var(--one-ux-palette--brand-neutral-300);
    border-color: var(--one-ux-palette--brand-neutral-400);
  }

  #button:focus {
    outline: none;
  }

  :host([disabled]) #button {
    background: var(--one-ux-palette--gray-150) !important;
    color: var(--one-ux-palette--gray-400) !important;
    border-color: var(--one-ux-palette--gray-300) !important;
    cursor: default;
  }

  :host([purpose='main']) #button {
    background: var(--one-ux-palette--brand-core-400);
    color: var(--one-ux-palette--gray-100);
    border-color: var(--one-ux-palette--brand-core-400);

    &:hover {
      background: var(--one-ux-palette--brand-core-500);
      border-color: var(--one-ux-palette--brand-core-500);
    }

    &:is(:active, [aria-pressed='true']) {
      background: var(--one-ux-palette--brand-core-600);
      border-color: var(--one-ux-palette--brand-core-600);
    }
  }

  :host([purpose='caution']) #button {
    background: var(--one-ux-palette--red-300);
    color: var(--one-ux-palette--gray-100);
    border-color: var(--one-ux-palette--red-300);

    &:hover {
      background: var(--one-ux-palette--red-500);
      border-color: var(--one-ux-palette--red-500);
    }

    &:is(:active, [aria-pressed='true']) {
      background: var(--one-ux-palette--red-600);
      border-color: var(--one-ux-palette--red-600);
    }
  }

  :host([purpose='notice']) #button {
    background: var(--one-ux-palette--azure-200);
    border-color: var(--one-ux-palette--azure-400);

    &:hover {
      background: var(--one-ux-palette--azure-300);
    }

    &:is(:active, [aria-pressed='true']) {
      background: var(--one-ux-palette--azure-400);
      border-color: var(--one-ux-palette--azure-500);
    }
  }

  :host([purpose='muted']) #button {
    border-color: transparent;
    background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);

    &:hover {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);
    }

    &:is(:active, [aria-pressed='true']) {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.39);
    }
  }

  :host([purpose='placeholder']) #button {
    border-style: dashed;
    border-width: var(--one-ux-border-width--100);
    background: none;
    color: var(--one-ux-palette--brand-neutral-500);
    font-weight: normal;

    &:hover {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
      color: var(--one-ux-palette--brand-neutral-600);
    }

    &:is(:active, [aria-pressed='true']) {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);
      color: var(--one-ux-palette--brand-neutral-600);
    }
  }

  :host([implicit]) #button {
    background: none;
    border-color: transparent;
    color: var(--one-ux-palette--brand-neutral-500);

    &:hover {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
      color: var(--one-ux-palette--brand-neutral-600);
    }

    &:is(:active, [aria-pressed='true']) {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);
      color: var(--one-ux-palette--brand-neutral-600);
    }
  }

  :host([implicit][disabled]) #button {
    background: none !important;
    border-color: transparent !important;
  }

  :host([implicit][purpose='main']) #button {
    background: none;
    border-color: transparent;
    color: var(--one-ux-palette--brand-core-400);

    &:hover {
      background: rgba(var(--one-ux-rgb--brand-core-400), 0.15);
      color: var(--one-ux-palette--brand-core-500);
    }

    &:is(:active, [aria-pressed='true']) {
      background: rgba(var(--one-ux-rgb--brand-core-400), 0.33);
      color: var(--one-ux-palette--brand-core-600);
    }
  }

  :host([implicit][purpose='caution']) #button {
    background: none;
    border-color: transparent;
    color: var(--one-ux-palette--red-500);

    &:hover {
      background: rgba(var(--one-ux-rgb--red-400), 0.15);
      color: var(--one-ux-palette--red-500);
    }

    &:is(:active, [aria-pressed='true']) {
      background: rgba(var(--one-ux-rgb--red-400), 0.33);
      color: var(--one-ux-palette--red-600);
    }
  }

  :host([implicit][purpose='notice']) #button {
    background: none;
    border-color: transparent;
    color: var(--one-ux-palette--azure-400);

    &:hover {
      background: rgba(var(--one-ux-rgb--azure-400), 0.21);
      color: var(--one-ux-palette--azure-500);
    }

    &:is(:active, [aria-pressed='true']) {
      background: rgba(var(--one-ux-rgb--azure-400), 0.42);
      color: var(--one-ux-palette--azure-600);
    }
  }

  :host([implicit][purpose='muted']) #button {
    font-weight: 300;
  }

  :host([implicit][purpose='placeholder']) #button {
    background: none;
    border-color: transparent;
    color: var(--one-ux-palette--brand-neutral-500);
    text-decoration: underline dashed var(--one-ux-palette--brand-neutral-300);
    text-underline-offset: 0.25em;

    &:hover {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
      color: var(--one-ux-palette--brand-neutral-600);
    }

    &:is(:active, [aria-pressed='true']) {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);
      color: var(--one-ux-palette--brand-neutral-600);
    }
  }

  :host([state-keyboard-focus]) #button:focus,
  :host([state-keyboard-focus][purpose]) #button:focus {
    background: var(--one-ux-palette--keyboard-focus-200);
    border-color: var(--one-ux-palette--keyboard-focus-400);
    color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([state-keyboard-focus]) #button:active,
  :host([state-keyboard-focus][purpose]) #button::active {
    background: var(--one-ux-palette--keyboard-focus-300);
  }

  .spinner-clip {
    position: absolute;
    inset: 0;
    pointer-events: none;
    border-radius: inherit;
    overflow: hidden;
  }

  .spinner {
    --spinner-color: var(--one-ux-palette--focus-400);
    --spinner-duration: 1s; /* TODO: Missing token for duration */
    --spinner-thickness: var(--one-ux-border-width--500);
    --spinner-easing: var(--one-ux-easing--default);

    position: absolute;

    &.done {
      --spinner-color: var(--one-ux-palette--mint-400);
    }

    :host(:is([purpose='main'], [purpose='caution']):not([implicit])) & {
      --spinner-color: var(--one-ux-palette--gray-100);
    }

    &:not(.round) {
      bottom: 0;
      height: var(--spinner-thickness);
      background: var(--spinner-color);

      &.busy {
        animation:
          spinner-opacity var(--spinner-duration) infinite linear,
          spinner-flat calc(2 * var(--spinner-duration)) infinite var(--spinner-easing);
      }

      &.done {
        animation: spinner-flat-done var(--spinner-duration) var(--spinner-easing);
      }
    }

    &.round {
      border-radius: inherit;

      &.busy {
        top: 0;
        bottom: 0;
        right: 0;
        width: 50%;

        transform-origin: center left;

        animation:
          spinner-opacity var(--spinner-duration) linear infinite,
          spinner-round-start var(--spinner-duration) var(--spinner-easing) infinite;

        &:before {
          content: '';
          display: block;
          border-radius: inherit;
          box-sizing: border-box;
          height: 100%;
          width: 200%;
          position: absolute;
          right: 0;

          border: var(--spinner-thickness) solid;
          border-color: var(--spinner-color) var(--spinner-color) transparent transparent;
          animation: spinner-round-end var(--spinner-duration) var(--spinner-easing) infinite;
        }
      }

      &.done {
        inset: 0;
        border: var(--spinner-thickness) solid var(--spinner-color);
        opacity: 0;
        animation: spinner-round-done var(--spinner-duration) var(--one-ux-easing--exit);
      }
    }
  }

  @keyframes spinner-opacity {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes spinner-round-start {
    0% {
      transform: rotate(0deg);
    }
    16.7% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-round-end {
    0% {
      rotate: 0deg;
      transform: rotate(-135deg);
    }
    16.7% {
      rotate: 0deg;
    }
    83.3% {
      transform: rotate(225deg);
    }
    100% {
      rotate: -360deg;
      transform: rotate(225deg);
    }
  }

  @keyframes spinner-round-done {
    0% {
      opacity: 0;
    }
    50%,
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes spinner-flat {
    0% {
      left: 0;
      right: 100%;
    }
    8.3% {
      left: 0;
    }
    41.7% {
      right: 0;
    }
    50% {
      left: 100%;
      right: 0;
    }
    58.3% {
      right: 0;
    }
    91.7% {
      left: 0;
    }
    100% {
      left: 0;
      right: 100%;
    }
  }

  @keyframes spinner-flat-done {
    0% {
      left: 50%;
      right: 50%;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      left: 0;
      right: 0;
    }
  }
`
