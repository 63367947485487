export type Lang = {
  confirm: {
    header: string
    message: string
    ok: string
    cancel: string
  }
  alert: {
    header: string
    ok: string
  }
}

const sv = {
  confirm: {
    header: 'Bekräfta',
    message: 'Är du säker?',
    ok: 'OK',
    cancel: 'Avbryt'
  },
  alert: {
    header: 'Meddelande',
    ok: 'OK'
  }
}

const en = {
  confirm: {
    header: 'Confirm',
    message: 'Are you sure?',
    ok: 'OK',
    cancel: 'Cancel'
  },
  alert: {
    header: 'Message',
    ok: 'OK'
  }
}

const locales = {
  sv: sv,
  'sv-se': sv,
  en: en,
  'en-gb': en
}

export const getLanguage = (locale: string) => ({
  lang: (locale || '').toLowerCase() as 'sv' | 'en',
  translations: locales[(locale || '').toLowerCase() as 'sv' | 'en'] || en
})
