import { html } from 'lit'
import type { optionData } from '../types.js'
import { type OneUxColorPickerElement } from '../OneUxColorPickerElement.js'
import { ColorChip } from './ColorChip.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { ID_ACTIVE_OPTION } from '../constants.js'

export function ColorOption(this: OneUxColorPickerElement, option: optionData) {
  return html`<td
    id=${ifDefined(this._activeOption === option ? ID_ACTIVE_OPTION : undefined)}
    aria-selected=${this._selectedOption === option}
    one-ux-tooltip=${ifDefined(option.label)}
    pdr-test-hook=${`one-ux-color-picker-option-${option.value}`}
  >
    <div class="color-option" @click=${() => this._userSelectOption(option)}>${ColorChip.call(this, option)}</div>
  </td>`
}
