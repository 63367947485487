import { avatarState } from '../../one-ux/contexts/AvatarContext.js'
import { log } from './avatarLog.js'
import type { userCatalogPhotoDto, requestedImageSize, userCatalogUserDto, jwtTokenPayload, pdrUser } from './types.js'

export class AvatarApiService {
  #pendingAvatarUrlRequests: Record<string, Promise<userCatalogPhotoDto[]>> = {}

  async getAvatarUrl(tenantId: string, userId: string, size: requestedImageSize): Promise<string> {
    const url = `${await this.#userCatalogBaseUrl}/api/v2/users/photos?tenantId=${tenantId}`
    const pendingRequestKey = `${tenantId}:${userId}`
    let pendingRequest = this.#pendingAvatarUrlRequests[pendingRequestKey]
    if (!pendingRequest) {
      pendingRequest = window.PDR.api.withScopes('user.readwrite').post(url, {
        size: `${size}x${size}`,
        userIds: [userId]
      })
      this.#pendingAvatarUrlRequests[pendingRequestKey] = pendingRequest
    }
    const response = await pendingRequest
    delete this.#pendingAvatarUrlRequests[pendingRequestKey]
    return Array.isArray(response) && response.length && response[0].profilePhotoUri
      ? `data:image/png;base64,${response[0].profilePhotoUri}`
      : ''
  }

  #pendingFullNameRequests: Record<string, Promise<userCatalogUserDto>> = {}

  async getFullName(tenantId: string, userId: string): Promise<string> {
    const url = `${await this.#userCatalogBaseUrl}/api/v1/users/${userId}?tenantId=${tenantId}`
    const pendingRequestKey = `${tenantId}:${userId}`
    let pendingRequest = this.#pendingFullNameRequests[pendingRequestKey]
    if (!pendingRequest) {
      pendingRequest = window.PDR.api.withScopes('user.readwrite').get(url)
      this.#pendingFullNameRequests[pendingRequestKey] = pendingRequest
    }
    const response = await pendingRequest
    delete this.#pendingFullNameRequests[pendingRequestKey]
    return `${response.firstName} ${response.lastName}`
  }

  #pendingStateRequests: Record<string, Promise<userCatalogUserDto>> = {}

  async getState(tenantId: string, userId: string): Promise<avatarState> {
    const url = `${await this.#userCatalogBaseUrl}/api/v1/deletedusers/${userId}?tenantId=${tenantId}`
    const pendingRequestKey = `${tenantId}:${userId}`
    let pendingRequest = this.#pendingStateRequests[pendingRequestKey]
    if (!pendingRequest) {
      pendingRequest = window.PDR.api.withScopes('user.readwrite').get(url)
      this.#pendingStateRequests[pendingRequestKey] = pendingRequest
    }
    const response = await pendingRequest
    delete this.#pendingStateRequests[pendingRequestKey]
    const anonymizedUserFirstName = 'N/A'
    if (response.firstName === anonymizedUserFirstName) {
      return 'anonymized'
    }
    return 'removed'
  }

  #userCatalogBaseUrlPromise: Promise<string> | undefined

  get #userCatalogBaseUrl() {
    if (!this.#userCatalogBaseUrlPromise) {
      this.#userCatalogBaseUrlPromise = this.#tryGetUserCatalogBaseUrl()
    }

    return this.#userCatalogBaseUrlPromise
  }

  async #tryGetUserCatalogBaseUrl() {
    const user: pdrUser = await window.PDR.session.getUser()
    const tokenPayload: jwtTokenPayload = JSON.parse(atob(user.access_token.split('.')[1]))
    const issuerHostname = new URL(tokenPayload.iss).hostname

    switch (issuerHostname) {
      case 'login.svc.dev.stratsys.net':
        return 'https://usercatalog.svc.dev.stratsys.net'
      case 'login.svc.test.stratsys.net':
        return 'https://usercatalog.svc.test.stratsys.net'
      case 'login.svc.stratsys.com':
        return 'https://usercatalog.svc.stratsys.com'
    }

    const error = `Access token issuer hostname "${issuerHostname}" does not match any known identity provider.`
    log.error(error)
    throw new Error()
  }
}
