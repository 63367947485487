import { css } from 'lit'

export const style = css`
  input {
    --one-ux-switch-element--width: 28px;
    --one-ux-switch-element--height: 16px;
    --one-ux-switch-element--color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-switch-element--background-color: var(--one-ux-palette--gray-100);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-neutral-300);
    --one-ux-switch-element--handle-color: var(--one-ux-palette--brand-neutral-550);
    --one-ux-switch-element--handle-size: 10px;
    --one-ux-switch-element--handle-spacing: calc(
      (var(--one-ux-switch-element--height) - var(--one-ux-switch-element--handle-size)) / 2
    );
    --one-ux-switch-element--handle-left: calc(
      var(--one-ux-spacing--smallest) - var(--one-ux-switch-element--handle-spacing)
    );

    appearance: none;
    all: unset;

    display: inline-flex;
    box-sizing: border-box;
    width: var(--one-ux-switch-element--width);
    height: var(--one-ux-switch-element--height);

    background-color: var(--one-ux-switch-element--background-color);
    border: var(--one-ux-border-width--500) solid var(--one-ux-switch-element--border-color);
    border-radius: var(--one-ux-radius--700);

    align-items: center;
    justify-content: left;
    margin: var(--one-ux-spacing--200);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
    cursor: pointer;
  }

  input::before {
    content: '';
    background-color: var(--one-ux-switch-element--handle-color);
    background-clip: content-box;

    width: var(--one-ux-switch-element--handle-size);
    height: var(--one-ux-switch-element--handle-size);

    border-radius: var(--one-ux-radius--max);
    border: solid var(--one-ux-switch-element--handle-spacing) transparent;

    position: relative;
    left: var(--one-ux-switch-element--handle-left);
    transition-property: background, border-color, color, left;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  input:hover {
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-neutral-400);
  }

  input:checked {
    --one-ux-switch-element--background-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-switch-element--handle-color: var(--one-ux-palette--gray-100);
  }

  input:checked::before {
    --one-ux-switch-element--handle-left: calc(
      100% - var(--one-ux-switch-element--handle-size) - var(--one-ux-spacing--smallest) - var(
          --one-ux-switch-element--handle-spacing
        )
    );
  }

  input:checked:hover {
    --one-ux-switch-element--background-color: var(--one-ux-palette--brand-neutral-700);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-neutral-700);
  }

  /* PURPOSE MAIN */
  :host([purpose='main']) input:checked {
    --one-ux-switch-element--background-color: var(--one-ux-palette--brand-core-400);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-core-400);
    --one-ux-switch-element--handle-color: var(--one-ux-palette--gray-100);
  }

  :host([purpose='main']) input:checked:hover {
    --one-ux-switch-element--background-color: var(--one-ux-palette--brand-core-500);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-core-500);
  }

  /* PURPOSE CAUTION */
  :host([purpose='caution']) input {
    --one-ux-switch-element--border-color: var(--one-ux-palette--red-300);
  }

  :host([purpose='caution']) input:hover {
    --one-ux-switch-element--border-color: var(--one-ux-palette--red-400);
  }

  :host([purpose='caution']) input:checked {
    --one-ux-switch-element--background-color: var(--one-ux-palette--red-400);
    --one-ux-switch-element--border-color: var(--one-ux-palette--red-400);
  }

  :host([purpose='caution']) input:checked:hover {
    --one-ux-switch-element--background-color: var(--one-ux-palette--red-500);
    --one-ux-switch-element--border-color: var(--one-ux-palette--red-500);
  }

  /* PURPOSE NOTICE */
  :host([purpose='notice']) input {
    --one-ux-switch-element--border-color: var(--one-ux-palette--azure-400);
  }

  :host([purpose='notice']) input:hover {
    --one-ux-switch-element--border-color: var(--one-ux-palette--azure-500);
  }

  :host([purpose='notice']) input:checked {
    --one-ux-switch-element--background-color: var(--one-ux-palette--azure-200);
    --one-ux-switch-element--border-color: var(--one-ux-palette--azure-400);
    --one-ux-switch-element--handle-color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([purpose='notice']) input:checked:hover {
    --one-ux-switch-element--background-color: var(--one-ux-palette--azure-300);
    --one-ux-switch-element--border-color: var(--one-ux-palette--azure-400);
  }

  /* IMPLICIT */
  :host([implicit]) input:not(:checked) {
    --one-ux-switch-element--background-color: transparent;
  }

  /* FOCUS */
  :host([state-keyboard-focus]) input:focus:not(:disabled) {
    --one-ux-switch-element--background-color: var(--one-ux-palette--keyboard-focus-200) !important;
    --one-ux-switch-element--border-color: var(--one-ux-palette--keyboard-focus-400) !important;
    --one-ux-switch-element--handle-color: var(--one-ux-palette--keyboard-focus-700) !important;
  }

  /* DISABLED */
  input:disabled {
    --one-ux-switch-element--background-color: var(--one-ux-palette--gray-150) !important;
    --one-ux-switch-element--border-color: var(--one-ux-palette--gray-300) !important;
    --one-ux-switch-element--handle-color: var(--one-ux-palette--gray-400) !important;

    pointer-events: none;
  }

  input:checked:disabled {
    --one-ux-switch-element--background-color: var(--one-ux-palette--gray-350) !important;
    --one-ux-switch-element--border-color: var(--one-ux-palette--gray-350) !important;
    --one-ux-switch-element--handle-color: var(--one-ux-palette--gray-150) !important;
  }
`
