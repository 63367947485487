export class AccessibilityService {
  #snapshotHandler: any

  async snapshot() {
    if (!this.#snapshotHandler) {
      const { AccessibilityTreeSnapshot } = await import('./AccessibilityTreeSnapshot/AccessibilityTreeSnapshot')
      this.#snapshotHandler = new AccessibilityTreeSnapshot()
    }
    this.#snapshotHandler.create()
  }
}
