import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { IValue, ValueFactory } from '../../mixins/Value.js'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../../mixins/Validated.js'
import { Placeholder } from '../../mixins/Placeholder.js'
import { Focusable } from '../../mixins/Focusable.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { IRequired, Required } from '../../mixins/Required.js'
import { Disabled } from '../../mixins/Disabled.js'
import type { OneUxEditableTextElement } from '../../elements/one-ux-editable-text/OneUxEditableTextElement.js'
import { FormAssociated } from '../../mixins/FormAssociated.js'
import { defaultLabelContext, labelContext } from '../../contexts/LabelContext.js'
import { consume } from '@lit/context'
import { styleMap } from 'lit/directives/style-map.js'

const Styled = StyledFactory(style)
type valueType = string
const Value = ValueFactory<valueType>({
  type: String,
  isEmpty(value) {
    return typeof value === 'string' ? !value : value == null
  }
})

const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }
    const { fieldIsRequired } = getFormValidationLanguage(this)
    const valid = !this.empty
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldIsRequired]
    }
  }
})
const BaseClass = FormAssociated(Disabled(Required(Validated(Value(Placeholder(Focusable(Styled(OneUxElement))))))))

const DEFAULT_LINE_COUNT = 3
const AUTO_SIZE_LINE_COUNT = 2.5

/**
 * An input component for multiple rows of text. Can either be configured with a fixed height or to automatically resize.
 */
@customElement('one-ux-textarea')
export class OneUxTextareaElement extends BaseClass {
  /**
   * Defines the number of lines of text that a `<one-ux-textarea>` will have.
   *
   * It is also possible to provide the value `auto` so that the size will match the text automatically.
   */
  @property({ attribute: 'line-count', type: String })
  public accessor lineCount = DEFAULT_LINE_COUNT as number | 'auto'

  @consume({ context: labelContext, subscribe: true })
  labelContext = defaultLabelContext

  render() {
    const isAutoResize = this.lineCount === 'auto'

    const $textarea = html`<one-ux-editable-text
      style=${styleMap({
        minHeight: isAutoResize ? `calc(${AUTO_SIZE_LINE_COUNT} * var(--one-ux-textarea-element--line-height))` : null
      })}
      .delegateAria=${{
        'aria-label': this.labelContext.label || undefined,
        'aria-required': this.required
      }}
      width="max"
      ?disabled=${this.disabled}
      .value=${this.value || ''}
      enable-line-breaks
      placeholder=${ifDefined(this.placeholder)}
      @beforeinput=${(e: Event) => e.stopPropagation()}
      @input=${this.#handleInput}
      @change=${this.#handleChange}
      @internal-one-ux-validity-changed=${(e: Event) => e.stopPropagation()}
    ></one-ux-editable-text>`

    return html`<div class="one-ux-element--root">
      ${isAutoResize
        ? $textarea
        : html`<one-ux-scroll
            weight="low"
            width="max"
            style=${styleMap({
              height: `calc(${this.lineCount} * var(--one-ux-textarea-element--line-height))`
            })}
          >
            ${$textarea}
          </one-ux-scroll>`}
    </div>`
  }

  #handleInput(event: InputEvent) {
    event.stopPropagation()
    const $target = event.target as OneUxEditableTextElement
    const beforeInputEvent = new InputEvent('beforeinput', {
      bubbles: true,
      composed: true,
      cancelable: true,
      data: $target.value
    })
    if (this.dispatchEvent(beforeInputEvent)) {
      this._applyUserValue($target.value)
      const inputEvent = new InputEvent('input', {
        bubbles: true,
        composed: true,
        cancelable: false
      })
      this.dispatchEvent(inputEvent)
    } else {
      $target.value = this.value ?? ''
    }
  }

  #handleChange(event: Event) {
    event.stopPropagation()
    this.dispatchEvent(new Event('change', event))
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-textarea': OneUxTextareaElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-textarea': OneUxTextareaElement
    }
  }
}
