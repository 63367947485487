import { WildcardVersion } from './WildcardVersion.js'
import { MajorVersion } from './MajorVersion.js'
import { Version } from '../../Version.js'
import { NumberedVersion } from '../NumberedVersion.js'

const wildcard = '^'
export class MinorVersion extends WildcardVersion {
  fulfills(version: Version): boolean {
    if (version instanceof MajorVersion) {
      if (this.major === version.major) {
        if (this.minor === version.minor) {
          return this.patch >= version.patch
        }
        return this.minor > version.minor
      }
      return this.major >= version.major
    } else if (version instanceof MinorVersion) {
      if (this.major !== version.major) {
        return false
      }
      if (this.minor === version.minor) {
        return this.patch >= version.patch
      }
      return this.minor > version.minor
    }
    return false
  }

  includes(version: Version) {
    if (version.type !== 'numbered') {
      return false
    }

    const numberedVersion = version as NumberedVersion

    if (this.major !== numberedVersion.major) {
      return false
    }

    if (this.minor === numberedVersion.minor) {
      return this.patch <= numberedVersion.patch
    }
    return this.minor < numberedVersion.minor
  }

  static matches(versionString: string) {
    return versionString[0] === wildcard
  }
}
