import './generated/design-tokens.css'
import './generated/typography.css'
import './one-ux.css'

import './styling/rootTheme.js'
import './styling/keyboardOnlyState.js'
import './fonts/index.js'

// Ensure we always have a capturing context root to avoid failed context requests due to order and timing of custom element registrations.
import './contextRoot.js'

import './contexts/tree/contextual-one-ux-tree/ContextualOneUxTreeElement.js'

import './elements/index.js'

import('./visualizations')

if (import.meta.webpackHot) {
  import.meta.webpackHot.decline()
}
