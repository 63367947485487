import { html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Label } from '../../mixins/Label.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Focusable } from '../../mixins/Focusable.js'
import { SlotController } from '../../controllers/SlotController.js'
import { consume } from '@lit/context'
import { classMap } from 'lit/directives/class-map.js'
import { defaultTabsNextContext, tabsNextContext } from '../one-ux-tabs-next/TabsNextContext.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Optional } from '../../types.js'
import { Errors } from '../../mixins/Errors.js'
import { ErrorsPopout } from '../../fragments/ErrorsPopout.js'
import { Indicator } from './Indicator.js'
import { PurposeFactory } from '../../mixins/Purpose.js'

const Styled = StyledFactory(style)
const Purpose = PurposeFactory({ purposes: ['default', 'ai', 'placeholder'] })

const BaseClass = Purpose(Errors(Disabled(Focusable(Label(Styled(OneUxElement))))))

@customElement('one-ux-tab-next')
export class OneUxTabNextElement extends BaseClass {
  @consume({ context: tabsNextContext, subscribe: true })
  _tabsNextContext = defaultTabsNextContext

  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['start', 'end', 'adornment']
  })

  @property({ type: Boolean })
  public accessor active: boolean = false

  @property({ type: String })
  public accessor name: Optional<string>

  @state()
  private accessor _isHovered = false

  public provideTabPanelRefs(tabId: string, tabpanelRefId: string) {
    const $tab = this.shadowRoot!.querySelector('[role="tab"]')
    $tab?.setAttribute('id', tabId)
    $tab?.setAttribute('aria-controls', tabpanelRefId)
  }

  public click() {
    if (this.disabled) return
    const $tab = this.shadowRoot!.querySelector<HTMLButtonElement>('[role="tab"]')
    $tab?.click()
  }

  protected willUpdate(): void {
    this._tabsNextContext.updateTab(this)
    this.toggleAttribute('state-implicit', this._tabsNextContext.implicit)
  }

  guardedRender() {
    return html`<button
        type="button"
        role="tab"
        class=${classMap({
          'one-ux-element--root': true,
          'is-implicit': this._tabsNextContext.implicit,
          [`is-weight-${this._tabsNextContext.weight}`]: true,
          'is-tablist-focused': this._tabsNextContext.isTablistFocused
        })}
        aria-label=${this.label}
        aria-disabled=${this.disabled}
        aria-selected=${this.active}
        tabindex=${this._tabsNextContext.isFocused(this) ? '0' : '-1'}
        one-ux-tooltip=${ifDefined(this.#labelTooltip)}
        ?one-ux-tooltip-custom-aria=${!!this.#labelTooltip}
        @mousedown=${() => (this._isHovered = false)}
        @click=${this.#handleClick}
        @blur=${this._tabsNextContext.onTabBlur}
        @mouseenter=${() => (this._isHovered = true)}
        @mouseleave=${() => (this._isHovered = false)}
      >
        <slot name="start"></slot>
        ${this.#aiIconFragment()}
        <slot></slot>
        ${this.#slots.hasDefaultSlot() ? nothing : html`<span class="label">${this.label}</span>`}
        <slot name="end"></slot>
        ${this.#pillFragment('end')}
        <div class="adornments">
          ${this.#pillFragment('adornment')}
          <slot name="adornment"></slot>
        </div>
        ${this.#indicatorFragment()}
      </button>
      ${ErrorsPopout({
        reference: 'previous',
        errors: this.disabled ? [] : this.errors,
        hidden: this.hideErrors
      })}`
  }

  #indicatorFragment() {
    const isActionable = !this.disabled && !this.active
    const isKeyboardFocused = this._tabsNextContext.isTablistFocused && this._tabsNextContext.isFocused(this)
    return isActionable && (this._isHovered || isKeyboardFocused)
      ? Indicator({
          implicit: this._tabsNextContext.implicit,
          weight: this._tabsNextContext.weight,
          purpose: this.purpose,
          isKeyboardFocused
        })
      : nothing
  }

  #aiIconFragment() {
    return !this.#slots.hasNamedSlot('start') && this.purpose === 'ai'
      ? html`<one-ux-icon set="ai" icon="ai-powered" size="icon-100" purpose="ai"></one-ux-icon>`
      : nothing
  }

  #pillFragment(placement: 'adornment' | 'end') {
    if (!this.errors.length) {
      return nothing
    }

    if (!this._tabsNextContext.implicit && placement === 'adornment') {
      return html`<one-ux-pill purpose="caution"></one-ux-pill>`
    }

    return this._tabsNextContext.implicit && placement === 'end'
      ? html`<one-ux-pill purpose="caution" style="align-self: end"></one-ux-pill>`
      : nothing
  }

  get #labelTooltip() {
    if (this.#slots.hasSingleDefaultSlot('one-ux-icon')) {
      return this.label
    }
  }

  #handleClick() {
    if (this.disabled) return
    this._tabsNextContext.changeTab(this)
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tab-next': OneUxTabNextElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tab-next': OneUxTabNextElement
    }
  }
}
