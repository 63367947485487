import themes from './themes.js'

const style = document.createElement('style')
style.textContent = `
:root {
  ${Object.keys(themes.light)
    .map((key) => `${key}: ${(themes as any).light[key]} !important;`)
    .join('\n')}
}
`
style.id = 'one-ux-root-theme-variables'
document.head.appendChild(style)
