import { OneUxElement } from '../../OneUxElement.js'
import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { style } from './style.js'
import { StyledFactory } from '../../mixins/Styled.js'

import { Implicit } from '../../mixins/Implicit.js'
import { OneUxIconToken, OneUxPaletteToken } from '../../generated/design-tokens.js'
import { Focusable } from '../../mixins/Focusable.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Optional } from '../../types.js'
import { styleMap } from 'lit/directives/style-map.js'

const Styled = StyledFactory(style)

const BaseClass = Disabled(Focusable(Implicit(Styled(OneUxElement))))

// TODO: Make type "dynamic" based on current set
type icons = OneUxIconToken[keyof OneUxIconToken]

@customElement('one-ux-widget-button')
export class OneUxWidgetButtonElement extends BaseClass {
  @property({ type: String })
  public accessor set = 'default' as keyof OneUxIconToken

  @property({ type: String })
  public accessor icon!: icons

  @property({ type: String })
  public accessor label!: string

  @property({ type: Boolean })
  public accessor accessible = false

  @property({ type: String })
  public accessor color: Optional<OneUxPaletteToken>

  render() {
    const scrubbedColor = this.color?.replaceAll(/[^a-z0-9-]/gi, '').trim()

    return html`<div
      class="one-ux-element--root widget"
      style=${styleMap({
        '--one-ux-widget-button-element--color': scrubbedColor ? `var(--one-ux-palette--${scrubbedColor})` : undefined
      })}
      ?state-disabled=${this.disabled}
    >
      ${this.accessible && !this.disabled
        ? html`<button one-ux-tooltip=${this.label} aria-label=${this.label} ?disabled=${this.disabled} type="button">
            <one-ux-icon part="icon" set=${this.set} icon=${this.icon}></one-ux-icon>
          </button>`
        : html`<one-ux-icon part="icon" set=${this.set} icon=${this.icon}></one-ux-icon>`}
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-widget-button': OneUxWidgetButtonElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-widget-button': OneUxWidgetButtonElement
    }
  }
}
