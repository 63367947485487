import { OneUxIconToken } from '../../generated/design-tokens.js'
import { svgSourceService } from './SvgSourceService.js'

// Change cacheBust if the structure has to change and become incompatible
const cacheBust = '33bda878-985a-4005-8f48-a0873b53dc99'
const cachePrefix = 'one-ux-icon-cached-svg-sprite'
const cacheKey = `${cachePrefix}-${cacheBust}`
const cacheLimitInKb = 1024 * 256 // Slightly less than 125 icons

function clearOldCacheEntries() {
  Array.from({ length: localStorage.length }, (_, i) => localStorage.key(i))
    .filter((x) => x?.startsWith(cachePrefix) && !x?.endsWith(cacheBust))
    .forEach((x) => x && localStorage.removeItem(x))
}
clearOldCacheEntries()

class IconManager {
  #parser = new DOMParser()
  #serializer = new XMLSerializer()
  #sprite

  constructor() {
    this.#sprite = this.#loadSpriteSheetFromCache()
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.#saveSpriteSheet(this.#sprite)
      }
    })
  }

  get(icon: string, set: keyof OneUxIconToken): SVGElement | null {
    let $icon = this.#getIconFromSpriteSheet(icon, set)
    if (!$icon) {
      try {
        const svgSource = svgSourceService.cachedSource(icon, set)
        $icon = this.#saveIcon(svgSource, icon, set)
      } catch {
        return null
      }
    }
    return $icon.cloneNode(true) as SVGElement
  }

  #getIconFromSpriteSheet(icon: string, set: keyof OneUxIconToken) {
    const iconId = this.#getId(icon, set)
    // Needs to be querySelector, not getElementById (for Safari bug)
    return this.#sprite.querySelector('#' + iconId) as SVGElement
  }

  #loadSpriteSheetFromCache() {
    const cachedSvgText = localStorage.getItem(cacheKey)
    if (cachedSvgText) {
      const $svgDocument = this.#parser.parseFromString(cachedSvgText, 'image/svg+xml')
      const $svg = $svgDocument.documentElement as unknown as SVGElement
      if ($svg.tagName === 'svg') {
        Array.from($svg.children).forEach((child) => !child.id && child.remove())
        return $svg
      }
    }
    return document.createElementNS('http://www.w3.org/2000/svg', 'svg') as SVGElement
  }

  #saveSpriteSheet($sprite: SVGElement) {
    const $spriteClone = $sprite.cloneNode(true) as SVGElement
    let cacheValue = this.#serializeSpriteSheet($spriteClone)
    // x2 due to unicode
    while (cacheValue.length * 2 > cacheLimitInKb) {
      if (!$spriteClone.firstChild) {
        break
      }
      $spriteClone.removeChild($spriteClone.firstChild)
      cacheValue = this.#serializeSpriteSheet($spriteClone)
    }
    localStorage.setItem(cacheKey, cacheValue)
  }

  #serializeSpriteSheet($sprite: SVGElement) {
    return this.#serializer.serializeToString($sprite)
  }

  async update(icon: string, set: keyof OneUxIconToken) {
    const svgSource = await svgSourceService.loadSource(icon, set)
    this.#saveIcon(svgSource, icon, set)
  }

  #saveIcon(svgSource: string, icon: string, set: keyof OneUxIconToken) {
    const $icon = this.#parseSvgSource(svgSource, icon, set)

    const $cachedIcon = this.#getIconFromSpriteSheet(icon, set)
    if ($cachedIcon) {
      $cachedIcon.replaceWith($icon)
    } else {
      this.#sprite.appendChild($icon)
    }
    return $icon
  }

  #parseSvgSource(source: string, icon: string, set: keyof OneUxIconToken) {
    const skippedSvgAttributes = ['xmlns', 'xmlns:xlink', 'version']
    const $doc = this.#parser.parseFromString(source, 'image/svg+xml')
    const $error = $doc.querySelector('parsererror')
    if ($error) {
      throw new Error(`Can't parse icon markup.`)
    }
    const $svg = $doc.firstElementChild
    if (!$svg) {
      throw new Error('Invalid icon format')
    }
    const $icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg') as SVGElement
    Array.from($svg.attributes).forEach((attr) => {
      if (!skippedSvgAttributes.includes(attr.nodeName)) {
        $icon.setAttribute(attr.nodeName, attr.nodeValue || '')
      }
    })
    $icon.append(...$svg.children)
    $icon.setAttribute('preserveAspectRatio', 'xMidYMid')

    const iconId = this.#getId(icon, set)
    $icon.id = iconId
    return $icon
  }

  #getId(icon: string, set: keyof OneUxIconToken) {
    return `one-ux-${set}-${icon}`.toLowerCase()
  }
}

export const iconManager = new IconManager()
