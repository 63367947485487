import { ReactiveController } from 'lit'
import { BASE_Z_INDEX } from './style.js'
import type { OneUxDialogElement } from './OneUxDialogElement.js'

export class DialogController implements ReactiveController {
  #animateRequest = 0
  #currentLeft = 0
  #currentTop = 0

  constructor(private $element: OneUxDialogElement) {
    this.$element.addController(this)
  }

  hostConnected() {
    this.$element.addEventListener('wheel', this.#handleScroll)
  }

  hostDisconnected() {
    this.stopPositioning()
    this.$element.removeEventListener('wheel', this.#handleScroll)
  }

  startPositioning() {
    const openDialogs = document.querySelectorAll('one-ux-dialog[visible]')
    this.$element.style.zIndex = (BASE_Z_INDEX + openDialogs.length).toString()

    this.#update()
  }

  #update = () => {
    this.#animateRequest = requestAnimationFrame(() => {
      const left = Math.floor((window.innerWidth - this.$element.clientWidth) / 2)
      const top = Math.floor((window.innerHeight - this.$element.clientHeight) / 2)

      if (left !== this.#currentLeft && Math.abs(left - this.#currentLeft) > 1) {
        this.$element.style.setProperty('--one-ux-dialog-element--left', left + 'px')
        this.#currentLeft = left
      }
      if (top !== this.#currentTop && Math.abs(top - this.#currentTop) > 1) {
        this.$element.style.setProperty('--one-ux-dialog-element--top', top + 'px')
        this.#currentTop = top
      }
      this.#update()
    })
  }

  stopPositioning() {
    cancelAnimationFrame(this.#animateRequest)

    if (this.#currentLeft !== 0) {
      this.#currentLeft = 0
      this.$element.style.setProperty('--one-ux-dialog-element--left', '0')
    }

    if (this.#currentTop !== 0) {
      this.#currentTop = 0
      this.$element.style.setProperty('--one-ux-dialog-element--top', '0')
    }
  }

  #handleScroll = (event: WheelEvent) => {
    const scrollingUp = event.deltaY < 0
    const scrollingDown = event.deltaY > 0
    for (const $node of event.composedPath() as HTMLElement[]) {
      if ($node === this.$element) {
        break
      }
      if ($node instanceof ShadowRoot) {
        continue
      }

      const hasContentOverflow = $node.clientHeight < $node.scrollHeight
      const overflowConfig = window.getComputedStyle($node).overflowY.toLowerCase()
      const scrollBarsEnabled = overflowConfig === 'auto' || overflowConfig === 'scroll'
      const couldScroll = hasContentOverflow && scrollBarsEnabled

      if (couldScroll) {
        const canScrollUp = $node.scrollTop > 0
        const canScrollDown = $node.scrollTop < $node.scrollHeight - $node.clientHeight - 1

        const isScrolling = (canScrollUp && scrollingUp) || (canScrollDown && scrollingDown)

        if (isScrolling) {
          return
        }
      }
    }
    event.preventDefault()
  }
}
