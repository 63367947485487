import { OneUxElement } from '../../OneUxElement.js'
import { html } from 'lit'
import { customElement, state } from 'lit/decorators.js'
import { style } from './style.js'
import { Implicit } from '../../mixins/Implicit.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Focusable } from '../../mixins/Focusable.js'
import { Placeholder } from '../../mixins/Placeholder.js'
import { getLanguage } from './language.js'
import { Compact } from '../../mixins/Compact.js'
import { ValueFactory } from '../../mixins/Value.js'
import { styleMap } from 'lit/directives/style-map.js'
import { classMap } from 'lit/directives/class-map.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Busy } from '../../mixins/Busy.js'
import { Label } from '../../mixins/Label.js'
import { keyCodes } from '../../utils.js'

const Styled = StyledFactory(style)
const Value = ValueFactory<string>({
  defaultValue() {
    return ''
  },
  type: String
})

const BaseClass = Busy(Label(Value(Compact(Placeholder(Disabled(Focusable(Implicit(Styled(OneUxElement)))))))))

@customElement('one-ux-search')
export class OneUxSearchElement extends BaseClass {
  @state()
  private accessor _userExpanded = false

  constructor() {
    super()
    const { translations } = getLanguage(this)
    this.label = translations.search

    this.addEventListener('blur', () => {
      this._userExpanded = false
    })
  }

  protected render() {
    const { translations, lang } = getLanguage(this)
    const collapsed = this.compact && !this.value && !this._userExpanded

    this.toggleAttribute('state-collapsed', collapsed)

    return html`
      <div
        class=${classMap({
          'one-ux-element--root': true,
          empty: this.empty
        })}
        role=${ifDefined(collapsed ? 'button' : undefined)}
        tabindex=${ifDefined(collapsed ? 0 : -1)}
        one-ux-tooltip=${ifDefined(collapsed ? this.label : undefined)}
        aria-label=${ifDefined(collapsed ? this.label : undefined)}
        aria-expanded=${ifDefined(collapsed ? false : undefined)}
        ?disabled=${collapsed && this.disabled}
        lang=${lang}
        @click=${this.#handleUserExpand}
        @keypress=${this.#handleKeyPress}
        @scroll=${(event: Event & { target: HTMLElement }) => {
          event.target.scrollLeft = 0
        }}
        @focus=${() => {
          if (!collapsed) {
            this.shadowRoot!.querySelector('input')?.focus()
          }
        }}
      >
        <one-ux-icon icon="search" size="200"></one-ux-icon>

        <input
          ?disabled=${this.disabled}
          placeholder=${this.placeholder || translations.search}
          .value=${this.value}
          aria-label=${this.label}
          @input=${this.#handleUserInput}
          @change=${this.#handleChange}
        />

        <one-ux-widget-button
          class="clear"
          style=${styleMap({
            visibility: this.empty || this.disabled ? 'hidden' : null
          })}
          icon="remove"
          ?disabled=${this.disabled}
          @click=${this.#handleUserClear}
        ></one-ux-widget-button>

        <div
          class=${classMap({
            indicator: true,
            passive: this.implicit && !this.disabled && !collapsed,
            busy: this.busy,
            done: this.done
          })}
        ></div>
      </div>
    `
  }

  #handleUserInput = (event: InputEvent & { target: HTMLInputElement }) => {
    event.preventDefault()
    event.stopPropagation()
    this.value = event.target.value
    this.#dispatch()
  }

  #handleChange = (event: InputEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  #handleUserExpand = async () => {
    if (this.disabled) {
      return
    }
    this._userExpanded = true
    await this.updateComplete
    this.shadowRoot!.querySelector('input')?.focus()
  }

  #handleUserClear = () => {
    if (this.disabled) {
      return
    }
    this.value = ''
    this.#dispatch()
  }

  #handleKeyPress = (event: KeyboardEvent & { target: HTMLElement }) => {
    if (!event.target.classList.contains('one-ux-element--root')) {
      return
    }

    switch (event.code) {
      case keyCodes.RETURN:
      case keyCodes.NUMPADRETURN:
      case keyCodes.SPACE:
        event.preventDefault()
        event.stopPropagation()
        event.target.click()
        break
    }
  }

  #debounce!: ReturnType<typeof setTimeout>
  #dispatch = () => {
    this.dispatchEvent(new Event('input'))
    if (this.#debounce) {
      clearTimeout(this.#debounce)
    }
    this.#debounce = setTimeout(() => {
      this.dispatchEvent(new Event('change'))
    }, 300)
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-search': OneUxSearchElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-search': OneUxSearchElement
    }
  }
}
