import { css } from 'lit'

export const style = css`
  .link {
    --one-ux-link-element--color: var(--one-ux-palette--brand-core-400);
    --one-ux-link-element--background-color: transparent;
    --one-ux-link-element--line-height: 140%;
    --one-ux-link-element--font: var(--one-ux-font--body-300);
    --one-ux-link-element--letter-spacing: var(--one-ux-letter-spacing--body-300);

    color: var(--one-ux-link-element--color);
    background-color: var(--one-ux-link-element--background-color);
    line-height: var(--one-ux-link-element--line-height);
    font: var(--one-ux-link-element--font);
    letter-spacing: var(--one-ux-link-element--letter-spacing);

    transition-property: background-color, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);

    display: inline-flex;
    gap: var(--one-ux-spacing--smaller);
    align-items: flex-start;

    .text {
      flex-grow: 1;
      overflow-wrap: break-word;
      min-width: 0;
    }
  }

  :host::before {
    /* Zero-width whitespace forces the base line to be the same regardless of icon */
    content: '\\200B' !important;
  }

  .link:focus {
    outline: none;
  }

  .link:hover {
    --one-ux-link-element--color: var(--one-ux-palette--brand-core-500);
  }

  .link:is(:active, :visited) {
    --one-ux-link-element--color: var(--one-ux-palette--violet-500);
  }

  :host([implicit]) .link {
    --one-ux-link-element--color: inherit;
    --one-ux-link-element--line-height: inherit;
    --one-ux-link-element--font: inherit;
    --one-ux-link-element--letter-spacing: inherit;
  }

  :host([implicit]:not([state-keyboard-focus])) .link:hover {
    opacity: 0.75;
  }

  :host([implicit]:not([state-keyboard-focus])) .link:is(:active, :visited) {
    opacity: 0.5;
  }

  :host([state-keyboard-focus]) .link {
    --one-ux-link-element--color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-link-element--background-color: var(--one-ux-palette--keyboard-focus-300);
  }

  :host([state-keyboard-focus]) .link:is(:active, :visited) {
    --one-ux-link-element--background-color: var(--one-ux-palette--keyboard-focus-400);
  }

  :host(:not([implicit])) :is([name='start'], [name='end'])::slotted(one-ux-icon) {
    font-size: var(--one-ux-size--icon-200);
  }
`
