export const requestedImageSizes = [48, 64, 96] as const

export type requestedImageSize = (typeof requestedImageSizes)[number]

export type userCatalogPhotoDto = {
  profilePhotoUri: string
}

export type userCatalogUserDto = {
  firstName: string
  lastName: string
}

export type pdrUser = {
  profile: { tid: string }
  access_token: string
}

export type jwtTokenPayload = {
  iss: string
}
