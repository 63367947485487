import { Version } from './versions/Version.js'

export class Utils {
  available(tag: string) {
    const CustomElement = customElements.get(tag) as CustomElementConstructor & { version: Version }
    if (CustomElement) {
      return CustomElement.version.toString() || 'unknown'
    }
    return ''
  }
}
