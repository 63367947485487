export class BeforeCloseEvent extends Event {
  static get eventName() {
    return 'beforeclose'
  }

  constructor() {
    super(BeforeCloseEvent.eventName, {
      bubbles: false,
      cancelable: true,
      composed: false
    })
  }
}
