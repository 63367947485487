import { LitElement, css, html } from 'lit'
import { customElement } from 'lit/decorators.js'

/**
 * The text component is only used to position text in components that use slots.
 * It only allows text children and will hide all other elements.
 *  */
@customElement('one-ux-text')
export class OneUxText extends LitElement {
  static styles = css`
    ::slotted(*) {
      display: none !important;
      visibility: hidden !important;
      position: fixed !important;
      pointer-events: none !important;
    }
  `

  protected render() {
    return html`<slot></slot>`
  }
}
