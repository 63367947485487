import { LanguageSets, getLangCode } from '../../utils/getLangCode.js'

export type LanguageSet = {
  close: string
  startOfContent: string
}

const languages: LanguageSets<LanguageSet> = {
  en: {
    close: 'Close',
    startOfContent: 'Start of contents'
  },
  sv: {
    close: 'Stäng',
    startOfContent: 'Början av innehållet'
  },
  nb: {
    close: 'Lukk',
    startOfContent: 'Start av innhold'
  },
  fi: {
    close: 'Sulje',
    startOfContent: 'Sisällön alkuun'
  },
  da: {
    close: 'Luk',
    startOfContent: 'Start af indhold'
  }
}

export const getLanguage = (element: Element): { translations: LanguageSet; lang?: string } => {
  const closestLang = getLangCode(element)
  const translations = languages[closestLang]

  return { translations, lang: closestLang }
}
