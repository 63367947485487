import { Optional } from '../../types.js'
import { type isoDateString, isoDate } from './IsoDate.js'

export type calendarModel = {
  weeks: weekModel[]
  firstDayInActiveWeek: isoDateString
  lastDayInActiveWeek: isoDateString
}

export type weekModel = {
  number: number
  dates: dateModel[]
}

export type dateModel = {
  value: isoDateString
  isSelected: boolean
  isDisabled: boolean
  isRangeStart: boolean
  isRangeEnd: boolean
  isInRange: boolean
  isToday: boolean
  isActive: boolean
  isPadding: boolean
  isOverlapping: boolean
}

export function buildIso8601CalendarModel({
  activeDate,
  otherActiveDate,
  value,
  pendingUnsortedRangeValue,
  min,
  max,
  disabled
}: {
  activeDate: isoDateString
  otherActiveDate: isoDateString
  value: isoDateString[]
  pendingUnsortedRangeValue: isoDateString[] | null
  min: Optional<isoDateString>
  max: Optional<isoDateString>
  disabled: boolean
}): calendarModel {
  const numberOfWeeks = 6
  const todayDate = isoDate.today()
  const pendingSortedRangeValue = pendingUnsortedRangeValue?.toSorted()
  const [otherActiveYear, otherActiveMonth] = isoDate.getParts(otherActiveDate)
  const rangeValue = value.length ? [value[0], value[1] || value[0]] : []

  const getDaysSinceWeekStart = (date: isoDateString) => isoDate.getIsoWeekdayIndex(date)

  const buildDate = (date: isoDateString, isPadding: boolean): dateModel => {
    const [startDate, endDate] = pendingSortedRangeValue ? pendingSortedRangeValue : rangeValue

    let isRangeStart = date === startDate
    let isRangeEnd = date === endDate
    let isInRange =
      !isRangeStart &&
      !isRangeEnd &&
      (pendingSortedRangeValue
        ? isoDate.isInRange(date, pendingSortedRangeValue[0], pendingSortedRangeValue[1])
        : !!rangeValue.length && isoDate.isInRange(date, rangeValue[0], rangeValue[1]))

    const [year, month] = isoDate.getParts(date)
    const isOverlapping = isPadding && year === otherActiveYear && month === otherActiveMonth

    if (isOverlapping) {
      const [startYear, startMonth] = startDate ? isoDate.getParts(startDate) : []
      const [endYear, endMonth] = endDate ? isoDate.getParts(endDate) : []

      const redundantStartDate =
        otherActiveDate > activeDate && startYear === otherActiveYear && startMonth === otherActiveMonth
      const redundantEndDate =
        otherActiveDate < activeDate && endYear === otherActiveYear && endMonth === otherActiveMonth

      if (redundantStartDate || redundantEndDate) {
        isRangeStart = false
        isRangeEnd = false
        isInRange = false
      }
    }

    return {
      value: date,
      isSelected: date === rangeValue[0] || date === rangeValue[1],
      isDisabled: disabled || !isoDate.isInRange(date, min, max),
      isRangeStart,
      isRangeEnd,
      isInRange,
      isToday: date === todayDate,
      isActive: date === activeDate,
      isPadding,
      isOverlapping
    }
  }

  const emptyDaysFirstWeekInMonth = getDaysSinceWeekStart(isoDate.getFirstDateInMonth(activeDate))
  const datesPreviousMonth = isoDate
    .getAllDatesInMonth(isoDate.addMonths(activeDate, -1))
    .slice(-emptyDaysFirstWeekInMonth, emptyDaysFirstWeekInMonth ? undefined : 0)

  const datesActiveMonth = isoDate.getAllDatesInMonth(activeDate)

  const datesNextMonth = isoDate
    .getAllDatesInMonth(isoDate.addMonths(activeDate, 1))
    .slice(0, numberOfWeeks * 7 - datesPreviousMonth.length - datesActiveMonth.length)

  const dateModels = [
    ...datesPreviousMonth.map((date) => buildDate(date, true)),
    ...datesActiveMonth.map((date) => buildDate(date, false)),
    ...datesNextMonth.map((date) => buildDate(date, true))
  ]

  const firstDayInActiveWeek = isoDate.addDays(activeDate, -getDaysSinceWeekStart(activeDate))

  const weeks = Array.from({ length: numberOfWeeks }, () => {
    const dates = dateModels.splice(0, 7)
    return {
      number: isoDate.getIsoWeek(dates[0].value),
      dates
    }
  })

  return {
    weeks,
    firstDayInActiveWeek,
    lastDayInActiveWeek: isoDate.addDays(firstDayInActiveWeek, 6)
  }
}
