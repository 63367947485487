import { LogModuleConfig, PDRConfig, PDRWebviewConfig } from '../types.js'

export class LogSettings {
  #config
  constructor(config: LogModuleConfig) {
    this.#config = config
  }

  static build(config: PDRConfig | PDRWebviewConfig) {
    return new LogSettings(config.log || {})
  }

  get message() {
    return typeof this.#config.message !== 'undefined' ? !!this.#config.message : undefined
  }
  get info() {
    return typeof this.#config.info !== 'undefined' ? !!this.#config.info : undefined
  }
  get warning() {
    return typeof this.#config.warning !== 'undefined' ? !!this.#config.warning : undefined
  }
  get error() {
    return typeof this.#config.error !== 'undefined' ? !!this.#config.error : undefined
  }
  get debug() {
    return typeof this.#config.debug !== 'undefined' ? !!this.#config.debug : undefined
  }
  get timer() {
    return typeof this.#config.timer !== 'undefined' ? !!this.#config.timer : undefined
  }
  get tracker() {
    return typeof this.#config.tracker !== 'undefined' ? !!this.#config.tracker : undefined
  }
}
