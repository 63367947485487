import { css } from 'lit'

/* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
export const style = css`
  .resize-container {
    display: flex;
    position: relative;
    flex: 1;
    border-radius: var(--one-ux-radius--300);
    box-sizing: border-box;
    min-width: 74px;
    overflow-wrap: anywhere;
    word-break: normal;
  }

  .resize-container::after {
    /* This element will handle resize.  */
    /* Additional zero-width whitespace required to prevent flickering and collapsing when empty. */
    content: attr(replicated-value) '\\200B';
    visibility: hidden;
    white-space: pre-wrap;
    pointer-events: none;
  }

  textarea {
    resize: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: inherit;
  }

  textarea::placeholder {
    color: var(--one-ux-palette--brand-neutral-400);
  }

  .resize-container textarea,
  .resize-container::after {
    /* textarea and ::after  have to have identical styling for auto resize to work. */
    background: none;
    box-sizing: border-box;
    border: none;
    padding: 0;
    outline: none;

    font: inherit;
    letter-spacing: inherit;
  }
`
