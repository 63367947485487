import { createContext } from '@lit/context'
import { weight } from '../mixins/Weight.js'
import { OneUxTabElement } from '../elements/one-ux-tab/OneUxTabElement.js'

export interface ITabsContext {
  weight: weight
  implicit: boolean
  isTablistFocused: boolean
  updateTab($tab: OneUxTabElement): void
  changeTab($tab: OneUxTabElement): void
  isActive($tab: OneUxTabElement): boolean
  isSelected($tab: OneUxTabElement): boolean
  onTabBlur(event: FocusEvent): void
}

const contextKey = Symbol('one-ux-tabs-context')

export const defaultTabsContext: ITabsContext = {
  weight: 'normal',
  implicit: false,
  isTablistFocused: false,
  updateTab() {},
  changeTab() {},
  isActive() {
    return false
  },
  isSelected() {
    return false
  },
  onTabBlur() {}
}

export const tabsContext = createContext<ITabsContext>(contextKey)
