import { OneUxElementTheme } from '../OneUxElement.js'

import { OneUxScrollElementTheme } from '../elements/one-ux-scroll/theme.js'
import { OneUxTreeElementTheme } from '../contexts/tree/contextual-one-ux-tree/theme.js'

export const OneUxFieldElementTheme: OneUxElementTheme<{
  '--contextual-one-ux-field-element--label-color': string
}> = {
  light: {
    '--contextual-one-ux-field-element--label-color': 'var(--one-ux-palette--brand-neutral-600)'
  },
  dark: {
    '--contextual-one-ux-field-element--label-color': 'var(--one-ux-palette--gray-100)'
  }
}

export default createThemes(OneUxFieldElementTheme, OneUxScrollElementTheme, OneUxTreeElementTheme)

function createThemes(...themes: OneUxElementTheme<Record<string, unknown>>[]) {
  const result: OneUxElementTheme<Record<string, unknown>> = {
    light: {},
    dark: {}
  }
  themes.forEach((theme) => {
    result.light = {
      ...result.light,
      ...theme.light
    }
    result.dark = {
      ...result.dark,
      ...theme.dark
    }
  })
  return result
}
