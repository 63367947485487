import { log } from '../log.js'

export class HttpResult {
  #request: XMLHttpRequest
  constructor(request: XMLHttpRequest) {
    this.#request = request
  }

  get headers() {
    return this.#request
      .getAllResponseHeaders()
      .split('\r\n')
      .reduce(
        (acc, next) => {
          const parts = (next || '').split(':')
          if (parts.length >= 2) {
            acc[parts[0].toLowerCase().trim()] = parts.slice(1).join(':').trim()
          }
          return acc
        },
        {} as Record<string, string>
      )
  }

  get status() {
    return this.#request.status
  }
  get successful() {
    return this.#request.status >= 200 && this.#request.status < 300
  }
  get response() {
    if (this.#request.responseType === 'blob') {
      return this.#request.response
    }

    const contentType = (this.#request.getResponseHeader('Content-Type') || '').toLowerCase()

    if (contentType.includes('application/zip')) {
      log.warning(
        "Response with header Content-Type: application/zip is parsed to blob, consider passing option responseType: 'blob' for [GET] request"
      )
      return this.#request.response ? new Blob([this.#request.response], { type: 'application/zip' }) : null
    }

    const responseText = (this.#request.responseText || '').trim()

    if (contentType.includes('application/json')) {
      return responseText ? JSON.parse(responseText) : null
    }

    if (contentType.includes('text/plain')) {
      return responseText
    }

    return responseText
  }

  get responseText() {
    return this.#request.responseText
  }
}
