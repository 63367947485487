let mouseMoveTimeout: ReturnType<typeof setTimeout>
let mouseMoving = false

let currentX = 0
let currentY = 0

export function getCursorPosition() {
  return {
    cursorX: currentX,
    cursorY: currentY
  }
}

document.addEventListener(
  'mousemove',
  (e: MouseEvent) => {
    currentX = e.clientX
    currentY = e.clientY

    clearTimeout(mouseMoveTimeout)
    mouseMoveTimeout = setTimeout(() => {
      mouseMoving = false
    }, 100)
    mouseMoving = true
  },
  {
    capture: true
  }
)

export const hasMouseMovement = () => {
  return mouseMoving
}

let mouseActive = false
let pendingMouseInactive: ReturnType<typeof setTimeout>
const updateMouseActivity = () => {
  mouseActive = true
  clearTimeout(pendingMouseInactive)
  pendingMouseInactive = setTimeout(() => {
    mouseActive = false
  }, 500)
}
document.addEventListener('mousedown', updateMouseActivity, { capture: true })
document.addEventListener('mouseup', updateMouseActivity, { capture: true })

export const hasMouseActivity = () => {
  return mouseActive
}
