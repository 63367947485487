/* 
  Javscript % is a remainder operator, not a modulo operator.
  Most notable difference in result when there is sign difference between the two arguments.
  https://2ality.com/2019/08/remainder-vs-modulo.html

  Proposal to add modulo to Javascript:
  https://github.com/tc39/proposal-integer-and-modulus-math?tab=readme-ov-file#modulus

  Recommended implementation of modulo, see "Description" paragraphs:
  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Remainder
*/
export const modulo = (n: number, d: number) => ((n % d) + d) % d
