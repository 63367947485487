export class InternalValidityVisibleEvent extends Event {
  static get eventName() {
    return 'internal-one-ux-validity-visible'
  }

  constructor() {
    super(InternalValidityVisibleEvent.eventName, {
      bubbles: true,
      cancelable: false,
      composed: true
    })
  }
}
