import { PDRModule } from '../PDRModule.js'
import { LogSettings } from './LogSettings.js'
import { Log, updateSettings, message, enableStackTraces, disableStackTraces } from './Log.js'

export class LogModule extends PDRModule<LogSettings> {
  #log
  constructor() {
    super(LogSettings.build)
    this.#log = this._configured.then((config) => {
      updateSettings({
        message: config.message,
        info: config.info,
        warning: config.warning,
        error: config.error,
        debug: config.debug,
        timer: config.timer,
        tracker: config.tracker
      })
      return new Log()
    })
  }

  async create(options: { prefix: string }) {
    await this._configured
    return new Log(options.prefix)
  }

  async message(message: string | message) {
    const log = await this.#log
    log.message(message)
  }

  async info(message: string | message) {
    const log = await this.#log
    log.info(message)
  }

  async warning(message: string | message) {
    const log = await this.#log
    log.warning(message)
  }

  async deprecation(message: string | message) {
    const log = await this.#log
    log.deprecation(message)
  }

  async error(message: string | message) {
    const log = await this.#log
    log.error(message)
  }

  async debug(message: string | message) {
    const log = await this.#log
    log.debug(message)
  }

  async timer(message?: string | message) {
    const log = await this.#log
    return log.timer(message)
  }

  async tracker(trackName?: string) {
    const log = await this.#log
    return log.tracker(trackName)
  }

  enableStackTraces() {
    enableStackTraces()
  }

  async disableStackTraces() {
    disableStackTraces()
  }
}
