import { OneUxPaletteToken } from '../../generated/design-tokens.js'

export const colorKeywords = ['transparent', 'automatic'] as const
export type colorKeyword = (typeof colorKeywords)[number]

export type hexColor = `#${string}`

export type color = hexColor | OneUxPaletteToken | colorKeyword

// user input types
export type colorInput = { color: color }
export type groupInput = { heading: string; colors: (colorInput | color)[] }
export type optionInput = colorInput | groupInput | color

// internal data types
export type optionData = {
  value: color
  hexColor: hexColor
  isKeyword: boolean
  isPale: boolean
  label?: string
}

export type groupData = {
  heading: string
  options: optionData[]
}

export type colorMatrix = optionData[][]
