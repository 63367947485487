export class BeforeOpenEvent extends Event {
  static get eventName() {
    return 'beforeopen'
  }

  constructor() {
    super(BeforeOpenEvent.eventName, {
      bubbles: false,
      cancelable: true,
      composed: false
    })
  }
}
