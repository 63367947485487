import { OneUxTabElement } from '../one-ux-tab/OneUxTabElement.js'

export type direction = 'left' | 'right'

export type internalTab = {
  index: number
  $el: OneUxTabElement
  name: string
  tabRef: string
  tabpanelRef: string
}

export const validEdges = ['left', 'right', 'bottom'] as const
export type validEdge = (typeof validEdges)[number]
