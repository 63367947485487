import { ChildRefs } from './OneUxScrollElement.js'

export class MouseDownHandler {
  public handleY
  public handleX

  constructor(private refs: ChildRefs) {
    this.handleY = (event: MouseEvent) => this.handle(event, 'y')
    this.handleX = (event: MouseEvent) => this.handle(event, 'x')
  }

  private handle(event: MouseEvent, type: string) {
    const isX = type === 'x'
    const $bar = (isX ? this.refs.$scrollbarX : this.refs.$scrollbarY).value!
    const $handle = (isX ? this.refs.$handleX : this.refs.$handleY).value!
    const scrollValue = isX ? 'scrollLeft' : 'scrollTop'
    const scrollSize = isX ? 'scrollWidth' : 'scrollHeight'
    const clientValue = isX ? 'clientX' : 'clientY'
    const clientSize = isX ? 'clientWidth' : 'clientHeight'
    const pageValue = isX ? 'pageX' : 'pageY'
    const rectValue = isX ? 'left' : 'top'

    event.preventDefault()

    // needs to be declared here due to retargeting
    const target = event.target

    requestAnimationFrame(() => {
      const $content = this.refs.$content.value!

      const factor = $content[scrollSize] / $bar[clientSize]
      const startMouseValue = event[clientValue]
      let startScrollValue = $content[scrollValue]

      if (target === $bar) {
        startScrollValue = $content[scrollValue] =
          (event[pageValue] -
            document.documentElement[scrollValue] -
            $bar.getBoundingClientRect()[rectValue] -
            $handle[clientSize] / 2) *
          factor
      }

      function pointerMove(e: MouseEvent) {
        requestAnimationFrame(() => {
          $content[scrollValue] = startScrollValue + (e[clientValue] - startMouseValue) * factor
        })
      }

      function pointerUp() {
        requestAnimationFrame(() => {
          document.removeEventListener('pointermove', pointerMove)
          document.removeEventListener('pointerup', pointerUp)
          $bar.removeAttribute('active')
        })
      }

      $bar.setAttribute('active', '')
      document.addEventListener('pointermove', pointerMove)
      document.addEventListener('pointerup', pointerUp)
    })
  }
}
