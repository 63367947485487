/* Auto-generated by script, do not modify */

import { css } from 'lit'

export const style = css`
  .one-ux-typography--body-100 {
    font: var(--one-ux-font--body-100);
    letter-spacing: var(--one-ux-letter-spacing--body-100);
    margin: var(--one-ux-font-margin--body-100);
  }
  .one-ux-typography--body-200 {
    font: var(--one-ux-font--body-200);
    letter-spacing: var(--one-ux-letter-spacing--body-200);
    margin: var(--one-ux-font-margin--body-200);
  }
  .one-ux-typography--body-300 {
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    margin: var(--one-ux-font-margin--body-300);
  }
  .one-ux-typography--body-400 {
    font: var(--one-ux-font--body-400);
    letter-spacing: var(--one-ux-letter-spacing--body-400);
    margin: var(--one-ux-font-margin--body-400);
  }
  .one-ux-typography--body-500 {
    font: var(--one-ux-font--body-500);
    letter-spacing: var(--one-ux-letter-spacing--body-500);
    margin: var(--one-ux-font-margin--body-500);
  }
  .one-ux-typography--body-600 {
    font: var(--one-ux-font--body-600);
    letter-spacing: var(--one-ux-letter-spacing--body-600);
    margin: var(--one-ux-font-margin--body-600);
  }
  .one-ux-typography--body-700 {
    font: var(--one-ux-font--body-700);
    letter-spacing: var(--one-ux-letter-spacing--body-700);
    margin: var(--one-ux-font-margin--body-700);
  }
  .one-ux-typography--body-variant-100 {
    font: var(--one-ux-font--body-variant-100);
    letter-spacing: var(--one-ux-letter-spacing--body-variant-100);
    margin: var(--one-ux-font-margin--body-variant-100);
  }
  .one-ux-typography--body-variant-200 {
    font: var(--one-ux-font--body-variant-200);
    letter-spacing: var(--one-ux-letter-spacing--body-variant-200);
    margin: var(--one-ux-font-margin--body-variant-200);
  }
  .one-ux-typography--body-variant-300 {
    font: var(--one-ux-font--body-variant-300);
    letter-spacing: var(--one-ux-letter-spacing--body-variant-300);
    margin: var(--one-ux-font-margin--body-variant-300);
  }
  .one-ux-typography--body-variant-400 {
    font: var(--one-ux-font--body-variant-400);
    letter-spacing: var(--one-ux-letter-spacing--body-variant-400);
    margin: var(--one-ux-font-margin--body-variant-400);
  }
  .one-ux-typography--body-variant-500 {
    font: var(--one-ux-font--body-variant-500);
    letter-spacing: var(--one-ux-letter-spacing--body-variant-500);
    margin: var(--one-ux-font-margin--body-variant-500);
  }
  .one-ux-typography--body-variant-600 {
    font: var(--one-ux-font--body-variant-600);
    letter-spacing: var(--one-ux-letter-spacing--body-variant-600);
    margin: var(--one-ux-font-margin--body-variant-600);
  }
  .one-ux-typography--body-variant-700 {
    font: var(--one-ux-font--body-variant-700);
    letter-spacing: var(--one-ux-letter-spacing--body-variant-700);
    margin: var(--one-ux-font-margin--body-variant-700);
  }
  .one-ux-typography--heading-100 {
    font: var(--one-ux-font--heading-100);
    letter-spacing: var(--one-ux-letter-spacing--heading-100);
    margin: var(--one-ux-font-margin--heading-100);
  }
  .one-ux-typography--heading-200 {
    font: var(--one-ux-font--heading-200);
    letter-spacing: var(--one-ux-letter-spacing--heading-200);
    margin: var(--one-ux-font-margin--heading-200);
  }
  .one-ux-typography--heading-300 {
    font: var(--one-ux-font--heading-300);
    letter-spacing: var(--one-ux-letter-spacing--heading-300);
    margin: var(--one-ux-font-margin--heading-300);
  }
  .one-ux-typography--heading-400 {
    font: var(--one-ux-font--heading-400);
    letter-spacing: var(--one-ux-letter-spacing--heading-400);
    margin: var(--one-ux-font-margin--heading-400);
  }
  .one-ux-typography--heading-500 {
    font: var(--one-ux-font--heading-500);
    letter-spacing: var(--one-ux-letter-spacing--heading-500);
    margin: var(--one-ux-font-margin--heading-500);
  }
  .one-ux-typography--heading-600 {
    font: var(--one-ux-font--heading-600);
    letter-spacing: var(--one-ux-letter-spacing--heading-600);
    margin: var(--one-ux-font-margin--heading-600);
  }
  .one-ux-typography--heading-700 {
    font: var(--one-ux-font--heading-700);
    letter-spacing: var(--one-ux-letter-spacing--heading-700);
    margin: var(--one-ux-font-margin--heading-700);
  }
  .one-ux-typography--heading-variant-100 {
    font: var(--one-ux-font--heading-variant-100);
    letter-spacing: var(--one-ux-letter-spacing--heading-variant-100);
    margin: var(--one-ux-font-margin--heading-variant-100);
  }
  .one-ux-typography--heading-variant-200 {
    font: var(--one-ux-font--heading-variant-200);
    letter-spacing: var(--one-ux-letter-spacing--heading-variant-200);
    margin: var(--one-ux-font-margin--heading-variant-200);
  }
  .one-ux-typography--heading-variant-300 {
    font: var(--one-ux-font--heading-variant-300);
    letter-spacing: var(--one-ux-letter-spacing--heading-variant-300);
    margin: var(--one-ux-font-margin--heading-variant-300);
  }
  .one-ux-typography--heading-variant-400 {
    font: var(--one-ux-font--heading-variant-400);
    letter-spacing: var(--one-ux-letter-spacing--heading-variant-400);
    margin: var(--one-ux-font-margin--heading-variant-400);
  }
  .one-ux-typography--heading-variant-500 {
    font: var(--one-ux-font--heading-variant-500);
    letter-spacing: var(--one-ux-letter-spacing--heading-variant-500);
    margin: var(--one-ux-font-margin--heading-variant-500);
  }
  .one-ux-typography--heading-variant-600 {
    font: var(--one-ux-font--heading-variant-600);
    letter-spacing: var(--one-ux-letter-spacing--heading-variant-600);
    margin: var(--one-ux-font-margin--heading-variant-600);
  }
  .one-ux-typography--heading-variant-700 {
    font: var(--one-ux-font--heading-variant-700);
    letter-spacing: var(--one-ux-letter-spacing--heading-variant-700);
    margin: var(--one-ux-font-margin--heading-variant-700);
  }
  .one-ux-typography--label-100 {
    font: var(--one-ux-font--label-100);
    letter-spacing: var(--one-ux-letter-spacing--label-100);
    margin: var(--one-ux-font-margin--label-100);
  }
  .one-ux-typography--label-200 {
    font: var(--one-ux-font--label-200);
    letter-spacing: var(--one-ux-letter-spacing--label-200);
    margin: var(--one-ux-font-margin--label-200);
  }
  .one-ux-typography--label-300 {
    font: var(--one-ux-font--label-300);
    letter-spacing: var(--one-ux-letter-spacing--label-300);
    margin: var(--one-ux-font-margin--label-300);
  }
  .one-ux-typography--label-400 {
    font: var(--one-ux-font--label-400);
    letter-spacing: var(--one-ux-letter-spacing--label-400);
    margin: var(--one-ux-font-margin--label-400);
  }
  .one-ux-typography--label-500 {
    font: var(--one-ux-font--label-500);
    letter-spacing: var(--one-ux-letter-spacing--label-500);
    margin: var(--one-ux-font-margin--label-500);
  }
  .one-ux-typography--label-600 {
    font: var(--one-ux-font--label-600);
    letter-spacing: var(--one-ux-letter-spacing--label-600);
    margin: var(--one-ux-font-margin--label-600);
  }
  .one-ux-typography--label-700 {
    font: var(--one-ux-font--label-700);
    letter-spacing: var(--one-ux-letter-spacing--label-700);
    margin: var(--one-ux-font-margin--label-700);
  }
  .one-ux-typography--label-variant-100 {
    font: var(--one-ux-font--label-variant-100);
    letter-spacing: var(--one-ux-letter-spacing--label-variant-100);
    margin: var(--one-ux-font-margin--label-variant-100);
  }
  .one-ux-typography--label-variant-200 {
    font: var(--one-ux-font--label-variant-200);
    letter-spacing: var(--one-ux-letter-spacing--label-variant-200);
    margin: var(--one-ux-font-margin--label-variant-200);
  }
  .one-ux-typography--label-variant-300 {
    font: var(--one-ux-font--label-variant-300);
    letter-spacing: var(--one-ux-letter-spacing--label-variant-300);
    margin: var(--one-ux-font-margin--label-variant-300);
  }
  .one-ux-typography--label-variant-400 {
    font: var(--one-ux-font--label-variant-400);
    letter-spacing: var(--one-ux-letter-spacing--label-variant-400);
    margin: var(--one-ux-font-margin--label-variant-400);
  }
  .one-ux-typography--label-variant-500 {
    font: var(--one-ux-font--label-variant-500);
    letter-spacing: var(--one-ux-letter-spacing--label-variant-500);
    margin: var(--one-ux-font-margin--label-variant-500);
  }
  .one-ux-typography--label-variant-600 {
    font: var(--one-ux-font--label-variant-600);
    letter-spacing: var(--one-ux-letter-spacing--label-variant-600);
    margin: var(--one-ux-font-margin--label-variant-600);
  }
  .one-ux-typography--label-variant-700 {
    font: var(--one-ux-font--label-variant-700);
    letter-spacing: var(--one-ux-letter-spacing--label-variant-700);
    margin: var(--one-ux-font-margin--label-variant-700);
  }
  .one-ux-typography--mono-100 {
    font: var(--one-ux-font--mono-100);
    letter-spacing: var(--one-ux-letter-spacing--mono-100);
    margin: var(--one-ux-font-margin--mono-100);
  }
  .one-ux-typography--mono-200 {
    font: var(--one-ux-font--mono-200);
    letter-spacing: var(--one-ux-letter-spacing--mono-200);
    margin: var(--one-ux-font-margin--mono-200);
  }
  .one-ux-typography--mono-300 {
    font: var(--one-ux-font--mono-300);
    letter-spacing: var(--one-ux-letter-spacing--mono-300);
    margin: var(--one-ux-font-margin--mono-300);
  }
  .one-ux-typography--mono-400 {
    font: var(--one-ux-font--mono-400);
    letter-spacing: var(--one-ux-letter-spacing--mono-400);
    margin: var(--one-ux-font-margin--mono-400);
  }
  .one-ux-typography--mono-500 {
    font: var(--one-ux-font--mono-500);
    letter-spacing: var(--one-ux-letter-spacing--mono-500);
    margin: var(--one-ux-font-margin--mono-500);
  }
  .one-ux-typography--mono-600 {
    font: var(--one-ux-font--mono-600);
    letter-spacing: var(--one-ux-letter-spacing--mono-600);
    margin: var(--one-ux-font-margin--mono-600);
  }
  .one-ux-typography--mono-700 {
    font: var(--one-ux-font--mono-700);
    letter-spacing: var(--one-ux-letter-spacing--mono-700);
    margin: var(--one-ux-font-margin--mono-700);
  }
  .one-ux-typography--mono-variant-100 {
    font: var(--one-ux-font--mono-variant-100);
    letter-spacing: var(--one-ux-letter-spacing--mono-variant-100);
    margin: var(--one-ux-font-margin--mono-variant-100);
  }
  .one-ux-typography--mono-variant-200 {
    font: var(--one-ux-font--mono-variant-200);
    letter-spacing: var(--one-ux-letter-spacing--mono-variant-200);
    margin: var(--one-ux-font-margin--mono-variant-200);
  }
  .one-ux-typography--mono-variant-300 {
    font: var(--one-ux-font--mono-variant-300);
    letter-spacing: var(--one-ux-letter-spacing--mono-variant-300);
    margin: var(--one-ux-font-margin--mono-variant-300);
  }
  .one-ux-typography--mono-variant-400 {
    font: var(--one-ux-font--mono-variant-400);
    letter-spacing: var(--one-ux-letter-spacing--mono-variant-400);
    margin: var(--one-ux-font-margin--mono-variant-400);
  }
  .one-ux-typography--mono-variant-500 {
    font: var(--one-ux-font--mono-variant-500);
    letter-spacing: var(--one-ux-letter-spacing--mono-variant-500);
    margin: var(--one-ux-font-margin--mono-variant-500);
  }
  .one-ux-typography--mono-variant-600 {
    font: var(--one-ux-font--mono-variant-600);
    letter-spacing: var(--one-ux-letter-spacing--mono-variant-600);
    margin: var(--one-ux-font-margin--mono-variant-600);
  }
  .one-ux-typography--mono-variant-700 {
    font: var(--one-ux-font--mono-variant-700);
    letter-spacing: var(--one-ux-letter-spacing--mono-variant-700);
    margin: var(--one-ux-font-margin--mono-variant-700);
  }
`
