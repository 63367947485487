import { css } from 'lit'

export const style = css`
  :host {
    max-width: var(--one-ux-element-internal--popout-constraint-max-width, 'none');
    max-height: var(--one-ux-element-internal--popout-constraint-max-height, 'none');
    width: var(--one-ux-element-internal--popout-constraint-width);
  }

  .menu,
  [role='menu'] {
    --one-ux-menu--menu-item-content-background: none;
    --one-ux-menu--menu-item-gap: 6px 8px;

    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    color: var(--one-ux-palette--brand-neutral-600);
    white-space: nowrap;
    padding: var(--one-ux-spacing--normal);
    display: flex;
    flex-direction: column;

    &:focus {
      outline: none;
    }
  }

  [role='separator'] {
    border: none;
    height: var(--one-ux-border-width--500);
    background: var(--one-ux-palette--brand-neutral-200);
    margin: 3.5px 0;
    height: 1px;
  }

  [role='menuitem']:not([aria-haspopup]),
  [role='menuitem'][aria-haspopup] .menu-item-content {
    --one-ux-menu--menu-item-cursor: default;

    all: unset;
    appearance: none;
    color: inherit;
    background: var(--one-ux-menu--menu-item-content-background);
    cursor: var(--one-ux-menu--menu-item-cursor);
    display: flex;
    align-items: center;
    gap: var(--one-ux-menu--menu-item-gap);
    padding: var(--one-ux-spacing--300);
    min-width: 0;

    border-radius: var(--one-ux-radius--300);
    outline: none;
    text-decoration: none;
    line-height: var(--one-ux-size--icon-300); // same size as icon in menu-item

    transition-property: background-color, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  /* Group */
  [role='menuitem'][aria-haspopup] {
    & > .menu-item-content {
      display: flex;
    }

    .group-content {
      display: flex;
      align-items: center;
      gap: var(--one-ux-menu--menu-item-gap);
      flex-grow: 1;
    }
  }

  /* Disabled state */
  :host([disabled]) [role='menuitem'][aria-haspopup],
  [aria-disabled='true'],
  [role='menuitem'][aria-disabled='true'] {
    color: var(--one-ux-palette--gray-400);
  }

  /* Option + Group: mouse interaction */
  :host(:not([disabled]):not([state-keyboard-focus])) [role='menuitem']:not([aria-haspopup]),
  :host(:not([disabled]):not([state-keyboard-focus])) [role='menuitem'][aria-haspopup] > .menu-item-content {
    &:hover:not([aria-disabled='true']) {
      --one-ux-menu--menu-item-content-background: var(--one-ux-palette--brand-neutral-150);
      --one-ux-menu--menu-item-cursor: pointer;
    }

    &:active:not([aria-disabled='true']) {
      --one-ux-menu--menu-item-content-background: var(--one-ux-palette--brand-neutral-300);
    }
  }

  /* Option: keyboard interaction */
  :host(:not([disabled])[state-keyboard-focus]) [role='menuitem']:not([aria-haspopup]) {
    &:focus {
      --one-ux-menu--menu-item-content-background: var(--one-ux-palette--keyboard-focus-200);
    }

    &:focus:active {
      --one-ux-menu--menu-item-content-background: var(--one-ux-palette--keyboard-focus-300);
    }
  }

  /* Group: keyboard interaction */
  :host(:not([disabled])) [role='menuitem'][aria-haspopup] {
    &:focus,
    &:focus:active {
      outline: none;
    }
  }

  :host(:not([disabled])[state-keyboard-focus]) [role='menuitem'][aria-haspopup] {
    &:focus > .menu-item-content {
      --one-ux-menu--menu-item-content-background: var(--one-ux-palette--keyboard-focus-200);
    }

    &:focus:active > .menu-item-content {
      --one-ux-menu--menu-item-content-background: var(--one-ux-palette--keyboard-focus-300);
    }
  }

  :host(:not([disabled])[state-keyboard-focus]) [role='menuitem'][aria-expanded='true'] > .menu-item-content {
    --one-ux-menu--menu-item-content-background: var(--one-ux-palette--keyboard-focus-200);
  }

  /* Group: mouse interaction */
  :host(:not([disabled])) [role='menuitem'][aria-expanded='true'] > .menu-item-content {
    --one-ux-menu--menu-item-content-background: var(--one-ux-palette--brand-neutral-150);
  }
`
