import { css } from 'lit'

export const style = css`
  :host {
    margin-bottom: var(--one-ux-spacing--300);
  }

  .one-ux-element--root {
    :host([implicit]) & {
      border-radius: var(--one-ux-radius--400);
      transition-property: background, outline-color;
      transition-duration: var(--one-ux-duration--100);
      transition-timing-function: var(--one-ux-easing--default);

      outline: var(--one-ux-spacing--100) solid rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
      outline-offset: calc(var(--one-ux-spacing--100) * -1);

      &:has([role='heading']:hover) {
        outline-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);
      }

      &:has([role='heading']:active) {
        outline-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.15);
      }

      :host([open]) & {
        outline-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);

        &:has([role='heading']:hover) {
          outline-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.18);
        }

        &:has([role='heading']:active) {
          outline-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);
        }
      }
    }

    :host([implicit][purpose='branded']) & {
      border-radius: var(--one-ux-radius--400);
      outline-color: var(--one-ux-palette--brand-core-400);

      &:has([role='heading']:hover) {
        outline-color: var(--one-ux-palette--brand-core-400);
        background: var(--one-ux-palette--brand-core-150);
      }

      &:has([role='heading']:active) {
        outline-color: var(--one-ux-palette--brand-core-400);
        background: var(--one-ux-palette--brand-core-150);
      }

      :host([open]) & {
        outline-color: var(--one-ux-palette--brand-core-400);
        background: var(--one-ux-palette--brand-core-150);

        &:has([role='heading']:hover) {
          outline-color: var(--one-ux-palette--brand-core-400);
          background: var(--one-ux-palette--brand-core-150);
        }

        &:has([role='heading']:active) {
          outline-color: var(--one-ux-palette--brand-core-400);
          background: var(--one-ux-palette--brand-core-150);
        }
      }
    }
  }

  [role='heading'] {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 40px;
    border-radius: var(--one-ux-radius--400);
    gap: var(--one-ux-spacing--300);
    padding: 0 var(--one-ux-spacing--400);
    user-select: none;

    font: var(--one-ux-font--heading-200);
    color: var(--one-ux-palette--brand-neutral-600);
    letter-spacing: var(--one-ux-letter-spacing--heading-200);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);

    :host([state-keyboard-focus]) & {
      &:has(button:focus) {
        background: var(--one-ux-palette--keyboard-focus-300) !important;
        color: var(--one-ux-palette--keyboard-focus-700) !important;
      }
      &:has(button:active) {
        background: var(--one-ux-palette--keyboard-focus-400) !important;
        color: var(--one-ux-palette--keyboard-focus-700) !important;
      }
    }

    :host(:not([implicit])) & {
      background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.06);

      &:hover {
        background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
      }

      &:active {
        background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.15);
      }

      :host([open]) & {
        background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);

        &:hover {
          background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.15);
        }

        &:active {
          background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.21);
        }
      }
    }

    :host(:not([implicit])[purpose='branded']) & {
      border-radius: var(--one-ux-radius--400);
      color: var(--one-ux-palette--gray-100);
      background: var(--one-ux-palette--brand-core-400);

      &:hover {
        background: var(--one-ux-palette--brand-core-450);
      }

      &:active {
        background: var(--one-ux-palette--brand-core-500);
      }

      :host([open]) & {
        background: var(--one-ux-palette--brand-core-450);

        &:hover {
          background: var(--one-ux-palette--brand-core-500);
        }

        &:active {
          background: var(--one-ux-palette--brand-core-550);
        }
      }
    }
  }

  button {
    all: unset;
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;
    outline: none;
  }

  .header-content {
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    gap: var(--one-ux-spacing--200);
    align-items: center;
  }

  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: stretch;
  }

  .indicator {
    transition-property: rotate;
    transition-duration: var(--one-ux-duration--400);
    transition-timing-function: var(--one-ux-easing--default);

    &.up {
      rotate: -180deg;
    }
  }

  slot {
    &::slotted(one-ux-icon) {
      font-size: var(--one-ux-size--icon-300);
    }

    &:is([name='indicator-start'], [name='indicator'], [name='indicator-end'])::slotted(*) {
      align-self: center;
    }
  }

  .content-wrapper {
    overflow: hidden;
    visibility: hidden;
    height: 0;

    &.expanded {
      height: auto;
      visibility: visible;
    }
  }

  .content {
    width: auto;

    :host(:not([disable-indent])) &.has-content {
      padding: var(--one-ux-spacing--300) var(--one-ux-spacing--400) var(--one-ux-spacing--500)
        var(--one-ux-spacing--400);
    }
  }
`
