import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Implicit } from '../../mixins/Implicit.js'
import { Focusable } from '../../mixins/Focusable.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { log } from '../../utils/log.js'
import { Label } from '../../mixins/Label.js'

const Styled = StyledFactory(style)

const BaseClass = Label(Focusable(Implicit(Styled(OneUxElement))))

let globallyAvailableTextDeprecationWarnings = 5

@customElement('one-ux-link')
export class OneUxLinkElement extends BaseClass {
  @property({ type: String })
  public accessor url!: string

  @property({ type: String })
  public set text(text: string) {
    this.#logTextDeprecation()
    this.label = text
  }
  public get text() {
    return this.label
  }

  @property({ type: String })
  public accessor open = 'current' as 'new' | 'current'

  @property({ type: String })
  public accessor download!: string

  render() {
    if (!this.url) {
      log.error({ title: 'No url provided, not rendering.', details: this })
      return
    }
    return html`<a
      class="one-ux-element--root link"
      download=${ifDefined(this.download)}
      target=${this.open === 'new' ? '_blank' : '_self'}
      href=${this.url}
    >
      <slot name="start"></slot>
      <span class="text">${this.label || this.url}</span>
      <slot name="end"></slot>
    </a>`
  }

  #logTextDeprecation() {
    if (globallyAvailableTextDeprecationWarnings) {
      globallyAvailableTextDeprecationWarnings--

      const reason = 'Usage of "text" is deprecated in favor of "label" property.'
      const message = globallyAvailableTextDeprecationWarnings ? reason : `${reason} Suppressing further warnings.`
      log.deprecation(message)
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-link': OneUxLinkElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-link': OneUxLinkElement
    }
  }
}
