import { css } from 'lit'

export const style = css`
  .toast {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    position: relative;

    box-shadow: var(--one-ux-shadow--drop-300);
    background: rgba(var(--one-ux-rgb--brand-neutral-100), 0.7);
    backdrop-filter: blur(12px);
    border-radius: var(--one-ux-radius--400);
    z-index: 2147483646;
  }

  .toast:not(.has-actions-slot-content) .toast--actions,
  .toast:not(.has-body-slot-content) .toast--body {
    display: none;
  }

  .toast:not(.has-body-slot-content) .toast--contents {
    gap: 0 var(--one-ux-spacing--200);
    padding-bottom: var(--one-ux-spacing--300);
  }

  .toast--contents {
    display: inline-grid;
    grid-template-columns: min-content minmax(0, 1fr) min-content;
    grid-template-rows: min-content minmax(0, 1fr) min-content;
    width: 100%;
    padding: var(--one-ux-spacing--300) var(--one-ux-spacing--400) var(--one-ux-spacing--400) var(--one-ux-spacing--400);
    gap: var(--one-ux-spacing--300) var(--one-ux-spacing--200);
  }

  .toast--icon {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    margin-top: 1px;
  }

  .toast--header {
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    color: var(--one-ux-palette--brand-neutral-600);
    padding-top: var(--one-ux-spacing--100);
    margin: 0;
    word-wrap: break-word;
  }

  .toast--body {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    color: var(--one-ux-palette--brand-neutral-500);
    margin: 0;
    word-wrap: break-word;
  }

  .toast--actions {
    grid-row: 3 / 4;
    grid-column: 1 / 4;
    text-align: right;
    gap: var(--one-ux-spacing--200) var(--one-ux-spacing--300);
    display: flex;
    justify-content: flex-end;
  }

  .toast--progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: var(--one-ux-palette--brand-core-400);
    transition: width linear;
  }

  .toast--close {
    grid-row: 1 / 3;
    grid-column: 3 / 4;
    margin-top: var(--one-ux-spacing--200);
  }
`
