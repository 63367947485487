import { css } from 'lit'

export const style = css`
  .message {
    --one-ux-message--icon-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-message--icon-size: var(--one-ux-size--icon-400);
    --one-ux-message--action-button-size: var(--one-ux-size--icon-300);
    --one-ux-message--background-color: transparent;
    --one-ux-message--font-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-message--border-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-message--column-gutter: var(--one-ux-spacing--large);
    --one-ux-message--row-gutter: var(--one-ux-spacing--normal);
    --one-ux-message--vertical-indent: var(--one-ux-spacing--400);
    --one-ux-message--horizontal-indent: var(--one-ux-spacing--large);

    --one-ux-message--font-heading: var(--one-ux-font--heading-200);
    --one-ux-message--letter-spacing-heading: var(--one-ux-letter-spacing--heading-200);
    --one-ux-message--line-height-heading: var(--one-ux-line-height--heading-200);
    --one-ux-message--header-column-gutter: var(--one-ux-spacing--normal);
    --one-ux-message--content-collapsed-height: 'auto';

    --one-ux-message--font-body: var(--one-ux-font--body-300);
    --one-ux-message--letter-spacing-body: var(--one-ux-letter-spacing--body-300);
    --one-ux-message--line-height-body: var(--one-ux-line-height--body-300);

    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: var(--one-ux-message--column-gutter);
    align-items: center;
    padding: var(--one-ux-message--vertical-indent) var(--one-ux-message--horizontal-indent);

    background: var(--one-ux-message--background-color);
    color: var(--one-ux-message--font-color);
    border-color: var(--one-ux-message--border-color);

    border-style: solid;
    border-radius: var(--one-ux-radius--300);
    border-width: var(--one-ux-border-width--100);

    font: var(--one-ux-message--font-body);
    letter-spacing: var(--one-ux-message--letter-spacing-body);

    :host(:not([animation-pending])) &.hidden {
      overflow: hidden;
      visibility: hidden;
      height: 0;
      border: none;
      padding: 0;
    }
  }

  :host([weight]) .message:has(.hide-heading),
  :host([weight]:not([animation-pending])) .message:has(.collapsed) {
    --one-ux-message--row-gutter: 0;
  }

  :host([weight='low']) .message {
    --one-ux-message--icon-size: var(--one-ux-size--icon-200);
    --one-ux-message--column-gutter: var(--one-ux-spacing--normal);
    --one-ux-message--row-gutter: var(--one-ux-spacing--small);
    --one-ux-message--vertical-indent: var(--one-ux-spacing--normal);
    --one-ux-message--horizontal-indent: var(--one-ux-spacing--normal);

    --one-ux-message--font-body: var(--one-ux-font--body-200);
    --one-ux-message--letter-spacing-body: var(--one-ux-letter-spacing--body-200);
    --one-ux-message--line-height-body: var(--one-ux-line-height--body-200);

    --one-ux-message--font-heading: var(--one-ux-font--heading-100);
    --one-ux-message--letter-spacing-heading: var(--one-ux-letter-spacing--heading-100);
    --one-ux-message--line-height-heading: var(--one-ux-line-height--heading-100);
  }

  :host([weight='high']) .message {
    --one-ux-message--icon-size: var(--one-ux-size--icon-700);
    --one-ux-message--column-gutter: var(--one-ux-spacing--larger);
    --one-ux-message--vertical-indent: var(--one-ux-spacing--large);
    --one-ux-message--horizontal-indent: var(--one-ux-spacing--larger);

    --one-ux-message--font-heading: var(--one-ux-font--heading-300);
    --one-ux-message--letter-spacing-heading: var(--one-ux-letter-spacing--heading-300);
    --one-ux-message--line-height-heading: var(--one-ux-line-height--heading-300);
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;

    &.collapsed {
      overflow: hidden;
      height: var(--one-ux-message--content-collapsed-height);
    }
  }

  .legacy-content {
    white-space: pre-line;
  }

  .inner-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: var(--one-ux-message--row-gutter);
  }

  .heading,
  .slotted-content {
    /* TODO: Define text max-width as variable in Figma */
    max-width: 807px;
  }

  :host(:not([animation-pending])) .content.collapsed:not(.hide-heading) .slotted-content {
    visibility: hidden;
  }

  .header {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--one-ux-message--header-column-gutter);

    font: var(--one-ux-message--font-heading);
    letter-spacing: var(--one-ux-message--letter-spacing-heading);
  }

  :host([weight]:not([animation-pending])) .collapsed .heading,
  :host([weight]:not([animation-pending])) .collapsed .slotted-content ::slotted(*) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  :host([mode='collapsible']) .header,
  :host([mode='dismissible']) .header,
  :host([mode='collapsible']) .content.hide-heading .slotted-content {
    margin-right: calc(var(--one-ux-message--action-button-size) + var(--one-ux-spacing--normal));
  }

  .header-end {
    display: flex;
    gap: var(--one-ux-message--header-column-gutter);
  }

  .header-end ::slotted(one-ux-icon) {
    font-size: var(--one-ux-size--icon-200);
  }

  :host([weight='low']) .header-end ::slotted(one-ux-icon) {
    font-size: var(--one-ux-size--icon-100);
  }

  .graphic {
    height: 1px; // to avoid jumping header when collapsing
  }

  :host(:is([weight='low'], [weight='normal'])) .graphic {
    align-self: flex-start;
  }

  :host([weight='high']) .graphic {
    margin-top: calc(-1 * var(--one-ux-message--icon-size));
  }

  .graphic one-ux-icon {
    color: var(--one-ux-message--icon-color);
    font-size: var(--one-ux-message--icon-size);
    line-height: var(--one-ux-message--icon-size);
    margin-top: calc(-1 * var(--one-ux-spacing--smaller));
  }

  :host([weight='low']) .graphic one-ux-icon {
    margin-top: calc(-1 * var(--one-ux-spacing--smallest));
  }

  :host([weight='high']) .graphic one-ux-icon {
    align-self: center;
    margin-top: 0;
  }

  :host([implicit]) .message {
    border-color: transparent;
  }

  one-ux-widget-button {
    position: absolute;
    top: var(--one-ux-message--vertical-indent);
    right: var(--one-ux-message--horizontal-indent);
  }
`
