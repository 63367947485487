import { timingFunction } from '../../utils/animation-utils.js'
import oneUxEasing from '../../generated/json/easing/easing.json'

export const showAnimation = () => [
  {
    transform: 'scale(0.85)',
    opacity: 0,
    easing: timingFunction(oneUxEasing.default)
  },
  {
    opacity: 1
  }
]

export const hideAnimation = () => [
  {
    display: 'flex',
    opacity: 1,
    easing: timingFunction(oneUxEasing.default)
  },
  {
    transform: 'scale(0.85)',
    opacity: 0
  }
]
