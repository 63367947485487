import { OneUxElementTheme } from '../../OneUxElement.js'

export const OneUxScrollElementTheme: OneUxElementTheme<{
  '--one-ux-scroll-theme--scrollbar-background-color': string
  '--one-ux-scroll-theme--scrollbar-border-color': string

  '--one-ux-scroll-theme--thumb-background': string
  '--one-ux-scroll-theme--thumb-background-hover': string
  '--one-ux-scroll-theme--thumb-background-pressed': string
}> = {
  light: {
    '--one-ux-scroll-theme--scrollbar-background-color': 'var(--one-ux-palette--brand-neutral-100)',
    '--one-ux-scroll-theme--scrollbar-border-color': 'var(--one-ux-palette--brand-neutral-200)',

    '--one-ux-scroll-theme--thumb-background': 'rgba(var(--one-ux-rgb--brand-neutral-250), 0.8)',
    '--one-ux-scroll-theme--thumb-background-hover': 'rgba(var(--one-ux-rgb--brand-neutral-450), 0.8)',
    '--one-ux-scroll-theme--thumb-background-pressed': 'var(--one-ux-palette--brand-neutral-450)'
  },
  dark: {
    '--one-ux-scroll-theme--scrollbar-background-color': 'var(--one-ux-palette--brand-neutral-600)',
    '--one-ux-scroll-theme--scrollbar-border-color': 'var(--one-ux-palette--brand-neutral-500)',

    '--one-ux-scroll-theme--thumb-background': 'rgba(var(--one-ux-rgb--brand-neutral-500), 0.8)',
    '--one-ux-scroll-theme--thumb-background-hover': 'rgba(var(--one-ux-rgb--brand-neutral-200), 0.9)',
    '--one-ux-scroll-theme--thumb-background-pressed': 'var(--one-ux-palette--brand-neutral-150)'
  }
}
