import { css } from 'lit'

export const style = css`
  .widget {
    --one-ux-widget-button-element--color: var(--one-ux-palette--brand-neutral-600);

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--one-ux-widget-button-element--color);
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: var(--one-ux-radius--200);
    cursor: pointer;

    font-size: var(--one-ux-size--icon-200);
    line-height: var(--one-ux-size--icon-200);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  button {
    all: unset;
  }

  :host([disabled]) {
    pointer-events: none;
  }

  :host([disabled]) .widget {
    --one-ux-widget-button-element--color: var(--one-ux-palette--gray-400);
    cursor: default;
  }

  :host(:not([implicit], [disabled])) .widget:hover {
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
  }

  :host(:not([implicit], [disabled])) .widget:active {
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.18);
  }

  :host(:not([disabled])[state-keyboard-focus]:focus) .widget {
    background-color: var(--one-ux-palette--keyboard-focus-200);
    --one-ux-widget-button-element--color: var(--one-ux-palette--keyboard-focus-700);
  }
`
