import { html, nothing } from 'lit'

import { repeat } from 'lit/directives/repeat.js'
import { styleMap } from 'lit/directives/style-map.js'
import { ref } from 'lit/directives/ref.js'

import { Toast } from '../Toast.js'
import { getLanguage } from '../lang.js'

export const ToastList = (toasts: Toast[]) => {
  return toasts.length
    ? html`<div
        style=${styleMap({
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '460px',
          gap: 'var(--one-ux-spacing--large)',
          position: 'fixed',
          top: 'var(--one-ux-spacing--500)',
          right: 'var(--one-ux-spacing--700)',
          zIndex: 2147483646
        })}
      >
        ${repeat(
          toasts,
          (toast) => toast.id,
          (toast) => {
            const { translations } = getLanguage(toast.$ref.value)

            return html`<one-ux-toast
              ${ref(toast.$ref)}
              type=${toast.type}
              @close=${() => toast.close()}
              label=${toast.title}
            >
              ${toast.description ? html`<one-ux-text>${toast.description}</one-ux-text>` : nothing}
              ${toast.hasCopyAction
                ? html`<one-ux-button
                    slot="action"
                    @click=${() => toast.copy()}
                    implicit
                    label=${translations.copy}
                    pdr-test-hook="copy-button"
                  >
                    <one-ux-icon icon="copy"></one-ux-icon>${!toast.isCopied ? translations.copy : translations.copied}
                  </one-ux-button> `
                : nothing}
            </one-ux-toast>`
          }
        )}
      </div>`
    : nothing
}
