import { OneUxElement } from '../../OneUxElement.js'
import { customElement, property } from 'lit/decorators.js'
import { until } from 'lit/directives/until.js'
import { style } from './style.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxIconToken } from '../../generated/design-tokens.js'
import { NotFoundIcon } from './fragments/NotFoundIcon.js'
import { html, nothing, PropertyValues } from 'lit'
import { iconManager } from './IconManager.js'
import { log } from '../../utils/log.js'
import { HidableTooltip } from '../../mixins/HidableTooltip.js'
import type { OneUxPaletteToken } from '../../generated/design-tokens.js'
import palette from '../../generated/json/palette/palette.json'
import iconSizes from '../../generated/json/size/size.json'
import { Optional } from '../../types.js'
import { PurposeFactory } from '../../mixins/Purpose.js'

const Styled = StyledFactory(style)

const Purpose = PurposeFactory({ purposes: ['default', 'ai'] })

const BaseClass = HidableTooltip(Purpose(Styled(OneUxElement)))

// TODO: Make type "dynamic" based on current set
type icons = OneUxIconToken[keyof OneUxIconToken]

/**
 * An icon component that allows selection from predefined sets of icons deemed compatible with OneUx.
 */
@customElement('one-ux-icon')
export class OneUxIconElement extends BaseClass {
  /**
   * The set that the icon provided by the `icon` property will be looked up in.
   */
  @property({ type: String })
  public accessor set = 'default' as keyof OneUxIconToken

  /**
   * The icon name as defined in whatever `set` you want to use.
   *
   * If no `set` is provided it will default to only show icons from set `default`.
   */
  @property({ type: String })
  public accessor icon!: icons

  /**
   * Fixed size for icon based on sizes 100-700.
   */
  @property({ type: String, reflect: true })
  public accessor size: Optional<'100' | '200' | '300' | '400' | '500' | '600' | '700'>

  /**
   * Makes the icon available for assistive technologies.
   * If no value is provided the icon will be hidden from assistive technologies.
   * When provided the icon will also have label as tooltip.
   */
  @property({ type: String })
  public accessor label!: string

  /**
   * Sets icon color
   */
  @property({ type: String, reflect: true })
  public accessor color: Optional<OneUxPaletteToken>

  protected render() {
    return [
      this.purpose === 'ai'
        ? html`<svg class="gradient">
            <linearGradient id="ai-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stop-color="var(--one-ux-palette--ai-core-400)"></stop>
              <stop offset="100%" stop-color="var(--one-ux-palette--ai-accent-400)"></stop>
            </linearGradient>
          </svg>`
        : nothing,
      until(this.#fetchIcon(), iconManager.get(this.icon, this.set))
    ]
  }

  protected firstUpdated(): void {
    this.#updateAccessibility()

    if (this.title) {
      log.warning('<one-ux-icon> should not use "title". Use "label" instead.')
    }
  }

  protected updated(changed: PropertyValues<this>): void {
    if (changed.has('label') || changed.has('hideTooltip')) {
      this.#updateAccessibility()
    }

    if (changed.has('color')) {
      this.#updateColor()
    }

    if (changed.has('size')) {
      this.#updateSize()
    }
  }

  async #fetchIcon() {
    try {
      await iconManager.update(this.icon, this.set)
    } catch (err: any) {
      log.warning({
        title: `Icon "${this.icon}" from source "${this.set}" not available. ${err.message}`,
        details: this
      })
    }
    const $icon = iconManager.get(this.icon, this.set)
    if (!$icon) {
      return NotFoundIcon()
    }
    return $icon
  }

  #updateAccessibility() {
    if (this.label) {
      this.setAttribute('role', 'img')
      if (!this.hideTooltip) {
        this.setAttribute('one-ux-tooltip', this.label)
        this.toggleAttribute('one-ux-tooltip-custom-aria', true)
      } else {
        this.removeAttribute('one-ux-tooltip')
        this.removeAttribute('one-ux-tooltip-custom-aria')
      }
      this.setAttribute('aria-label', this.label)
      this.removeAttribute('aria-hidden')
    } else {
      this.removeAttribute('role')
      this.removeAttribute('one-ux-tooltip')
      this.removeAttribute('one-ux-tooltip-custom-aria')
      this.removeAttribute('aria-label')
      this.setAttribute('aria-hidden', 'true')
    }
  }

  #updateColor() {
    if (!this.color) {
      this.style.removeProperty('--one-ux-icon-element--color')
    } else if (this.color in palette) {
      const color = `var(--one-ux-palette--${this.color})`
      this.style.setProperty('--one-ux-icon-element--color', color)
    }
  }

  #updateSize() {
    const iconSize = `icon-${this.size}`
    if (!this.size) {
      this.style.removeProperty('--one-ux-icon-element--size')
    } else if (iconSize in iconSizes) {
      const size = `var(--one-ux-size--${iconSize})`
      this.style.setProperty('--one-ux-icon-element--size', size)
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-icon': OneUxIconElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-icon': OneUxIconElement
    }
  }
}
