import { css, unsafeCSS } from 'lit'
import { ID_ACTIVE_OPTION, NUMBER_OF_COLUMNS_IN_SWATCH } from './constants.js'

export const style = css`
  :host {
    max-height: max(35vh, 250px);
    justify-content: center !important;
  }

  .one-ux-element--root {
    --one-ux-color-picker--width: 208px;
    --one-ux-color-picker--columns: ${unsafeCSS(NUMBER_OF_COLUMNS_IN_SWATCH)};

    --one-ux-color-picker--color-chip-size: 16px;
    --one-ux-color-picker--color-option-padding: var(--one-ux-spacing--200);
    --one-ux-color-picker--color-option-border-width: var(--one-ux-border-width--100);

    --one-ux-color-picker--vertical-spacing: 2px;
    --one-ux-color-picker--horizontal-spacing: 6px;

    --one-ux-color-picker--column-width: calc(
      var(--one-ux-color-picker--color-chip-size) +
        (var(--one-ux-color-picker--color-option-border-width) + var(--one-ux-color-picker--color-option-padding)) * 2
    );

    --one-ux-color-picker--focus-border: var(--one-ux-border-width--500) solid var(--one-ux-palette--focus-400);
    --one-ux-color-picker--focus-background: var(--one-ux-border-width--500) solid var(--one-ux-palette--focus-400);

    flex-grow: 0;
  }

  [role='grid'] {
    padding: 5px;
    table-layout: fixed;
    max-width: var(--one-ux-color-picker--width);
    outline: none;
    border-spacing: var(--one-ux-color-picker--horizontal-spacing) var(--one-ux-color-picker--vertical-spacing);

    :host([disabled]) & {
      background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
    }

    col {
      width: var(--one-ux-color-picker--column-width);
    }
  }

  tbody {
    th {
      padding: 22px 5px 10px;
      color: var(--one-ux-palette--brand-neutral-600);
      font: var(--one-ux-font--heading-100);
      line-height: var(--one-ux-line-height--heading-100);
      text-align: left;

      div {
        max-width: calc(
          var(--one-ux-color-picker--column-width) * var(--one-ux-color-picker--columns) +
            var(--one-ux-color-picker--horizontal-spacing) * (var(--one-ux-color-picker--columns) - 1)
        );
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:first-of-type th {
      padding-top: 0;
    }
  }

  td {
    padding: 0;
  }

  .color-option {
    --hover-background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
    --active-background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);

    padding: var(--one-ux-color-picker--color-option-padding);
    width: var(--one-ux-color-picker--color-chip-size);
    height: var(--one-ux-color-picker--color-chip-size);
    border: var(--one-ux-color-picker--color-option-border-width) solid transparent;
    border-radius: var(--one-ux-radius--300);
    background-clip: padding-box !important;

    :host(:not([disabled])[state-keyboard-focus]) [role='grid']:focus #${unsafeCSS(ID_ACTIVE_OPTION)} & {
      background: var(--one-ux-palette--keyboard-focus-300);

      .color-chip {
        color: var(--one-ux-palette--keyboard-focus-600) !important;
        border-color: var(--one-ux-palette--keyboard-focus-600) !important;
      }
    }

    :host(:not([disabled])[state-keyboard-focus]) [role='grid']:focus [aria-selected='true'] & {
      border: var(--one-ux-color-picker--focus-border);
    }

    :host(:not([disabled])) &:hover {
      background: var(--hover-background);
      cursor: pointer;
    }

    :host(:not([disabled]):not([state-keyboard-focus])) &:active {
      background: var(--active-background);
    }

    :host(:not([disabled])) [aria-selected='true'] & {
      padding: calc(var(--one-ux-spacing--200) - var(--one-ux-border-width--100));
      border: var(--one-ux-color-picker--focus-border);
      background: var(--one-ux-palette--gray-100);

      &:hover {
        background: var(--hover-background);
      }

      &:active {
        background: var(--active-background);
      }
    }
  }
`
