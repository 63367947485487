import { Version } from '../../Version.js'
import { NumberedVersion } from '../NumberedVersion.js'
import { WildcardVersion } from './WildcardVersion.js'

const wildcard = '*'
export class MajorVersion extends WildcardVersion {
  fulfills(version: Version): boolean {
    if (version instanceof MajorVersion) {
      if (this.major === version.major) {
        if (this.minor === version.minor) {
          return this.patch >= version.patch
        }
        return this.minor > version.minor
      }
      return this.major > version.major
    }
    return false
  }

  includes(version: Version): boolean {
    if (version.type !== 'numbered') {
      return false
    }

    const numberedVersion = version as NumberedVersion
    if (this.major === numberedVersion.major) {
      if (this.minor === numberedVersion.minor) {
        return this.patch <= numberedVersion.patch
      }
      return this.minor < numberedVersion.minor
    }
    return this.major < numberedVersion.major
  }

  static matches(versionString: string) {
    return versionString[0] === wildcard
  }
}
