import { PDRModule } from '../PDRModule.js'
import { CallbackQueue } from './CallbackQueue.js'
import { FunctionUtils } from './FunctionUtils.js'

const functionUtils = new FunctionUtils()

export class UtilsModule extends PDRModule {
  lang() {
    return document.documentElement.getAttribute('lang') || 'en'
  }

  defer(callback: (...args: unknown[]) => void, delay?: number) {
    functionUtils.defer(callback, delay)
  }

  debounce<TArgs extends Array<unknown>>(callback: (...args: TArgs) => void, delay: number) {
    return functionUtils.debounce(callback, delay)
  }

  createQueue(): CallbackQueue {
    return new CallbackQueue()
  }

  download(target: Blob | URL | string, fileName = 'download') {
    const url = getUrl(target)
    const $anchor = document.createElement('a')
    $anchor.setAttribute('href', url)
    $anchor.setAttribute('download', fileName)
    $anchor.setAttribute('aria-hidden', 'true')
    $anchor.style.display = 'none'
    document.body.appendChild($anchor)
    $anchor.click()
    if (target instanceof Blob) {
      window.URL.revokeObjectURL(url)
    }

    document.body.removeChild($anchor)
  }
}

function getUrl(target: Blob | URL | string) {
  let url = ''
  if (target instanceof Blob) {
    url = window.URL.createObjectURL(target)
  } else if (target instanceof URL) {
    url = target.href
  } else if (typeof target === 'string') {
    url = target
  }

  if (!url.length) {
    throw new Error("Parameter 'target' couldn't be parsed to a valid url.")
  }

  return url
}
