export class ShowTooltipEvent extends Event {
  static get eventName() {
    return 'internal-one-ux-tooltip-show'
  }

  constructor(public triggerType: 'keyboard' | 'cursor') {
    super(ShowTooltipEvent.eventName, {
      bubbles: true,
      cancelable: false,
      composed: true
    })
  }
}
