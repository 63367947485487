export async function animationFrameTimer(callback: (elapsed: number) => boolean, duration: number) {
  return new Promise<void>((resolve) => {
    const startTime = Date.now()

    const request = () => {
      const elapsed = Math.min(Date.now() - startTime, duration)
      requestAnimationFrame(() => {
        const isActive = callback(elapsed)
        if (isActive && elapsed < duration) {
          request()
        } else {
          resolve()
        }
      })
    }
    request()
  })
}
