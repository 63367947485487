import { requestedImageSize } from '../types.js'
import { AvatarContextCacheRepository } from './AvatarContextCacheRepository.js'

type avatarUrlCacheEntry = {
  urls: Record<number, { url: string; createdAt: string }>
}

const EXPIRED_AFTER_MS = 1000 * 60 * 60 * 24 * 7 // one week

export class AvatarUrlContextCacheRepository extends AvatarContextCacheRepository<avatarUrlCacheEntry> {
  constructor() {
    super('avatar-url', EXPIRED_AFTER_MS)
  }

  public get(tenantId: string, userId: string, size: requestedImageSize): { url: string; expired: boolean } {
    const entry = this.getEntry(tenantId, userId)
    if (!entry) {
      return { url: '', expired: true }
    }

    const profileImage = entry.urls[size]
    if (!profileImage) {
      return { url: '', expired: true }
    }

    const expired = this.hasExpired(profileImage)
    return { url: profileImage.url, expired }
  }

  public update(tenantId: string, userId: string, size: requestedImageSize, url: string) {
    const entry = this.getEntry(tenantId, userId) ?? {
      urls: {}
    }
    entry.urls[size] = {
      url,
      createdAt: new Date().toISOString()
    }
    this.setEntry(tenantId, userId, entry)
  }
}
