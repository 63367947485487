import { OneUxIconToken } from '../../generated/design-tokens.js'
import type { sets } from '../one-ux-badge/badge-sets.js'

export type messageType = 'info' | 'error' | 'warning' | 'success' | 'news' | 'ai'

export type icon = {
  set: keyof OneUxIconToken
  icon: OneUxIconToken[keyof OneUxIconToken]
}

export type badge = {
  set: keyof typeof sets
  badge: string
}

export type message = {
  textColor: string
  backgroundColor: string
  borderColor: string
  iconColor?: string
  badge?: badge
  icon?: icon
}

type set = Record<messageType, message>

export const messageSets: set = {
  info: {
    textColor: 'var(--one-ux-palette--brand-neutral-600)',
    backgroundColor: 'var(--one-ux-palette--brand-neutral-100)',
    borderColor: '',
    iconColor: 'var(--one-ux-palette--brand-neutral-600)',
    icon: { set: 'default', icon: 'info' }
  },
  error: {
    textColor: 'var(--one-ux-palette--red-700)',
    backgroundColor: 'var(--one-ux-palette--red-100)',
    borderColor: 'var(--one-ux-palette--red-700)',
    iconColor: 'var(--one-ux-palette--red-700)',
    icon: { set: 'default', icon: 'warning' }
  },
  warning: {
    textColor: 'var(--one-ux-palette--orange-700)',
    backgroundColor: 'var(--one-ux-palette--yellow-100)',
    borderColor: 'var(--one-ux-palette--orange-700)',
    iconColor: 'var(--one-ux-palette--orange-700)',
    icon: { set: 'default', icon: 'warning' }
  },
  success: {
    textColor: 'var(--one-ux-palette--mint-700)',
    backgroundColor: 'var(--one-ux-palette--mint-100)',
    borderColor: 'var(--one-ux-palette--mint-700)',
    iconColor: 'var(--one-ux-palette--mint-700)',
    icon: { set: 'status', icon: 'done' }
  },
  news: {
    textColor: 'var(--one-ux-palette--gray-700)',
    backgroundColor: 'var(--one-ux-palette--rose-100)',
    borderColor: 'var(--one-ux-palette--rose-500)',
    badge: { set: 'default', badge: 'new' }
  },
  ai: {
    textColor: 'var(--one-ux-palette--ai-core-700)',
    backgroundColor: 'var(--one-ux-palette--ai-core-100)',
    borderColor: 'var(--one-ux-palette--ai-accent-600)',
    iconColor: 'var(--one-ux-palette--ai-core-400)',
    icon: { set: 'ai', icon: 'ai-powered' }
  }
}
