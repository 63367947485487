export class AfterCloseEvent extends Event {
  static get eventName() {
    return 'afterclose'
  }

  constructor() {
    super(AfterCloseEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    })
  }
}
