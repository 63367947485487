import { ReactiveController } from 'lit'
import { OneUxElement } from '../OneUxElement.js'

export class UpdateOnMutationController implements ReactiveController {
  #observer!: MutationObserver

  constructor(private host: OneUxElement) {
    this.host.addController(this)
  }

  hostConnected(): void {
    this.#observer = new MutationObserver(() => this.host.requestUpdate())
  }

  hostDisconnected(): void {
    this.#observer.disconnect()
  }

  public observe($el: Element) {
    this.#observer.observe($el, {
      subtree: true,
      childList: true
    })
  }
}
