import { Version } from '../Version.js'
import { WildcardVersion } from './wildcard/WildcardVersion.js'

const numberedVersionRegex = /([0-9]+)\.([0-9]+)\.([0-9]+)/
export class NumberedVersion extends Version {
  major: number
  minor: number
  patch: number
  constructor(version: string, type = '') {
    super(version, type || 'numbered')
    const matches = (numberedVersionRegex.exec(version) || []).slice(1)
    if (matches.length !== 3) {
      throw new Error(`Unknown version format "${version}"`)
    }
    this.major = +matches[0]
    this.minor = +matches[1]
    this.patch = +matches[2]
  }

  fulfills(version: Version): boolean {
    if (version.type === 'special' || version.type === 'prerelease') {
      return false
    }

    if (version.type === 'wildcard') {
      return (version as WildcardVersion).includes(this)
    }

    return this.equal(version as NumberedVersion)
  }

  equal(version: NumberedVersion) {
    return this.major === version.major && this.minor === version.minor && this.patch === version.patch
  }
}
