import { PDRModule } from '../PDRModule.js'
import { awaitTestHook, findAllTestHooks, findTestHook } from './test-hooks.js'

export class TestModule extends PDRModule {
  /**
   * Searches for all available PDR test hooks.
   * Will search starting at the provided Element and search it's DOM tree, and all children, inluding shadowDom.
   * @param $element The element to start searching from.
   */
  findAllTestHooks($element: Element): Element[] {
    return findAllTestHooks($element)
  }

  /**
   * Finds the first test hook that matches a particular identifier.
   * Searches in a similar way to `findAllTestHooks`.
   * @param $element The element to start searching from.
   * @param hookId The hook identifier to attempt finding.
   */
  findTestHook($element: Element, hookId: string): Element | undefined {
    return findTestHook($element, hookId)
  }

  /**
   * Will return a promise that will resolve when a test hook is found that matches the identifier.
   * If a test hook is not found immediately, an asyncronous loop will start that runs until either a timeout occurs,
   * or a test hook is found. The found test hook will always be resolved after a delay.
   * @param $element The element to start searching from.
   * @param hookId The hook identifier to attempt finding.
   * @param config Allows you to configure the timeout until the promise will be rejected and the delay before the promise is resolved.
   */
  awaitTestHook($element: Element, hookId: string, config?: { timeout: number; delay: number }): Promise<Element> {
    return awaitTestHook($element, hookId, config)
  }
}
