import { lang } from './language.js'
import type { OneUxIconToken } from '../../generated/design-tokens.js'
export type badge = {
  textColor: string
  iconColor: string
  backgroundColor: string
  implicitTextColor: string
  implicitIconColor: string
  implicitBackgroundColor: string
  label?: (locale: keyof typeof lang) => string
  lockLabel?: boolean
  iconOnly?: boolean
  lockIcon?: boolean
  icon?: {
    set: keyof OneUxIconToken
    icon: OneUxIconToken[keyof OneUxIconToken]
  }
}
export type set = Record<string, badge>

const defaultSet: set = {
  default: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--brand-core-400)',
    implicitTextColor: 'var(--one-ux-palette--brand-core-500)',
    implicitIconColor: 'var(--one-ux-palette--brand-core-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--brand-core-400), 0.12)'
  },
  keyword: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--violet-400)',
    implicitTextColor: 'var(--one-ux-palette--violet-500)',
    implicitIconColor: 'var(--one-ux-palette--violet-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--violet-400), 0.12)'
  },
  new: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--rose-400)',
    implicitTextColor: 'var(--one-ux-palette--rose-400)',
    implicitIconColor: 'var(--one-ux-palette--rose-400)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--rose-400), 0.12)',
    label: (locale) => lang[locale].default.new,
    lockIcon: true,
    lockLabel: true
  },
  beta: {
    textColor: 'var(--one-ux-palette--brand-neutral-600)',
    iconColor: 'var(--one-ux-palette--brand-neutral-600)',
    backgroundColor: 'var(--one-ux-palette--yellow-400)',
    implicitTextColor: 'var(--one-ux-palette--yellow-600)',
    implicitIconColor: 'var(--one-ux-palette--yellow-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--yellow-400), 0.12)',
    label: (locale) => lang[locale].default.beta,
    lockIcon: true,
    lockLabel: true
  },
  preview: {
    textColor: 'var(--one-ux-palette--brand-neutral-600)',
    iconColor: 'var(--one-ux-palette--brand-neutral-600)',
    backgroundColor: 'var(--one-ux-palette--yellow-400)',
    implicitTextColor: 'var(--one-ux-palette--yellow-600)',
    implicitIconColor: 'var(--one-ux-palette--yellow-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--yellow-400), 0.12)',
    label: (locale) => lang[locale].default.preview,
    lockIcon: true,
    lockLabel: true
  },
  ai: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'linear-gradient(90deg, var(--one-ux-palette--ai-core-400), var(--one-ux-palette--ai-accent-400))',
    implicitTextColor:
      'linear-gradient(90deg, var(--one-ux-palette--ai-core-500), var(--one-ux-palette--ai-accent-500))',
    implicitIconColor: 'var(--one-ux-palette--ai-core-500)',
    implicitBackgroundColor:
      'linear-gradient(90deg, rgba(var(--one-ux-rgb--ai-core-400), 0.12), rgba(var(--one-ux-rgb--ai-accent-400), 0.12))',
    label: (locale) => lang[locale].default.ai,
    lockLabel: true,
    icon: {
      set: 'ai',
      icon: 'ai-powered'
    },
    lockIcon: true
  }
}

const colorSet: set = {
  gray: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--brand-neutral-400)',
    implicitTextColor: 'var(--one-ux-palette--brand-neutral-500)',
    implicitIconColor: 'var(--one-ux-palette--brand-neutral-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--brand-neutral-400), 0.12)'
  },
  rose: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--rose-400)',
    implicitTextColor: 'var(--one-ux-palette--rose-400)',
    implicitIconColor: 'var(--one-ux-palette--rose-400)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--rose-400), 0.12)'
  },
  magenta: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--magenta-400)',
    implicitTextColor: 'var(--one-ux-palette--magenta-500)',
    implicitIconColor: 'var(--one-ux-palette--magenta-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--magenta-400), 0.12)'
  },
  violet: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--violet-400)',
    implicitTextColor: 'var(--one-ux-palette--violet-500)',
    implicitIconColor: 'var(--one-ux-palette--violet-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--violet-400), 0.12)'
  },
  blue: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--blue-400)',
    implicitTextColor: 'var(--one-ux-palette--blue-500)',
    implicitIconColor: 'var(--one-ux-palette--blue-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--blue-400), 0.12)'
  },
  azure: {
    textColor: 'var(--one-ux-palette--azure-600)',
    iconColor: 'var(--one-ux-palette--azure-600)',
    backgroundColor: 'var(--one-ux-palette--azure-400)',
    implicitTextColor: 'var(--one-ux-palette--azure-500)',
    implicitIconColor: 'var(--one-ux-palette--azure-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--azure-400), 0.12)'
  },
  cyan: {
    textColor: 'var(--one-ux-rgb--cyan-700)',
    iconColor: 'var(--one-ux-rgb--cyan-700)',
    backgroundColor: 'var(--one-ux-palette--cyan-400)',
    implicitTextColor: 'var(--one-ux-palette--cyan-600)',
    implicitIconColor: 'var(--one-ux-palette--cyan-600)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--cyan-400), 0.12)'
  },
  mint: {
    textColor: 'var(--one-ux-palette--mint-700)',
    iconColor: 'var(--one-ux-palette--mint-700)',
    backgroundColor: 'var(--one-ux-palette--mint-400)',
    implicitTextColor: 'var(--one-ux-palette--mint-500)',
    implicitIconColor: 'var(--one-ux-palette--mint-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--mint-400), 0.12)'
  },
  green: {
    textColor: 'var(--one-ux-palette--green-700)',
    iconColor: 'var(--one-ux-palette--green-700)',
    backgroundColor: 'var(--one-ux-palette--green-400)',
    implicitTextColor: 'var(--one-ux-palette--green-600)',
    implicitIconColor: 'var(--one-ux-palette--green-600)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--green-400), 0.12)'
  },
  chartreuse: {
    textColor: 'var(--one-ux-palette--chartreuse-700)',
    iconColor: 'var(--one-ux-palette--chartreuse-700)',
    backgroundColor: 'var(--one-ux-palette--chartreuse-500)',
    implicitTextColor: 'var(--one-ux-palette--chartreuse-600)',
    implicitIconColor: 'var(--one-ux-palette--chartreuse-600)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--chartreuse-500), 0.12)'
  },
  yellow: {
    textColor: 'var(--one-ux-palette--brand-neutral-600)',
    iconColor: 'var(--one-ux-palette--brand-neutral-600)',
    backgroundColor: 'var(--one-ux-palette--yellow-400)',
    implicitTextColor: 'var(--one-ux-palette--yellow-600)',
    implicitIconColor: 'var(--one-ux-palette--yellow-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--yellow-400), 0.12)'
  },
  orange: {
    textColor: 'var(--one-ux-palette--orange-700)',
    iconColor: 'var(--one-ux-palette--orange-700)',
    backgroundColor: 'var(--one-ux-palette--orange-400)',
    implicitTextColor: 'var(--one-ux-palette--orange-500)',
    implicitIconColor: 'var(--one-ux-palette--orange-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--orange-400), 0.12)'
  },
  red: {
    textColor: 'var(--one-ux-palette--red-600)',
    iconColor: 'var(--one-ux-palette--red-600)',
    backgroundColor: 'var(--one-ux-palette--red-300)',
    implicitTextColor: 'var(--one-ux-palette--red-500)',
    implicitIconColor: 'var(--one-ux-palette--red-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--red-300), 0.12)'
  }
}

const activitiesSet: set = {
  'not-started': {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--brand-neutral-400)',
    implicitTextColor: 'var(--one-ux-palette--brand-neutral-500)',
    implicitIconColor: 'var(--one-ux-palette--brand-neutral-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--brand-neutral-400), 0.12)',
    label: (locale) => lang[locale].activities.notStarted
  },
  ongoing: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--blue-400)',
    implicitTextColor: 'var(--one-ux-palette--blue-500)',
    implicitIconColor: 'var(--one-ux-palette--blue-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--blue-400), 0.12)',
    label: (locale) => lang[locale].activities.ongoing
  },
  done: {
    textColor: 'var(--one-ux-palette--mint-700)',
    iconColor: 'var(--one-ux-palette--mint-700)',
    backgroundColor: 'var(--one-ux-palette--mint-400)',
    implicitTextColor: 'var(--one-ux-palette--mint-500)',
    implicitIconColor: 'var(--one-ux-palette--mint-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--mint-400), 0.12)',
    label: (locale) => lang[locale].activities.done
  },
  'done-with-deviation': {
    textColor: 'var(--one-ux-palette--mint-700)',
    iconColor: 'var(--one-ux-palette--mint-700)',
    backgroundColor: 'var(--one-ux-palette--mint-400)',
    implicitTextColor: 'var(--one-ux-palette--mint-500)',
    implicitIconColor: 'var(--one-ux-palette--mint-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--mint-400), 0.12)',
    label: (locale) => lang[locale].activities.doneWithDeviation
  },
  'soon-delayed': {
    textColor: 'var(--one-ux-palette--brand-neutral-600)',
    iconColor: 'var(--one-ux-palette--brand-neutral-600)',
    backgroundColor: 'var(--one-ux-palette--yellow-400)',
    implicitTextColor: 'var(--one-ux-palette--yellow-600)',
    implicitIconColor: 'var(--one-ux-palette--yellow-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--yellow-400), 0.12)',
    label: (locale) => lang[locale].activities.soonDelayed
  },
  'not-according-to-plan': {
    textColor: 'var(--one-ux-palette--brand-neutral-600)',
    iconColor: 'var(--one-ux-palette--brand-neutral-600)',
    backgroundColor: 'var(--one-ux-palette--yellow-400)',
    implicitTextColor: 'var(--one-ux-palette--yellow-600)',
    implicitIconColor: 'var(--one-ux-palette--yellow-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--yellow-400), 0.12)',
    label: (locale) => lang[locale].activities.notAccordingToPlan
  },
  paused: {
    textColor: 'var(--one-ux-palette--orange-700)',
    iconColor: 'var(--one-ux-palette--orange-700)',
    backgroundColor: 'var(--one-ux-palette--orange-400)',
    implicitTextColor: 'var(--one-ux-palette--orange-500)',
    implicitIconColor: 'var(--one-ux-palette--orange-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--orange-400), 0.12)',
    label: (locale) => lang[locale].activities.paused
  },
  delayed: {
    textColor: 'var(--one-ux-palette--red-600)',
    iconColor: 'var(--one-ux-palette--red-600)',
    backgroundColor: 'var(--one-ux-palette--red-300)',
    implicitTextColor: 'var(--one-ux-palette--red-500)',
    implicitIconColor: 'var(--one-ux-palette--red-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--red-300), 0.12)',
    label: (locale) => lang[locale].activities.delayed
  }
}

const measurementsBase: set = {
  empty: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--brand-neutral-400)',
    implicitTextColor: 'var(--one-ux-palette--brand-neutral-500)',
    implicitIconColor: 'var(--one-ux-palette--brand-neutral-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--brand-neutral-400), 0.12)',
    label: (locale) => lang[locale].measurements.empty
  },
  fulfilled: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--mint-400)',
    implicitTextColor: 'var(--one-ux-palette--mint-500)',
    implicitIconColor: 'var(--one-ux-palette--mint-400)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--mint-400), 0.12)',
    label: (locale) => lang[locale].measurements.fulfilled
  },
  'mostly-fulfilled': {
    textColor: 'var(--one-ux-palette--mint-600)',
    iconColor: 'var(--one-ux-palette--mint-500)',
    backgroundColor: 'var(--one-ux-palette--mint-300)',
    implicitTextColor: 'var(--one-ux-palette--mint-500)',
    implicitIconColor: 'var(--one-ux-palette--mint-300)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--mint-300), 0.12)',
    label: (locale) => lang[locale].measurements.mostlyFulfilled
  },
  'partially-fulfilled': {
    textColor: 'var(--one-ux-palette--brand-neutral-600)',
    iconColor: 'var(--one-ux-palette--brand-neutral-600)',
    backgroundColor: 'var(--one-ux-palette--yellow-400)',
    implicitTextColor: 'var(--one-ux-palette--yellow-600)',
    implicitIconColor: 'var(--one-ux-palette--yellow-400)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--yellow-400), 0.12)',
    label: (locale) => lang[locale].measurements.partiallyFulfilled
  },
  'marginally-fulfilled': {
    textColor: 'var(--one-ux-palette--orange-700)',
    iconColor: 'var(--one-ux-palette--orange-600)',
    backgroundColor: 'var(--one-ux-palette--orange-400)',
    implicitTextColor: 'var(--one-ux-palette--orange-500)',
    implicitIconColor: 'var(--one-ux-palette--orange-400)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--orange-400), 0.12)',
    label: (locale) => lang[locale].measurements.marginallyFulfilled
  },
  unfulfilled: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--red-400)',
    implicitTextColor: 'var(--one-ux-palette--red-500)',
    implicitIconColor: 'var(--one-ux-palette--red-400)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--red-400), 0.12)',
    label: (locale) => lang[locale].measurements.unfulfilled
  }
}

const measurementsIcons = {
  empty: 'measure-none',
  fulfilled: 'measure-fulfilled',
  'mostly-fulfilled': 'measure-mostly-fulfilled',
  'marginally-fulfilled': 'measure-marginally-fulfilled',
  'partially-fulfilled': 'measure-partially-fulfilled',
  unfulfilled: 'measure-unfulfilled'
}

const featuresBase: set = {
  activity: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--mint-400)',
    implicitTextColor: 'var(--one-ux-palette--mint-500)',
    implicitIconColor: 'var(--one-ux-palette--mint-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--mint-400), 0.12)',
    label: (locale) => lang[locale].features.activity
  },
  form: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--focus-400)',
    implicitTextColor: 'var(--one-ux-palette--focus-500)',
    implicitIconColor: 'var(--one-ux-palette--focus-400)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--focus-400), 0.12)',
    label: (locale) => lang[locale].features.forms
  },
  meetings: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--brand-neutral-400)',
    implicitTextColor: 'var(--one-ux-palette--brand-neutral-500)',
    implicitIconColor: 'var(--one-ux-palette--brand-neutral-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--brand-neutral-400), 0.12)',
    label: (locale) => lang[locale].features.meetings
  },
  risk: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--keyboard-focus-400)',
    implicitTextColor: 'var(--one-ux-palette--keyboard-focus-500)',
    implicitIconColor: 'var(--one-ux-palette--keyboard-focus-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--keyboard-focus-400), 0.12)',
    label: (locale) => lang[locale].features.risk
  },
  report: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--cyan-400)',
    implicitTextColor: 'var(--one-ux-palette--cyan-500)',
    implicitIconColor: 'var(--one-ux-palette--cyan-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--cyan-400), 0.12)',
    label: (locale) => lang[locale].features.report
  },
  'do-board': {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--violet-400)',
    implicitTextColor: 'var(--one-ux-palette--violet-500)',
    implicitIconColor: 'var(--one-ux-palette--violet-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--violet-400), 0.12)',
    label: (locale) => lang[locale].features.doBoard
  },
  measure: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--keyboard-focus-400)',
    implicitTextColor: 'var(--one-ux-palette--keyboard-focus-500)',
    implicitIconColor: 'var(--one-ux-palette--keyboard-focus-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--keyboard-focus-400), 0.12)',
    label: (locale) => lang[locale].features.measurement
  },
  goal: {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--red-300)',
    implicitTextColor: 'var(--one-ux-palette--red-400)',
    implicitIconColor: 'var(--one-ux-palette--red-300)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--red-300), 0.12)',
    label: (locale) => lang[locale].features.goal
  },
  'process-wheel': {
    textColor: 'var(--one-ux-palette--gray-100)',
    iconColor: 'var(--one-ux-palette--gray-100)',
    backgroundColor: 'var(--one-ux-palette--blue-400)',
    implicitTextColor: 'var(--one-ux-palette--blue-500)',
    implicitIconColor: 'var(--one-ux-palette--blue-500)',
    implicitBackgroundColor: 'rgba(var(--one-ux-rgb--blue-400), 0.12)',
    label: (locale) => lang[locale].features.processWheel
  }
}
const featuresIcons = {
  meetings: 'meetings-action'
}

export const sets = {
  default: defaultSet,
  colors: colorSet,
  activities: addIcons(activitiesSet, 'status'),
  measurements: addIcons(measurementsBase, 'scorecard', measurementsIcons),
  features: addIcons(featuresBase, 'platform-feature', featuresIcons)
} as const

function addIcons(base: set, iconSet: string, iconLookup = {} as Record<string, string>) {
  return Object.keys(base).reduce((result, key) => {
    const icon = {
      set: iconSet as keyof OneUxIconToken,
      icon: (iconLookup[key] ?? key) as OneUxIconToken[keyof OneUxIconToken]
    }
    result[key] = {
      ...base[key],
      lockIcon: true
    }
    result[`${key}-icon`] = {
      ...base[key],
      icon,
      lockIcon: true
    }
    result[`${key}-icon-only`] = {
      ...base[key],
      icon,
      iconOnly: true,
      lockIcon: true
    }
    return result
  }, {} as set)
}
