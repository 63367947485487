import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement.js'
import { Constructor } from '../utils.js'
import { InternalValidityConfigChangedEvent } from '../events/internal/InternalValidityConfigChangedEvent.js'
import { PropertyValues } from 'lit'
import { InternalElementStateChangedEvent } from '../events/internal/InternalElementStateChangedEvent.js'

export declare class IRequired {
  required: boolean
}

export const Required = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class RequiredClass extends SuperClass {
    /**
     * If set, the component will be marked as required.
     */
    @property({ type: Boolean })
    public accessor required = false

    #firstChange = true
    update(changedProperties: PropertyValues) {
      super.update(changedProperties)
      if (changedProperties.has('required')) {
        this.dispatchEvent(new InternalValidityConfigChangedEvent(!this.#firstChange))
        if (this.#firstChange) {
          this.#firstChange = false
        }

        this.dispatchEvent(
          new InternalElementStateChangedEvent({
            property: 'required',
            value: this.required
          })
        )
      }
    }
  }

  return RequiredClass as Constructor<IRequired> & TSuperClass
}
