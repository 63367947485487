import { html } from 'lit'
import { ItemIcon } from '../types.js'
import paletteKeys from '../../../generated/json/palette/palette-keys.json'
import { styleMap } from 'lit/directives/style-map.js'

export function Icon(icon?: ItemIcon) {
  if (!icon) {
    return null
  }
  return html`<one-ux-icon
    class="icon"
    set=${icon.set || 'default'}
    icon=${icon.name}
    size="300"
    style=${styleMap({
      color: icon.color
        ? paletteKeys.includes(icon.color)
          ? `var(--one-ux-palette--${icon.color})`
          : icon.color
        : null
    })}
  ></one-ux-icon>`
}
