import { css, unsafeCSS } from 'lit'
import checkMarkMask from '../../assets/check-mark-mask.svg?data'
import checkIndeterminateMask from '../../assets/check-indeterminate-mask.svg?data'

export const style = css`
  input {
    --one-ux-checkbox-element--size: 16px;
    --one-ux-checkbox-element--height: 24px;
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--gray-100);
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--brand-neutral-300);
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-checkbox-element--color: var(--one-ux-palette--brand-neutral-600);

    appearance: none;
    all: unset;

    display: inline-flex;
    box-sizing: border-box;
    width: var(--one-ux-checkbox-element--size);
    height: var(--one-ux-checkbox-element--size);
    aspect-ratio: 1;

    background-color: var(--one-ux-checkbox-element--background-color);
    border-radius: var(--one-ux-radius--300);

    align-items: center;
    justify-content: center;
    margin: var(--one-ux-spacing--200);
    cursor: pointer;
  }

  input::before {
    --one-ux-checkbox-element--checked-shape: url(${unsafeCSS(checkMarkMask)});
    content: '';
    display: block;
    border: 3px solid var(--one-ux-checkbox-element--border-color);
    box-sizing: border-box;
    -webkit-mask-image: var(--one-ux-checkbox-element--checked-shape);
    mask-image: var(--one-ux-checkbox-element--checked-shape);
    width: var(--one-ux-checkbox-element--size);
    height: var(--one-ux-checkbox-element--size);

    background-color: transparent;
    border-radius: var(--one-ux-radius--300);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  input:checked:before {
    background-color: var(--one-ux-checkbox-element--symbol-color);
  }

  input:hover {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--brand-neutral-400);
  }

  input:is(:checked, :indeterminate) {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--brand-neutral-600);
  }

  input:is(:checked, :indeterminate):hover {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--brand-neutral-700);
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--brand-neutral-700);
  }

  input:indeterminate:before {
    background-color: var(--one-ux-checkbox-element--symbol-color);
    --one-ux-checkbox-element--checked-shape: url(${unsafeCSS(checkIndeterminateMask)});
  }

  :host([implicit]) input {
    --one-ux-checkbox-element--background-color: transparent;
  }

  :host([purpose='main']) input:checked {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--brand-core-400);
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--brand-core-400);
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--gray-100);
  }

  :host([purpose='main']) input:checked:hover {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--brand-core-500);
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--brand-core-500);
  }

  :host([purpose='main']) input:indeterminate {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--brand-core-400);
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--brand-core-400);
  }

  :host([purpose='main']) input:indeterminate:hover {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--brand-core-500);
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--brand-core-500);
  }

  :host([purpose='caution']) input {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--red-300);
  }

  :host([purpose='caution']) input:hover {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--red-400);
  }

  :host([purpose='caution']) input:checked {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--red-400);
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--red-400);
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--gray-100);
  }

  :host([purpose='caution']) input:checked:hover {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--red-500);
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--red-500);
  }

  :host([purpose='caution']) input:indeterminate {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--red-400);
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--red-400);
  }

  :host([purpose='caution']) input:indeterminate:hover {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--red-500);
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--red-500);
  }

  :host([purpose='notice']) input {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--focus-400);
  }

  :host([purpose='notice']) input:hover {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--focus-500);
  }

  :host([purpose='notice']) input:is(:checked, :indeterminate) {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--focus-200);
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--focus-400);
  }

  :host([purpose='notice']) input:is(:checked, :indeterminate):hover {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--focus-300);
  }

  :host([implicit][purpose='main']) input:checked {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--brand-core-400);
  }

  :host([implicit][purpose='main']) input:checked:hover {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--brand-core-500);
  }

  :host([implicit][purpose='caution']) input:checked {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--red-400);
  }

  :host([implicit][purpose='caution']) input:checked:hover {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--red-500);
  }

  :host([implicit][purpose='notice']) input:is(*, :checked, :indeterminate) {
    --one-ux-checkbox-element--background-color: transparent;
  }

  :host([implicit][purpose='notice']) input:hover {
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--focus-500);
  }

  :host([state-keyboard-focus]) input:focus:not([aria-disabled='true']) {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--keyboard-focus-200) !important;
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--keyboard-focus-400) !important;
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--keyboard-focus-700) !important;
  }

  input[aria-disabled='true'] {
    --one-ux-checkbox-element--background-color: var(--one-ux-palette--gray-150) !important;
    --one-ux-checkbox-element--border-color: var(--one-ux-palette--gray-300) !important;
    --one-ux-checkbox-element--symbol-color: var(--one-ux-palette--gray-400) !important;

    pointer-events: none;
  }
`
