const validVersionRegex = /^[~^*]?[0-9]+\.[0-9]+\.[0-9]+(?:-.+)?$/

export abstract class Version {
  versionString: string
  type: string
  constructor(versionString: string, type: string) {
    this.versionString = versionString
    this.type = type
  }
  static valid(versionString: string) {
    if (versionString === 'latest' || versionString === 'stable') {
      return true
    }
    return validVersionRegex.test(versionString)
  }

  abstract fulfills(version: Version): boolean

  toString() {
    return this.versionString
  }
}
