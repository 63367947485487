import { css, unsafeCSS } from 'lit'
import { SHORTHAND_ID } from './TooltipAttributeHandler.js'

export const style = css`
  :host {
    position: absolute;
    pointer-events: none !important;
    /* Max z-index allowed. */
    z-index: 2147483647;

    /* Required for Playwright to consider it as visible */
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;

    border: none !important;
    background: none !important;
    padding: 0 !important;
  }

  :host(:not([role='tooltip'])) {
    display: none !important;
  }

  one-ux-popout {
    background-color: white;
    border-radius: var(--one-ux-radius--200);
  }

  .tooltip {
    --one-ux-tooltip--grid-layout: 1fr;

    display: grid;
    grid-template-columns: var(--one-ux-tooltip--grid-layout);
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.9);
    border-radius: var(--one-ux-radius--200);
    color: var(--one-ux-palette--gray-100);
    padding: var(--one-ux-spacing--small) var(--one-ux-spacing--normal);
    gap: 1em var(--one-ux-spacing--small);
    font: var(--one-ux-font--body-200);
    letter-spacing: var(--one-ux-letter-spacing--body-200);
    line-height: 16px;
    min-height: 24px;
    box-sizing: border-box;
    max-width: min(35vw, 350px);
  }

  :host(#${unsafeCSS(SHORTHAND_ID)}) .tooltip {
    white-space: pre-line;
    overflow-wrap: anywhere;
  }

  ::slotted(one-ux-icon) {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    overflow: hidden !important;
    flex-shrink: 0 !important;
  }

  ::slotted(one-ux-text) {
    grid-column: 2 / span 1;
  }
`
