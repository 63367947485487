import { createRef, Ref } from 'lit/directives/ref.js'
import * as ToastListRenderer from './toastListRenderer.js'

const DEFAULT_DURATION = 6000

export type toastType = 'confirmation' | 'info' | 'warning'

let TOAST_ID_GENERATOR = 1

export class Toast {
  id: number
  type: toastType
  title: string
  description: string
  duration: number | boolean
  hasCopyAction: boolean
  isCopied = false

  $ref: Ref<HTMLElement> = createRef()

  #resolveOnClose: (value: unknown) => void = () => {}

  constructor(
    type: toastType,
    title: string,
    description: string = '',
    duration: number | boolean = DEFAULT_DURATION,
    hasCopyAction: boolean = false
  ) {
    this.id = TOAST_ID_GENERATOR++
    this.type = type
    this.title = title
    this.description = description
    this.duration = duration === false ? false : Number.isInteger(duration) ? duration : DEFAULT_DURATION
    this.hasCopyAction = hasCopyAction
  }

  async show() {
    return new Promise((resolve) => {
      this.#resolveOnClose = resolve
      ToastListRenderer.add(this)
    })
  }

  async close() {
    await ToastListRenderer.remove(this)
    this.#resolveOnClose(undefined)
  }

  async copy() {
    await ToastListRenderer.copy(this)
    this.#resolveOnClose(undefined)
  }
}
