import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    --one-ux-pill--background: var(--one-ux-palette--brand-neutral-600);
    --one-ux-pill--size: 16px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: var(--one-ux-radius--max);
    color: var(--one-ux-palette--gray-100);
    padding: 0 var(--one-ux-spacing--200);
    text-align: center;

    background: var(--one-ux-pill--background);
    font: var(--one-ux-font--mono-variant-400);
    letter-spacing: var(--one-ux-letter-spacing--mono-variant-400);

    width: auto;
    height: var(--one-ux-pill--size);
    min-width: var(--one-ux-pill--size);

    &.pulse {
      animation: pulse 2s infinite;
    }

    :host([purpose='main']) & {
      --one-ux-pill--background: var(--one-ux-palette--brand-core-400);
    }

    :host([purpose='caution']) & {
      --one-ux-pill--background: var(--one-ux-palette--red-300);
    }

    :host([purpose='promote']) & {
      --one-ux-pill--background: var(--one-ux-palette--rose-400);
    }

    :host([weight='high']) & {
      font: var(--one-ux-font--mono-variant-600);
      letter-spacing: var(--one-ux-letter-spacing--mono-variant-600);
      --one-ux-pill--size: 20px;
    }

    :host([weight='low']) & {
      font: var(--one-ux-font--mono-variant-200);
      letter-spacing: var(--one-ux-letter-spacing--mono-variant-200);
      --one-ux-pill--size: 12px;
    }

    &:is(.is-single-character, .is-empty) {
      width: var(--one-ux-pill--size);
      padding: 0;
    }

    &.is-empty {
      --one-ux-pill--size: 8px;

      &::before {
        content: '\\200b';
      }

      :host([weight='high']) & {
        --one-ux-pill--size: 12px;
      }

      :host([weight='low']) & {
        --one-ux-pill--size: 6px;
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 var(--one-ux-pill--background);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`
