import { css } from 'lit'

export const style = css`
  .root {
    position: relative;
    overflow: hidden;
    border-radius: inherit;

    --one-ux-scroll-element--scrollbar-size: 16px;
    --one-ux-scroll-element--scrollbar-background: none;
    --one-ux-scroll-element--scrollbar-border-width: 0;
    --one-ux-scroll-element--scrollbar-border-color: var(--one-ux-scroll-theme--scrollbar-border-color);
    --one-ux-scroll-element--scrollbar-border: var(--one-ux-scroll-element--scrollbar-border-width) solid
      var(--one-ux-scroll-theme--scrollbar-border-color);

    --one-ux-scroll-element--track-size: 8px;
    --one-ux-scroll-element--track-background: transparent;
    --one-ux-scroll-element--track-indent: var(--one-ux-spacing--small);

    --one-ux-scroll-element--thumb-size: 8px;
    --one-ux-scroll-element--thumb-min-size: 4px;
    --one-ux-scroll-element--thumb-border-radius: var(--one-ux-radius--300);
    --one-ux-scroll-element--thumb-background: var(--one-ux-scroll-theme--thumb-background);
    --one-ux-scroll-element--thumb-background-hover: var(--one-ux-scroll-theme--thumb-background-hover);
    --one-ux-scroll-element--thumb-background-pressed: var(--one-ux-scroll-theme--thumb-background-pressed);
  }

  :host([weight='low']) .root {
    --one-ux-scroll-element--track-size: 4px;
    --one-ux-scroll-element--thumb-size: 4px;
  }

  :host([weight='high']) .root {
    --one-ux-scroll-element--scrollbar-background: var(--one-ux-scroll-theme--scrollbar-background-color);
    --one-ux-scroll-element--scrollbar-border-width: var(--one-ux-border-width--400);
  }

  :host([implicit]) .root {
    --one-ux-scroll-element--scrollbar-border: none;
  }

  /* CONTENT */
  .content {
    height: 100%;
    overflow: scroll;
    scrollbar-width: none;
  }

  .content::-webkit-scrollbar {
    display: none;
  }

  /* SCROLLBAR */
  .scrollbar {
    display: none;
    position: absolute;
    align-items: center;
    box-sizing: border-box;
    background: var(--one-ux-scroll-element--scrollbar-background);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  .scrollbar-x {
    flex-flow: row nowrap;
    width: 100%;
    height: var(--one-ux-scroll-element--scrollbar-size);
    bottom: 0;
    left: 0;
    border-top: var(--one-ux-scroll-element--scrollbar-border);
    border-bottom: var(--one-ux-scroll-element--scrollbar-border);
  }

  .scrollbar-y {
    flex-flow: column nowrap;
    height: 100%;
    width: var(--one-ux-scroll-element--scrollbar-size);
    top: 0;
    right: 0;

    border-left: var(--one-ux-scroll-element--scrollbar-border);
    border-right: var(--one-ux-scroll-element--scrollbar-border);
  }

  :host([implicit]:is([weight='normal'], [weight='low']):not(:hover)) .scrollbar {
    display: none;
  }

  .show-x .scrollbar-x,
  .show-y .scrollbar-y {
    display: flex;
  }

  .show-x.show-y .scrollbar-x {
    width: calc(100% - var(--one-ux-scroll-element--scrollbar-size));
  }
  .show-x.show-y .scrollbar-y {
    height: calc(100% - var(--one-ux-scroll-element--scrollbar-size));
  }

  /* SCROLLBAR-TRACK */
  .scrollbar-track {
    flex: 1;
    position: relative;
    z-index: 1;
    backface-visibility: hidden;
    background-color: var(--one-ux-scroll-element--track-background);
  }

  .scrollbar-track-x {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--one-ux-scroll-element--track-size);
    margin-left: var(--one-ux-scroll-element--track-indent);
    margin-right: var(--one-ux-scroll-element--track-indent);
  }

  .scrollbar-track-y {
    display: flex;
    justify-content: center;
    width: var(--one-ux-scroll-element--track-size);
    height: 100%;
    margin-top: var(--one-ux-scroll-element--track-indent);
    margin-bottom: var(--one-ux-scroll-element--track-indent);
  }

  /* SCROLLBAR-THUMB */
  .scrollbar-thumb {
    position: absolute;
    width: 100%;
    border-radius: var(--one-ux-scroll-element--thumb-border-radius);
    background: var(--one-ux-scroll-element--thumb-background);
    cursor: pointer;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  .scrollbar-thumb-x {
    height: var(--one-ux-scroll-element--thumb-size);
    min-width: var(--one-ux-scroll-element--thumb-min-size);
  }

  .scrollbar-thumb-y {
    width: var(--one-ux-scroll-element--thumb-size);
    min-height: var(--one-ux-scroll-element--thumb-min-size);
  }

  .scrollbar:hover .scrollbar-thumb {
    background: var(--one-ux-scroll-element--thumb-background-hover);
  }

  .scrollbar:active .scrollbar-thumb {
    background: var(--one-ux-scroll-element--thumb-background-pressed);
  }

  /* SCROLLBAR-CORNER */
  .scrollbar-corner {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: var(--one-ux-scroll-element--scrollbar-size);
    height: var(--one-ux-scroll-element--scrollbar-size);
    box-sizing: border-box;
  }

  :host([weight='high']) .scrollbar-corner {
    background-color: var(--one-ux-scroll-element--scrollbar-background);
    border-bottom: var(--one-ux-scroll-element--scrollbar-border);
    border-right: var(--one-ux-scroll-element--scrollbar-border);
  }

  :host([weight='high']) .scrollbar-corner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--one-ux-scroll-element--scrollbar-border-width);
    height: var(--one-ux-scroll-element--scrollbar-border-width);
    background-color: var(--one-ux-scroll-element--scrollbar-border-color);
  }

  .show-x.show-y .scrollbar-corner {
    display: block;
  }
`
