import { html } from 'lit'
import type { OneUxDatePickerElement } from '../OneUxDatePickerElement.js'
import { isoDateString, isoDate } from '../IsoDate.js'
import type { OneUxListElement } from '../../one-ux-list/OneUxListElement.js'
import { modulo } from '../../../utils/modulo.js'

export function Navigation(
  this: OneUxDatePickerElement,
  {
    activeDate,
    monthNames,
    onActiveChange
  }: {
    activeDate: isoDateString
    monthNames: string[]
    onActiveChange: (activeDate: isoDateString) => void
  }
) {
  const numberOfMonthsBefore = 3
  const numberOfMonthsAfter = 8
  const [year, month] = isoDate.getParts(activeDate)

  const deltaYearOptions = Array.from({ length: numberOfMonthsBefore + 1 + numberOfMonthsAfter }, (_, index) => {
    const deltaMonths = index - numberOfMonthsBefore
    const accumulatedMonthValue = month - 1 + deltaMonths
    const deltaYears = Math.floor(accumulatedMonthValue / 12)
    return {
      text: `${monthNames[modulo(accumulatedMonthValue, 12)]} ${year + deltaYears}`,
      value: deltaMonths
    }
  })

  return html`
    <div class="navigation" aria-hidden="true" ?inert=${this.disabled}>
      <one-ux-widget-button
        icon="toggle-left"
        @click=${() => onActiveChange(isoDate.addMonths(activeDate, -1))}
      ></one-ux-widget-button>

      <one-ux-adapter disable-notify>
        <one-ux-dropdown tabindex="-1">
          <span slot="preview" class="month-and-year">${monthNames[month - 1]} ${year}</span>
          <one-ux-list
            .value=${[0]}
            .options=${deltaYearOptions}
            @input=${(e: Event & { target: OneUxListElement }) =>
              onActiveChange(isoDate.addMonths(activeDate, e.target.value[0] as number))}
          ></one-ux-list>
        </one-ux-dropdown>
      </one-ux-adapter>

      <one-ux-widget-button
        icon="toggle-right"
        @click=${() => onActiveChange(isoDate.addMonths(activeDate, 1))}
      ></one-ux-widget-button>
    </div>
  `
}
