import { PropertyValues, html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { OneUxElement } from '../../OneUxElement.js'

import { Focusable } from '../../mixins/Focusable.js'
import { Disabled } from '../../mixins/Disabled.js'
import { TreeContextMixin as TreeContext } from '../../contexts/tree/TreeContextMixin.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { style } from './style.js'
import { Required } from '../../mixins/Required.js'
import { Explicit } from '../../mixins/Explicit.js'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../../mixins/Validated.js'
import type { IValue } from '../../mixins/Value.js'
import type { IRequired } from '../../mixins/Required.js'
import { consume } from '@lit/context'
import { getLanguage } from './translations.js'

import { traverseNodes } from '../../utils.js'
import type { InputNode } from '../../contexts/tree/contextual-one-ux-tree/types.js'
import { InternalElementStateChangedEvent } from '../../events/internal/InternalElementStateChangedEvent.js'
import { FormAssociated } from '../../mixins/FormAssociated.js'
import { defaultPopoutContext, popoutContext } from '../../contexts/PopoutContext.js'
import { defaultDropdownContext, dropdownContext } from '../../contexts/DropdownContext.js'

const Styled = StyledFactory(style)

const Validated = ValidatedFactory<IValue<string | string[]> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldYouHaveToMakeChoice } = getFormValidationLanguage(this)
    const valid = !this.empty
    return {
      valid: valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldYouHaveToMakeChoice]
    }
  }
})

const BaseClass = FormAssociated(Validated(TreeContext(Required(Disabled(Focusable(Explicit(Styled(OneUxElement))))))))

/**
 * Tree component for selecting single och multiple nodes
 */
@customElement('one-ux-tree')
export class OneUxTreeElement extends BaseClass {
  @consume({ context: dropdownContext, subscribe: true })
  private _dropdownContext = defaultDropdownContext

  @consume({ context: popoutContext, subscribe: true })
  private _popoutContext = defaultPopoutContext

  protected render() {
    return html`
      <contextual-one-ux-tree
        class="one-ux-element--root"
        ?disabled=${this.disabled}
        ?implicit=${!this.explicit}
        @input=${(event: Event) => {
          event.stopPropagation()
          this.dispatchEvent(
            new InternalElementStateChangedEvent({
              property: 'empty',
              value: this.empty
            })
          )
          if (!this.multiple) {
            this._popoutContext.closePopout()
          }
          this._dropdownContext.updatePreview(this.#getPreview())
          this.dispatchEvent(new Event(event.type, event))
        }}
      ></contextual-one-ux-tree>
    `
  }

  protected updated(changed: PropertyValues<this>) {
    if (changed.has('value') || changed.has('nodes')) {
      this._dropdownContext.updatePreview(this.#getPreview())
    }
  }

  #getPreview() {
    const { translations } = getLanguage(this)
    const values = typeof this.value == 'string' ? this.value.split(',') : this.value
    if (!values.length) {
      return null
    }
    const text =
      values.length > 1
        ? translations.selected.replace(/\$0/g, values.length.toFixed())
        : this.#getNodeText(values[0] as string)

    return text ?? null
  }

  #getNodeText = (value: string) => {
    let text
    traverseNodes(this.nodes, (node: InputNode) => {
      if (node.value == value) {
        text = node.text
      }
    })
    return text
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tree': OneUxTreeElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tree': OneUxTreeElement
    }
  }
}
