import oneUxEasing from '../generated/json/easing/easing.json'

type easingFunctionKeys = keyof typeof oneUxEasing
export type easingFunctions = (typeof oneUxEasing)[easingFunctionKeys]

export const timingFunction = (easing: easingFunctions) => `${easing.function}(${easing.parameters.join(', ')})`

export const flushAnimations = ($element: Element) => {
  for (const animation of $element.getAnimations()) {
    animation.finish()
  }
}
