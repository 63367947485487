import { timingFunction } from '../../utils/animation-utils.js'
import oneUxEasing from '../../generated/json/easing/easing.json'
import spacing from '../../generated/json/spacing/spacing.json'

export function getAnimationOptions(isCollapsible: boolean, isClosed: boolean, $root: ShadowRoot) {
  if (isCollapsible) {
    return {
      animation: isClosed ? collapseAnimation : expandAnimation,
      $animated: $root.querySelector('.content'),
      height: $root.querySelector('.inner-content')?.clientHeight
    }
  } else {
    return {
      animation: isClosed ? dismissAnimation : showAnimation,
      $animated: $root.querySelector('.one-ux-element--root'),
      height: $root.querySelector('.message')?.clientHeight
    }
  }
}

const expandAnimation = (height: number) => [
  {
    visibility: 'visible',
    height: 'var(--one-ux-message--content-collapsed-height)',
    opacity: 1,
    easing: timingFunction(oneUxEasing.enter),
    gap: 0
  },
  {
    height: height + 'px',
    opacity: 1,
    easing: timingFunction(oneUxEasing.default),
    gap: spacing.normal
  },
  {
    height: height + 'px',
    opacity: 1,
    gap: spacing.normal
  }
]

const collapseAnimation = (height: number) => [
  {
    height: height + 'px',
    opacity: 1,
    visibility: 'visible',
    easing: timingFunction(oneUxEasing.default),
    gap: spacing.normal
  },
  {
    height: height + 'px',
    opacity: 1,
    visibility: 'visible',
    easing: timingFunction(oneUxEasing.exit),
    gap: 0
  },
  {
    height: 'var(--one-ux-message--content-collapsed-height)',
    opacity: 1,
    visibility: 'visible',
    gap: 0
  }
]

const dismissAnimation = (height: number) => [
  {
    height: height + 'px',
    opacity: 1,
    visibility: 'visible',
    easing: timingFunction(oneUxEasing.default)
  },
  {
    height: height + 'px',
    opacity: 0,
    visibility: 'visible',
    easing: timingFunction(oneUxEasing.exit)
  },
  {
    height: 0,
    opacity: 0,
    visibility: 'hidden'
  }
]

const showAnimation = (height: number) => [
  {
    height: 0,
    opacity: 0,
    visibility: 'hidden',
    easing: timingFunction(oneUxEasing.default)
  },
  {
    height: height + 'px',
    opacity: 1,
    visibility: 'visible',
    easing: timingFunction(oneUxEasing.exit)
  },
  {
    height: height + 'px',
    opacity: 1,
    visibility: 'visible'
  }
]
