import { avatarState } from '../../../one-ux/contexts/AvatarContext.js'
import { AvatarContextCacheRepository } from './AvatarContextCacheRepository.js'

type stateCacheEntry = {
  state: avatarState
  createdAt: string
}

const EXPIRED_AFTER_MS = 1000 * 60 * 60 * 24 * 2 // two days

export class StateContextCacheRepository extends AvatarContextCacheRepository<stateCacheEntry> {
  constructor() {
    super('state', EXPIRED_AFTER_MS)
  }

  public get(tenantId: string, userId: string): { state: avatarState; expired: boolean } {
    const entry = this.getEntry(tenantId, userId)
    if (!entry) {
      return { state: 'none', expired: true }
    }

    const expired = this.hasExpired(entry)
    return { state: entry.state, expired }
  }

  public update(tenantId: string, userId: string, state: avatarState) {
    this.setEntry(tenantId, userId, {
      state,
      createdAt: new Date().toISOString()
    })
  }
}
