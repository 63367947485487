import { PDRModule } from '../PDRModule.js'
import { CustomSettings, OIDCSettings, SessionSettings } from './SessionSettings.js'

export class SessionModule extends PDRModule<OIDCSettings | CustomSettings> {
  #manager
  constructor() {
    super(SessionSettings.build)
    this.#manager = this._initialized.then(async ([{ utils }, config]) => {
      if (config instanceof CustomSettings) {
        const manager = await import(
          /* webpackChunkName: "custom-session-manager" */
          './custom/CustomManager'
        )
        return new manager.CustomManager(config)
      }
      const manager = await import(
        /* webpackChunkName: "oidc-session-manager" */
        './oidc/OIDCManager'
      )
      return new manager.OIDCManager(utils.lang, config)
    })
  }

  async getUser(forcedRefresh = false, scopes = [] as string[]) {
    const manager = await this.#manager
    return manager.getUser(forcedRefresh, scopes)
  }

  async signOut() {
    const manager = await this.#manager
    manager.signOut()
  }

  async hasSession(scopes = []) {
    const manager = await this.#manager
    return manager.hasSession(scopes)
  }
}
