import { Optional } from '../../types.js'

export const lerp = (a: number, b: number, t: number) => (1 - t) * a + t * b
export const invLerp = (a: number, b: number, t: number) => (t - a) / (b - a)
export const clamp = (value: number, min = 0, max = 1) => Math.min(max, Math.max(min, value))

export const precisionRound = (value: number, precision: number = 8) => {
  const factor = Math.pow(10, precision)
  return Math.round((value + Number.EPSILON) * factor) / factor
}

export const optionalMin = (minValue: Optional<number>, currentValue: Optional<number>) => {
  if (!Number.isFinite(minValue)) {
    return currentValue
  }
  if (!Number.isFinite(currentValue)) {
    return minValue
  }
  return Math.min(Number(minValue), Number(currentValue))
}

export const optionalMax = (maxValue: Optional<number>, currentValue: Optional<number>) => {
  if (!Number.isFinite(maxValue)) {
    return currentValue
  }
  if (!Number.isFinite(currentValue)) {
    return maxValue
  }
  return Math.max(Number(maxValue), Number(currentValue))
}
