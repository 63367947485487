import type { optionData, colorMatrix } from './types.js'

const PAGE_SIZE = 5

const directions = ['right', 'left', 'up', 'down'] as const
const columns = ['firstColumn', 'lastColumn'] as const
const pages = ['nextPage', 'previousPage'] as const

type direction = (typeof directions)[number]
type column = (typeof columns)[number]
type page = (typeof pages)[number]

type newPosition = direction | column | page

export class ActiveOptionCalculator {
  constructor(
    private colorMatrix: colorMatrix,
    private activeOption: optionData
  ) {}

  #currentPosition = this.#findPosition(this.activeOption)

  public tryGo(to: newPosition) {
    if (!this.#currentPosition) {
      return this.activeOption
    }

    const ensureValidResult = (result: optionData) => result || this.activeOption

    if (directions.includes(to as direction)) {
      return ensureValidResult(this.#tryStep(to as direction))
    }
    if (columns.includes(to as column)) {
      return ensureValidResult(this.#tryGoToColumn(to as column))
    }
    if (pages.includes(to as page)) {
      return ensureValidResult(this.#tryJumpToRow(to as page))
    }
  }

  #tryStep(direction: direction) {
    let { row, col } = this.#currentPosition!
    const delta = direction === 'left' || direction === 'up' ? -1 : 1
    const lastRow = this.colorMatrix.length - 1

    if (direction === 'left' || direction === 'right') {
      const lastColumn = this.colorMatrix[row].length - 1
      col = this.#clamp(col + delta, 0, lastColumn)
    } else {
      row = this.#clamp(row + delta, 0, lastRow)
    }

    while (this.colorMatrix[row] && !this.colorMatrix[row][col]) {
      row += delta
    }

    return this.colorMatrix[row]?.[col]
  }

  #tryGoToColumn(position: column) {
    const { row } = this.#currentPosition!
    const lastColumn = this.colorMatrix[row].length - 1
    const newCol = position === 'firstColumn' ? 0 : lastColumn

    return this.colorMatrix[row][newCol]
  }

  #tryJumpToRow(page: page) {
    let { row, col } = this.#currentPosition!
    const rowDelta = page === 'nextPage' ? PAGE_SIZE : -PAGE_SIZE
    const lastRow = this.colorMatrix.length - 1

    row = this.#clamp(row + rowDelta, 0, lastRow)

    while (this.colorMatrix[row] && !this.colorMatrix[row][col]) {
      row = this.#clamp(row + rowDelta / Math.abs(rowDelta), 0, lastRow)

      if (!this.colorMatrix[row][col] && page === 'nextPage') {
        --col
      }
    }

    return this.colorMatrix[row]?.[col]
  }

  #clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, value))
  }

  #findPosition(option: optionData): { row: number; col: number } | null {
    for (let rowIndex = 0; rowIndex < this.colorMatrix.length; rowIndex++) {
      const row = this.colorMatrix[rowIndex]
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        if (row[colIndex] === option) {
          return { row: rowIndex, col: colIndex }
        }
      }
    }
    return null
  }
}
