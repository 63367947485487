import { PropertyValues, html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { spreadEvents } from '@open-wc/lit-helpers'
import { OneUxElement } from '../../OneUxElement.js'
import { FocusableFactory } from '../../mixins/Focusable.js'
import { Explicit } from '../../mixins/Explicit.js'
import { UpdateOnResizedController } from '../../controllers/UpdateOnResizedController.js'
import { Weight } from '../../mixins/Weight.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { keyCodes } from '../../utils.js'
import { InputTab, InternalSubtab, InternalTab, InternalTabBase, ScrollDirection } from './types.js'
import { mapState } from './utils/mapState.js'
import { setOverflow } from './utils/setOverflow.js'
import { scrollToLeft, scrollToCurrent, scrollToRight } from './utils/scrollTo.js'
import { style } from './style.js'
import { TabsFragment } from './fragments/TabsFragment.js'
import { getLanguage } from './language.js'
import { OneUxSpacingToken } from '../../generated/design-tokens.js'
import { styleMap } from 'lit/directives/style-map.js'
import { log } from '../../utils/log.js'
import {
  getActiveTab,
  getImplicitActiveTab,
  getFirstTab,
  getNextTab,
  getPreviousTab,
  getTabSiblings,
  getLastTab
} from './utils/tabGetters.js'
import { setActiveTabState } from './utils/setActiveTabState.js'
import { SlotController } from '../../controllers/SlotController.js'
import { OneUxTabsPreviewElement } from '../one-ux-tabs-preview/OneUxTabsPreviewElement.js'
import { validEdge } from '../one-ux-tabs-preview/types.js'
import { Optional } from '../../types.js'
import { AddEvent, TabEvent, BeforeTabEvent } from '../one-ux-tabs-preview/events.js'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const BaseClass = Weight(Explicit(Focusable(Styled(OneUxElement))))

/**
 * Tabbable section with support for another layer of subtabs for each tab.
 * A tab can have slotted content with either a custom slot name [slot="customName"] or set to the expected generated slot name based on tab nr and subtab nr (tab-1, tab-1-3 etc.).
 */
@customElement('one-ux-tabs')
export class OneUxTabsElement extends BaseClass {
  /**
   * Accessability label for the tablist.
   */
  @property({ type: String })
  public accessor label = ''

  /**
   * Sets the placement of tabs.
   */
  @property({ type: String, reflect: true })
  public accessor placement: 'start' | 'center' | 'end' | 'flex' = 'start'

  /**
   * InputTab:
   * The tab to be clicked. Optinal custom event "tab" can be listened on for detecting a clicked tab.
   * * slot: Must be set to the same name as the custom [slot="customName"] name for slotted tab content. Will default to generated slot name based on tab nr and subtab nr (tab-1, tab-1-3 etc.).
   * * icon: Optional icon for the or only icon.
   * * disabled: Non interactable greyed out option.
   * * tabs: Subtabs for current tab.
   */
  @property({ type: Array })
  public accessor tabs: Optional<InputTab[]>

  /**
   * Allows control over the indentation of the content.
   */
  @property({ attribute: 'indent-content', type: String })
  public accessor indentContent: OneUxSpacingToken = 'normal'

  /**
   * Allows control over the indentation of the tabs.
   */
  @property({ attribute: 'indent-tabs', type: String })
  public accessor indentTabs: OneUxSpacingToken = 'none'

  /**
   * Disable auto activate tab on focus.
   */
  @property({ type: Boolean, reflect: true })
  public accessor manual = false

  /**
   * Sets active tab
   */
  @property({ type: String, attribute: 'active-tab' })
  get activeTab() {
    return this.selected
  }
  set activeTab(value: string) {
    this.selected = value
  }

  /*
   * ------------ START ------------
   * Properties for one-ux-tabs-preview
   */
  @property({ type: String })
  public accessor selected = ''

  @property({ type: Boolean, attribute: 'show-add' })
  public accessor showAdd = false

  @property({ type: String, attribute: 'show-add-label' })
  public accessor showAddLabel = ''

  @property({ type: Array, attribute: 'collapse-edge' })
  public accessor collapseEdge: validEdge[] = []

  // This is only to be compliant with <one-ux-tabs-preview>
  @property({ type: Boolean })
  public accessor implicit = false
  /* ------------ END ------------ */

  /** @internal */
  @state()
  accessor _tabs!: InternalTab[]

  /** @internal */
  @state()
  accessor _focusedTab!: InternalTabBase

  /** @internal */
  @state()
  accessor _hasSlotContent = false

  #isFocusedByUser = false

  #slots = new SlotController(this, {
    defaultSlot: true
  })

  constructor() {
    super()

    this.width = 'max'

    new UpdateOnResizedController(this)
  }

  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('tabs') && this.tabs?.length) {
      this._tabs = mapState(this.tabs, this.activeTab)
      const activeTab = getActiveTab(this._tabs, this.activeTab)
      this._focusedTab = activeTab
    } else if (changed.has('activeTab') && this.activeTab && this._tabs?.length) {
      const activeTab = getActiveTab(this._tabs, this.activeTab)
      setActiveTabState(this._tabs, activeTab)
      this._focusedTab = activeTab
    }
  }

  protected updated(changed: PropertyValues<this>): void {
    for (const $tabs of this.shadowRoot!.querySelectorAll('.tabs')) {
      setOverflow($tabs as HTMLElement)
    }

    if (changed.has('_focusedTab') && this.#isFocusedByUser) {
      const $focusedTab = this.shadowRoot!.querySelector('#active-tab-item') as HTMLElement
      if ($focusedTab) {
        scrollToCurrent($focusedTab)
      }
    }
    this.#isFocusedByUser = false
  }

  protected render() {
    if (!this.tabs) {
      const slotNames = Array.from(new Set(Array.from(this.querySelectorAll('[slot]')).map(($el) => $el.slot)))
      const customBackgroundColor = getComputedStyle(this).getPropertyValue('--one-ux-tabs--background-color').trim()
      const events = {
        [AddEvent.eventName]: () => this.dispatchEvent(new AddEvent()),
        [BeforeTabEvent.eventName]: (e: CustomEvent) => {
          if (!this.dispatchEvent(new BeforeTabEvent(e.detail))) {
            e.preventDefault()
          }
        },
        [TabEvent.eventName]: (e: CustomEvent) => {
          this.selected = e.detail
          this.dispatchEvent(new TabEvent(e.detail))
        },
        __internaltab__: (e: CustomEvent) => {
          this.selected = e.detail
        }
      }

      return html`
        <one-ux-tabs-preview
          .label=${this.label}
          .selected=${this.selected}
          .collapseEdge=${this.collapseEdge}
          .showAdd=${this.showAdd}
          .showAddLabel=${this.showAddLabel}
          .implicit=${this.implicit}
          .weight=${this.weight}
          .width=${this.width}
          .height=${this.height}
          style=${styleMap({
            '--one-ux-tabs--background-color': customBackgroundColor
          })}
          ${spreadEvents(events)}
        >
          <slot
            @slotchange=${() => {
              const $tabsPreview = this.shadowRoot!.querySelector<OneUxTabsPreviewElement>('one-ux-tabs-preview')!
              $tabsPreview._updateDefaultSlot()
            }}
          ></slot>
          ${slotNames.map((name) => html`<slot name=${name} slot=${name}></slot>`)}
        </one-ux-tabs-preview>
      `
    }

    if (!this.#validate()) {
      return
    }

    const { languageKey, languageSet } = getLanguage(this)
    const activeTab = getImplicitActiveTab(this._tabs, this.activeTab)
    const subtabs =
      activeTab.type === 'parent' ? (activeTab as InternalTab).subtabs : getTabSiblings(this._tabs, activeTab)

    const tabPanelStyle = styleMap({
      marginTop: !this.indentContent || this.indentContent === 'none' ? null : `var(--one-ux-spacing--normal)`,
      padding:
        !this.indentContent || this.indentContent === 'none' ? null : `var(--one-ux-spacing--${this.indentContent})`
    })
    return html`<div
      class="one-ux-element--root"
      tabindex="0"
      aria-activedescendant="active-tab-item"
      lang=${languageKey}
      @keydown=${(e: KeyboardEvent) => this.#handleKeydown(e)}
      @focus=${() => this.#handlefocus()}
    >
      <slot></slot>
      ${TabsFragment({
        tabs: this._tabs,
        indent: this.indentTabs,
        focusedTab: this._focusedTab,
        label: this.label,
        iconSize: this.#getIconSize(true),
        languageSet,
        weight: this.weight,
        onTabClicked: (tab, activate) => this.#handleTabClicked(tab, activate),
        onTabScroll: (e, direction) => this.#handleTabScroll(e, direction)
      })}
      <div
        class=${classMap({
          'tabs--content': true,
          'has-content': this._hasSlotContent
        })}
      >
        ${subtabs.length
          ? TabsFragment({
              tabs: subtabs,
              indent: this.indentTabs,
              focusedTab: this._focusedTab,
              label: this.label,
              iconSize: this.#getIconSize(false),
              languageSet,
              weight: this.weight,
              onTabClicked: (tab, activate) => this.#handleTabClicked(tab, activate),
              onTabScroll: (e, direction) => this.#handleTabScroll(e, direction)
            })
          : nothing}
        <div id="tabs--tabpanel" tabindex="0" role="tabpanel" aria-label=${activeTab.label} style=${tabPanelStyle}>
          <slot @slotchange=${this.#onSlotchange} name=${activeTab.id}></slot>
        </div>
      </div>
    </div>`
  }

  #validate() {
    if (!this.label?.length) {
      log.error('Missing label, not rendering.')
      return false
    }
    if (!this._tabs?.length) {
      log.error('Missing tabs, not rendering.')
      return false
    }

    const isEveryTabDisabled = (tabs: InternalTabBase[]) => tabs.length && tabs?.every((x) => x.disabled)
    if (isEveryTabDisabled(this._tabs) || this._tabs.every((tab) => isEveryTabDisabled(tab.subtabs))) {
      log.error("All tabs can't be disabled (including subtabs), not rendering.")
      return false
    }

    const isAnyTabMissingLabel = (tabs: InternalTabBase[]) => tabs.length && tabs?.some((x) => !x.label)
    if (isAnyTabMissingLabel(this._tabs) || this._tabs?.some((tab) => isAnyTabMissingLabel(tab.subtabs))) {
      log.error(
        'All tabs must set text property or the text property on the icon to ensure that aria-label is set (including subtabs), not rendering.'
      )
      return false
    }

    const isAnyPillMissingLabel = (tabs: InternalTabBase[]) => tabs.length && tabs?.some((x) => x.pill && !x.pill.label)
    if (isAnyPillMissingLabel(this._tabs) || this._tabs?.some((tab) => isAnyPillMissingLabel(tab.subtabs))) {
      log.error('All pills must have a label for accessibility (includings subtabs), not rendering.')
      return false
    }

    return true
  }

  #onSlotchange(e: Event & { target: HTMLSlotElement }) {
    const $slotChildren = e.target.assignedElements()
    this._hasSlotContent = !!$slotChildren[0]
  }

  #getIconSize(isParent: boolean) {
    if (this.weight === 'low') {
      return '100'
    }
    if (this.weight === 'normal') {
      return isParent ? '200' : '100'
    }
    return isParent ? '300' : '200'
  }

  #setFocusedTab(tab: InternalTabBase) {
    if (tab.disabled) {
      return
    }
    this._focusedTab = tab
  }

  #setActiveTab(tab: InternalTabBase) {
    if (tab.disabled) {
      return
    }

    if (this.activeTab === tab.id) {
      this.#setFocusedTab(tab) // ensures active tab is actually focused
    } else {
      this.activeTab = tab.id
      this.dispatchEvent(new CustomEvent('tab', { detail: this.activeTab, composed: true }))
    }
  }

  #handleTabScroll(e: Event, direction: ScrollDirection) {
    switch (direction) {
      case 'left':
        scrollToLeft(e.target as HTMLElement)
        break
      case 'current':
        scrollToCurrent(e.target as HTMLElement)
        break
      case 'right':
        scrollToRight(e.target as HTMLElement)
        break
    }
  }

  #handleTabClicked(tab: InternalTabBase, activate: boolean) {
    this.#isFocusedByUser = true
    if (activate) {
      this.#setActiveTab(tab)
    } else {
      this.#setFocusedTab(tab)
    }
  }

  #handlefocus() {
    if (!this._focusedTab || this._focusedTab.type === 'sub') {
      return
    }

    const subtabs = (this._focusedTab as InternalTab).subtabs
    const subtab = subtabs.find((tab) => tab.isActive && !tab.disabled)
    if (subtab) {
      this.#setFocusedTab(subtab)
    }
  }

  #handleKeydown(e: KeyboardEvent) {
    if (e.target !== this.shadowRoot?.querySelector('[aria-activedescendant]')) {
      return
    }

    const handled = () => {
      e.preventDefault()
      e.stopPropagation()
    }

    this.#isFocusedByUser = true

    const tabs = getTabSiblings(this._tabs, this._focusedTab)

    switch (e.code) {
      case keyCodes.LEFT:
        {
          handled()
          const previousTab = getPreviousTab(tabs, this._focusedTab)
          if (!this.manual) {
            this.#setActiveTab(previousTab)
          } else {
            this.#setFocusedTab(previousTab)
          }
        }
        break
      case keyCodes.RIGHT:
        {
          handled()
          const nextTab = getNextTab(tabs, this._focusedTab)
          if (!this.manual) {
            this.#setActiveTab(nextTab)
          } else {
            this.#setFocusedTab(nextTab)
          }
        }
        break
      case keyCodes.DOWN:
        {
          if (this._focusedTab.type == 'parent') {
            const parentTab = this._focusedTab as InternalTab
            const childTab =
              parentTab.subtabs.find((x) => x.isActive && !x.disabled) || parentTab.subtabs.find((x) => !x.disabled)

            if (childTab) {
              handled()
              if (!this.manual) {
                this.#setActiveTab(childTab)
              } else {
                this.#setFocusedTab(childTab)
              }
            }
          }
        }
        break
      case keyCodes.UP:
        {
          const subtab = this._focusedTab as InternalSubtab
          if (subtab.parent) {
            handled()
            this.#setFocusedTab(subtab.parent)
          }
        }
        break
      case keyCodes.HOME:
        {
          handled()
          const firstTab = getFirstTab(tabs)
          if (!this.manual) {
            this.#setActiveTab(firstTab)
          } else {
            this.#setFocusedTab(firstTab)
          }
        }
        break
      case keyCodes.END:
        {
          handled()
          const lastTab = getLastTab(tabs)
          if (!this.manual) {
            this.#setActiveTab(lastTab)
          } else {
            this.#setFocusedTab(lastTab)
          }
        }
        break

      case keyCodes.RETURN:
      case keyCodes.NUMPADRETURN:
      case keyCodes.SPACE:
        {
          handled()
          this.#setActiveTab(this._focusedTab)
        }
        break

      case keyCodes.ESCAPE:
        {
          if (this._focusedTab.type === 'sub') {
            handled()
            const parentTab = (this._focusedTab as InternalSubtab).parent
            this.#setFocusedTab(parentTab)
          }
        }
        break
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tabs': OneUxTabsElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tabs': OneUxTabsElement
    }
  }
}
