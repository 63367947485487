import { HttpResult } from './HttpResult.js'

export const ApiErrors = {
  error,
  requestFailed,
  requestCancelled,
  requestErrorResponse
}
/***
 * NOTE: A compromise between usability and terseness  is made here by creating the error in all methods.
 * If you don't do it this way the resulting stack trace with have an additional call that muddles the nature of the error.
 * The way it is configured now the stacktrace wills show for something similar to "uncaught error at ApiErrors.requestFailed".
 * In other words, don't change the following even if it looks repetitive
 */

export type RequestError<T = string> = Error & {
  status: number
  data: T
  result?: HttpResult
}
const API_ERROR_STATUS = -1
function requestFailed(errorEvent: ProgressEvent) {
  const error = new Error('[PDR.api] Request failed') as RequestError<ProgressEvent>
  error.status = API_ERROR_STATUS
  error.data = errorEvent
  return error
}
const API_CANCELED_STATUS = -2
function requestCancelled() {
  const error = new Error('[PDR.api] Request cancelled') as RequestError
  error.status = API_CANCELED_STATUS
  error.data = error.message
  return error
}
function requestErrorResponse(result: HttpResult) {
  const error = new Error(
    `[PDR.api] Request received with HTTP failure code ${result.status}.`
  ) as RequestError<unknown>
  error.status = result.status
  error.data = result.response
  error.result = result
  return error
}

function error(message: string) {
  const error = new Error(message) as RequestError
  error.status = API_ERROR_STATUS
  error.data = message
  return error
}
