import { ReactiveController } from 'lit'
import { Constructor } from '../../../utils.js'
import { getLangCode, langCode } from '../../../utils/getLangCode.js'
import { OneUxElement } from '../../../OneUxElement.js'

export declare class ILocale {
  _locale: langCode
}

export const Lang = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class LangClass extends SuperClass {
    static get __one_ux_mixin_lang__() {
      return true as const
    }

    public _locale!: langCode

    constructor(...args: any[]) {
      super(...args)

      new LangController(this)
    }
  }

  return LangClass as Constructor<ILocale> & TSuperClass
}

type LangMixinElement = OneUxElement & ILocale

class LangController implements ReactiveController {
  constructor(private host: LangMixinElement) {
    this.host.addController(this)
  }

  // Could probably be removed, but remains in case of timing issues
  hostConnected(): void {
    this.#updateLang()
  }

  hostUpdate(): void {
    this.#updateLang()
  }

  #updateLang() {
    const langCode = getLangCode(this.host)
    this.host._locale = langCode
    this.host.setAttribute('lang', langCode)
  }
}
