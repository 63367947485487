import { PropertyValues, html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { provide } from '@lit/context'
import { ILabelContext, labelContext } from '../../contexts/LabelContext.js'
import { Label } from '../../mixins/Label.js'
import { InternalElementStateChangedEvent } from '../../events/internal/InternalElementStateChangedEvent.js'
import { classMap } from 'lit/directives/class-map.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Compact } from '../../mixins/Compact.js'

const Styled = StyledFactory(style)

const BaseClass = Compact(Label(Styled(OneUxElement)))

/**
 * Labels can either be visual or for accessibility only.
 * The component provides a label context that child components will use to set `aria-label` and its `one-ux-tooltip`.
 */
@customElement('one-ux-label')
export class OneUxLabelElement extends BaseClass {
  @provide({ context: labelContext })
  private _labelContext = {
    label: this.label
  } satisfies ILabelContext

  @state()
  private accessor _childComponentDisabled = false

  @property({ type: Boolean, attribute: 'screen-reader-only' })
  private accessor screenReaderOnly = false

  constructor() {
    super()
    this.addEventListener(InternalElementStateChangedEvent.eventName, (e: Event) => {
      const event = e as InternalElementStateChangedEvent
      const { property, value } = event.detail
      switch (property) {
        case 'disabled':
          this._childComponentDisabled = value as boolean
          break
      }
    })
  }

  protected willUpdate(changed: PropertyValues<this>) {
    const labelChanged = changed.has('label') && this._labelContext.label !== this.label
    if (labelChanged) {
      this._labelContext = {
        label: this.label
      }
    }
  }

  guardedRender() {
    const tooltip = !this.screenReaderOnly && this.compact ? this.label : ''
    return html`<div
      class="one-ux-element--root"
      @click=${this.#handleLabelClick}
      one-ux-tooltip=${ifDefined(tooltip || undefined)}
      ?one-ux-tooltip-custom-aria=${!!tooltip}
      ?one-ux-tooltip-fixed=${tooltip && this.compact}
    >
      <slot name="start">
        <slot></slot>
      </slot>
      ${this.screenReaderOnly || this.compact
        ? nothing
        : html`
            <div
              class=${classMap({
                label: true,
                'has-disabled-child': this._childComponentDisabled
              })}
              aria-hidden="true"
            >
              ${this.label}
            </div>
          `}
      <slot name="end"></slot>
    </div>`
  }

  #getFirstSlottedChild() {
    const $assigned = Array.from(
      this.shadowRoot?.querySelectorAll<HTMLSlotElement>('slot') || [],
      ($slot: HTMLSlotElement) => $slot.assignedElements() as HTMLElement[]
    ).flat()
    return $assigned[0]
  }

  #handleLabelClick(e: Event) {
    if (this._childComponentDisabled) {
      return
    }

    const $target = e.target as HTMLElement
    if (!this.shadowRoot?.contains($target)) {
      return
    }
    const $child = this.#getFirstSlottedChild()
    if ($child) {
      $child.click()
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-label': OneUxLabelElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-label': OneUxLabelElement
    }
  }
}
