import { provide } from '@lit/context'
import { html, nothing, PropertyValues } from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { tabsNextContext, ITabsNextContext } from './TabsNextContext.js'
import { Implicit } from '../../mixins/Implicit.js'
import { FocusableFactory } from '../../mixins/Focusable.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { Weight } from '../../mixins/Weight.js'
import { OneUxElement } from '../../OneUxElement.js'
import { OneUxTabNextElement } from '../one-ux-tab-next/OneUxTabNextElement.js'
import { maskStart, maskStretch, style } from './style.js'
import { Label } from '../../mixins/Label.js'
import { SlotController } from '../../controllers/SlotController.js'
import { setOverflow } from './setOverflow.js'
import { getLanguage } from './language.js'
import { Indicator } from '../one-ux-tab-next/Indicator.js'
import { UpdateOnResizedController } from '../../controllers/UpdateOnResizedController.js'
import { UpdateOnMutationController } from '../../controllers/UpdateOnMutationController.js'
import { keyCodes, scrollElementIntoView } from '../../utils.js'
import { animationOptions, deselectTabpanelAnimation, selectTabpanelAnimation } from './animations.js'
import { validEdges, type direction, type internalTab, type validEdge } from './types.js'
import { BeforeTabEvent, TabEvent } from './events.js'
import { log } from '../../utils/log.js'
import { TABBABLE_TARGETS_SELECTOR } from '../../utils/focusable.js'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const BaseClass = Label(Weight(Implicit(Focusable(Styled(OneUxElement)))))

@customElement('one-ux-tabs-next')
export class OneUxTabsNextElement extends BaseClass {
  @property({ type: String })
  public accessor selected = ''

  @property({ type: Boolean, attribute: 'show-add' })
  public accessor showAdd = false

  @property({ type: String, attribute: 'show-add-label' })
  public accessor showAddLabel = ''

  @property({ type: Array, attribute: 'collapse-edge' })
  public accessor collapseEdge: validEdge[] = []

  /** @internal */
  @state()
  accessor _tabs: internalTab[] = []

  /** @internal */
  @state()
  accessor _currentScrollDirection: direction | 'none' = 'none'

  /** @internal */
  @query('.tablist-nav')
  accessor _$tablistNav: HTMLDivElement | undefined = undefined

  /** @internal */
  @query('[role="tablist"]')
  accessor _$tablist!: HTMLDivElement

  /** @internal */
  @provide({ context: tabsNextContext })
  _tabsNextContext: ITabsNextContext = {
    implicit: this.implicit,
    weight: this.weight,
    isTablistFocused: false,
    updateTab: this.#updateTab.bind(this),
    changeTab: this.#handleTabChange.bind(this),
    onTabBlur: this.#handleTabBlur.bind(this),
    isFocused: this.#isActive.bind(this)
  }

  #updateOnMutationController: UpdateOnMutationController | undefined

  constructor() {
    super()
    new UpdateOnResizedController(this)
    this.#updateOnMutationController = new UpdateOnMutationController(this)
  }

  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('_tabs') && this._tabs.length) {
      const selectedTab = this._tabs.find((tab) => !tab.$el.disabled && tab.name === this.selected) ?? this._tabs[0]
      this.#setSelected(selectedTab)
      this.#activeTabName = selectedTab.name

      // TODO: This event dispatch should be removed once this element is no longer a preview
      this.dispatchEvent(new CustomEvent('__internaltab__', { detail: selectedTab.name }))
    }

    const hasContextChanged =
      changed.has('selected') ||
      (changed.has('implicit') && this._tabsNextContext.implicit !== this.implicit) ||
      (changed.has('weight') && this._tabsNextContext.weight !== this.weight) ||
      (changed.has('hasKeyboardFocus') && changed.get('hasKeyboardFocus') !== this.hasKeyboardFocus)
    if (hasContextChanged) {
      this.#updateContext({
        implicit: this.implicit,
        weight: this.weight,
        isTablistFocused: this.hasKeyboardFocus && this._tabs.some((tab) => tab.$el === document.activeElement)
      })
    }
  }

  protected firstUpdated(): void {
    if (this.#hasFixedContent) {
      const $fixedContent = this.querySelector('[slot="fixed-content"]') as HTMLElement
      this.#updateOnMutationController?.observe($fixedContent)
    }
  }

  protected updated(): void {
    if (this._$tablistNav) {
      setOverflow(this._$tablistNav)
    }
  }

  #id = crypto.randomUUID()

  protected guardedRender() {
    const { languageKey, languageSet } = getLanguage(this)

    const hasNestedTabs = (name: string) =>
      Array.from(this.querySelectorAll(`:scope > [slot='${name}']`)).every(($el) => $el instanceof OneUxTabsNextElement)

    return html`<div class="one-ux-element--root" lang=${languageKey}>
      <div
        class=${classMap({
          header: true,
          'has-header-end-content': this.#slots.hasNamedSlot('header-end')
        })}
        role="group"
        aria-label=${languageSet.header}
      >
        <div class="tablist-nav">
          <one-ux-button
            aria-hidden="true"
            tabindex="-1"
            label=${languageSet.scrollLeft}
            class="nav-left"
            weight="low"
            implicit
            @click=${() => this.#scroll('left')}
          >
            <one-ux-icon icon="toggle-left"></one-ux-icon>
          </one-ux-button>
          <div
            role="tablist"
            aria-label=${this.label}
            @scroll=${this.#handleTablistScroll}
            scroll-direction=${this._currentScrollDirection}
            @keydown=${this.#handleKeydown}
          >
            ${this.#selectedTab &&
            Indicator({
              active: true,
              width: this.#selectedTab.$el.getBoundingClientRect().width,
              implicit: this.implicit,
              purpose: this.#selectedTab.$el.purpose,
              isKeyboardFocused: this._tabsNextContext.isTablistFocused,
              weight: this.weight,
              posLeft:
                this.#selectedTab.$el.getBoundingClientRect().left -
                this._$tablist.getBoundingClientRect().left +
                this._$tablist.scrollLeft
            })}
            <slot @slotchange=${this.#handleDefaultSlotChange}></slot>
            ${this.showAdd
              ? html`<one-ux-tab-next id="add-tab" label=${this.showAddLabel || languageSet.add}>
                  <one-ux-icon icon="add" slot="start"></one-ux-icon>
                </one-ux-tab-next>`
              : nothing}
          </div>
          <one-ux-button
            aria-hidden="true"
            tabindex="-1"
            label=${languageSet.scrollRight}
            class="nav-right"
            weight="low"
            implicit
            @click=${() => this.#scroll('right')}
          >
            <one-ux-icon icon="toggle-right"></one-ux-icon>
          </one-ux-button>
        </div>
        <slot name="header-end"></slot>
      </div>

      <div class="tabpanel-container" state-collapse-edge=${ifDefined(this.#getCollapseEdge())}>
        <div
          role="tabpanel"
          id=${`fixed-content-${this.#id}`}
          aria-labelledby=${this.#selectedTab?.tabRef}
          class=${classMap({
            'is-selected': this.#hasFixedContent,
            'has-nested-tabs': hasNestedTabs('fixed-content')
          })}
          style=${styleMap({
            display: this.#hasFixedContent ? 'block' : 'none'
          })}
        >
          <slot name="fixed-content"></slot>
        </div>

        ${this.#hasFixedContent
          ? nothing
          : this._tabs.map((tab) => {
              return html`<div
                role="tabpanel"
                id=${tab.tabpanelRef}
                aria-labelledby=${tab.tabRef}
                tabindex=${this.#hasTabbableContent(tab) ? '-1' : '0'}
                class=${classMap({
                  'is-selected': this.#isSelected(tab.$el),
                  'has-nested-tabs': hasNestedTabs(tab.name)
                })}
              >
                <slot name=${tab.name}></slot>
              </div>`
            })}
      </div>
    </div>`
  }

  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['header-end', 'fixed-content']
  })

  #hasTabbableContent(tab: internalTab): boolean {
    const $slot = this.shadowRoot!.querySelector<HTMLSlotElement>(`#${tab.tabpanelRef} slot`)
    const hasTabbableContentPredicate = ($el: Element): boolean =>
      $el.matches(TABBABLE_TARGETS_SELECTOR) || !!$el.querySelector(TABBABLE_TARGETS_SELECTOR)

    return $slot?.assignedElements().some(hasTabbableContentPredicate) ?? false
  }

  #getCollapseEdge() {
    if (!this.collapseEdge?.length) {
      return undefined
    }

    for (const edge of this.collapseEdge) {
      if (!(validEdges as unknown as string[]).includes(edge)) {
        log.warning(`Attribute "collapse-edge" can only be ${validEdges.join()}, ignoring provided value "${edge}".`)
      }
    }

    return this.collapseEdge.join(' ')
  }

  #scroll(direction: direction) {
    this._currentScrollDirection = direction
    const $tabs = this._tabs.map((tab) => tab.$el)

    if (this.showAdd) {
      const $addTab = this.shadowRoot!.querySelector<OneUxTabNextElement>('#add-tab')!
      $tabs.push($addTab)
    }

    const tablistRect = this._$tablist.getBoundingClientRect()

    const getScrollLeft = ($tabs: OneUxTabNextElement[]) => {
      const $partlyHiddenTab = $tabs.reverse().find(($tab) => this.#isHiddenToLeft($tab))

      const scrollAmount =
        $partlyHiddenTab!.getBoundingClientRect().right -
        tablistRect.left -
        $partlyHiddenTab!.getBoundingClientRect().width -
        maskStart

      return scrollAmount
    }

    const getScrollRight = ($tabs: OneUxTabNextElement[]) => {
      const $partlyHiddenTab = $tabs.find(($tab) => this.#isHiddenToRight($tab))

      const scrollAmount =
        $partlyHiddenTab!.getBoundingClientRect().left -
        tablistRect.left -
        tablistRect.width +
        $partlyHiddenTab!.getBoundingClientRect().width +
        maskStart

      return scrollAmount
    }

    const scrollAmount = direction === 'left' ? getScrollLeft($tabs) : getScrollRight($tabs)
    if (!scrollAmount) return
    this._$tablist.scrollBy({ left: scrollAmount })
    this.#scrollByButton = true
  }

  #isHiddenToLeft($tab: OneUxTabNextElement) {
    const leftEdge = $tab.getBoundingClientRect().left - this._$tablist.getBoundingClientRect().left
    return leftEdge < 0
  }

  #isHiddenToRight($tab: OneUxTabNextElement) {
    const tablistRect = this._$tablist.getBoundingClientRect()
    const rightEdge = $tab.getBoundingClientRect().right - tablistRect.left
    return rightEdge > tablistRect.width
  }

  #isPartlyHidden($tab: OneUxTabNextElement) {
    return this.#isHiddenToLeft($tab) || this.#isHiddenToRight($tab)
  }

  #scrollByButton = false

  #scrollTimeoutId: ReturnType<typeof setTimeout> | undefined
  #scrollOutOfViewTimeoutId: ReturnType<typeof setTimeout> | undefined
  #handleTablistScroll() {
    if (this.#scrollByButton) {
      clearTimeout(this.#scrollTimeoutId)
    }

    this.#scrollTimeoutId = setTimeout(() => {
      if (!this.#scrollByButton) {
        this._currentScrollDirection = 'none'
      }

      this.#scrollByButton = false
    }, 100)

    if (this._$tablistNav) {
      setOverflow(this._$tablistNav)
    }

    if (this.#scrollOutOfViewTimeoutId) {
      clearTimeout(this.#scrollOutOfViewTimeoutId)
    }

    const scrollIdleTimeout = 5000
    this.#scrollOutOfViewTimeoutId = setTimeout(() => {
      if (!this.#isPartlyHidden(this.#selectedTab.$el)) {
        return
      }
      this._currentScrollDirection = 'none'
      this.#scrollIntoView(this.#selectedTab.$el)
    }, scrollIdleTimeout)
  }

  #makeTab($el: OneUxTabNextElement, index: number): internalTab {
    const name = $el.name || `tab-${index + 1}`
    const tabRef = `tab_${this.#id}_${name}`
    const tabpanelRef = this.#hasFixedContent ? `fixed-content-${this.#id}` : `tabpanel_${this.#id}_${name}`

    $el.name = name

    return {
      index,
      $el,
      name,
      tabRef,
      tabpanelRef
    }
  }

  /** @internal */
  _updateDefaultSlot() {
    this.#handleDefaultSlotChange()
  }

  #handleDefaultSlotChange() {
    const $defaultSlot = this.shadowRoot!.querySelector<HTMLSlotElement>('slot:not([name])')

    if (!$defaultSlot) return

    const $tabs = $defaultSlot
      .assignedElements({ flatten: true })
      .filter<OneUxTabNextElement>((el) => el instanceof OneUxTabNextElement)
    this._tabs = $tabs.map<internalTab>(($tab, index) => this.#makeTab($tab, index))
    this._tabs.forEach((tab) => {
      tab.$el.provideTabPanelRefs(tab.tabRef, tab.tabpanelRef)
    })
  }

  #isSelected($tab: OneUxTabNextElement) {
    return this.selected === $tab.name
  }

  #isActive($tab: OneUxTabNextElement) {
    return this.#activeTabName === $tab.name
  }

  get #selectedTab() {
    return this._tabs.find((tab) => tab.name === this.selected)!
  }

  #updateTab($tab: OneUxTabNextElement) {
    const index = this._tabs.findIndex((tab) => tab.$el === $tab)
    if (index !== -1) {
      this._tabs[index] = this.#makeTab($tab, index)
      this.requestUpdate()
    }
  }

  #previousTab: internalTab | undefined
  #setSelected(tab: internalTab) {
    this.#previousTab = this.#selectedTab
    this.selected = tab.name
    this.#activeTabName = tab.name
    this.#updateContext()
  }

  #activeTabName = ''
  #setActive(tab: internalTab) {
    this.#activeTabName = tab.name
    tab.$el.focus()
    this.#updateContext()
  }

  async #handleTabChange($tab: OneUxTabNextElement) {
    const dispatchEvent = (event: BeforeTabEvent | TabEvent) => this.dispatchEvent(event)

    const tab = this._tabs.find((tab) => tab.$el === $tab)
    if (!tab || $tab === this.#selectedTab.$el) return

    if (!dispatchEvent(new BeforeTabEvent(tab.name))) {
      return
    }

    this.#setSelected(tab)
    this._currentScrollDirection = 'none'
    this.#scrollIntoView($tab)
    if (!this.#hasFixedContent) {
      await this.#animateTabChange()
    }
    dispatchEvent(new TabEvent(tab.name))
  }

  async #animateTabChange() {
    if (!this.#previousTab || this.#previousTab === this.#selectedTab) return Promise.resolve()

    const $previousTabpanel = this.shadowRoot!.querySelector(`#${this.#previousTab.tabpanelRef}`) as HTMLElement
    const $nextTabpanel = this.shadowRoot!.querySelector(`#${this.#selectedTab.tabpanelRef}`) as HTMLElement

    const direction: number = Math.sign(this.#selectedTab.index - this.#previousTab.index)
    const selectedAnimation = $nextTabpanel.animate(selectTabpanelAnimation(direction), animationOptions)
    const deselectAnimation = $previousTabpanel.animate(deselectTabpanelAnimation(-direction), animationOptions)

    await Promise.all([deselectAnimation.finished, selectedAnimation.finished])
  }

  #scrollIntoView($tab: OneUxTabNextElement) {
    scrollElementIntoView(this._$tablist, $tab, 'horizontal', maskStretch)
  }

  get #hasFixedContent() {
    return this.#slots.hasNamedSlot('fixed-content')
  }

  #handleTabBlur(event: FocusEvent) {
    if (!this._tabs.some((tab) => tab.$el === event.relatedTarget)) {
      this.#activeTabName = this.selected
      this.#updateContext()
    }
  }

  #updateContext(updatedContext: Partial<ITabsNextContext> = {}) {
    this._tabsNextContext = {
      ...this._tabsNextContext,
      ...updatedContext
    }
  }

  #handleKeydown(event: KeyboardEvent) {
    const handled = () => {
      event.preventDefault()
      event.stopPropagation()
    }

    const activeIndex = this._tabs.findIndex((tab) => tab.name === this.#activeTabName)

    switch (event.code) {
      case keyCodes.LEFT:
        if (activeIndex === 0) return
        this.#setActive(this._tabs[activeIndex - 1])
        handled()
        break
      case keyCodes.RIGHT:
        if (activeIndex === this._tabs.length - 1) return
        this.#setActive(this._tabs[activeIndex + 1])
        handled()
        break
      case keyCodes.HOME:
        this.#setActive(this._tabs[0])
        handled()
        break
      case keyCodes.END:
        this.#setActive(this._tabs[this._tabs.length - 1])
        handled()
        break
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tabs-next': OneUxTabsNextElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tabs-next': OneUxTabsNextElement
    }
  }
}
