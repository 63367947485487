import { Implementation } from '../types/Implementation.js'
import { MicrofrontendApplication, MicrofrontendApplicationModule } from '../types/types.js'
import { Action } from './Action.js'
export class Install extends Action {
  implementation: Implementation
  constructor(implementation: Implementation) {
    super()
    this.implementation = implementation
  }

  execute() {
    const className = this.getClassName()
    if (!window[className]) {
      window[className] = registerCustomElement(this.implementation)
      this.completed = true
    }
    return window[className]
  }

  getClassName() {
    const parts = this.implementation.tag.split('-').map((x) => x.substring(0, 1).toUpperCase() + x.substring(1))
    return `PDRMicrofrontend${parts.join('')}`
  }
}

function registerCustomElement(implementation: Implementation) {
  const {
    tag,
    methods,
    version,
    placeholder,
    style,
    provide,
    root,
    attributes,
    useMutationObserverForAttributes,
    cleanupDelay
  } = implementation
  async function getApplication() {
    await Promise.all(
      Object.keys(provide).map((name) => {
        const provided = provide[name]
        return window.PDR.mfe.provide({
          source: tag,
          name,
          version: provided.version,
          value: provided.value
        })
      })
    )
    return implementation.application()
  }
  class CustomElement extends HTMLElement {
    #import: Promise<MicrofrontendApplicationModule>
    #placeholderElement = placeholder()
    #rootElement!: HTMLElement
    #initialized = false
    #cleanUpToken: ReturnType<typeof setTimeout> | null = null
    #currentRunId = 1
    app: Promise<MicrofrontendApplication> | null = null

    constructor() {
      super()
      this.#import = getApplication()
      if (useMutationObserverForAttributes) {
        // TODO: Deprecate
        const observer = new MutationObserver((mutationList) => {
          mutationList.forEach((mutation) => {
            if (mutation.type === 'attributes') {
              this.attributeChangedCallback(
                mutation.attributeName || '',
                mutation.oldValue || '',
                this.getAttribute(mutation.attributeName || '') || ''
              )
            }
          })
        })
        observer.observe(this, {
          attributes: true,
          attributeOldValue: true
        })
      }
      const methodEntries = Object.entries(methods || {})
      for (const [methodName, methodImplementation] of methodEntries) {
        const _this = this as unknown as Record<string, (...args: unknown[]) => Promise<unknown>>
        _this[methodName] = async (...args: unknown[]) => {
          await this.#import
          return methodImplementation.call(this, ...args)
        }
      }
    }

    static get version() {
      return version
    }

    get version() {
      return version
    }

    async connectedCallback() {
      this.#abortCleanUp()
      this.#initializeCustomElement()
      this.#runApplication()
    }

    disconnectedCallback() {
      this.#invalidateRunId()
      this.#queueCleanUp()
    }

    static get observedAttributes() {
      return attributes.map((attribute) => (typeof attribute === 'string' ? attribute : attribute.name))
    }

    async attributeChangedCallback(name: string, _oldValue: string, newValue: string) {
      if (this.app) {
        const app = await this.app
        app.update(name, newValue)
      }
    }

    #initializeCustomElement() {
      if (!this.#initialized) {
        Object.assign(this.style, style)
        this.#initialized = true
      }
      if (!this.app && this.#placeholderElement) {
        this.append(this.#placeholderElement)
      }
    }

    async #runApplication() {
      const runId = this.#currentRunId
      const application = await this.#import
      const shouldRun = runId === this.#currentRunId && !this.app
      if (shouldRun) {
        while (this.firstChild) {
          this.removeChild(this.lastChild!)
        }
        this.#rootElement = root ? document.createElement(typeof root === 'string' ? root : 'div') : this
        if (this.#rootElement !== this) {
          this.appendChild(this.#rootElement)
        }
        this.app = Promise.resolve(application.main(this.#rootElement, this.#initialAttributes()))
      }
    }

    #initialAttributes() {
      if (useMutationObserverForAttributes) {
        // TODO: Deprecate
        return Array.from(this.attributes).reduce(
          (result, attr) => Object.assign(result, { [attr.name]: attr.value }),
          {}
        )
      }
      return attributes.reduce((result, attr) => {
        const attrName = typeof attr === 'string' ? attr : attr.name
        if (typeof attr !== 'string') {
          if (!this.hasAttribute(attrName) && typeof attr.default !== 'undefined') {
            this.setAttribute(attrName, attr.default)
          }
        }
        return Object.assign(result, {
          [attrName]: this.getAttribute(attrName)
        })
      }, {})
    }

    #invalidateRunId() {
      ++this.#currentRunId
    }

    #queueCleanUp() {
      this.#abortCleanUp()
      this.#cleanUpToken = setTimeout(() => {
        if (this.app) {
          this.app.then((app) => {
            if (this.#cleanUpToken) {
              app.destroy()
            }
          })
          this.app = null
        }
      }, cleanupDelay)
    }

    #abortCleanUp() {
      if (this.#cleanUpToken !== null) {
        clearTimeout(this.#cleanUpToken)
        this.#cleanUpToken = null
      }
    }
  }
  customElements.define(tag, CustomElement)
  return customElements.get(tag)
}
