let mouseMoveTimeout: ReturnType<typeof setTimeout>
let pendingMouseInactive: ReturnType<typeof setTimeout>
let mouseMoving = false
let mouseActive = false
const oneUxKeyboardOnlyAttribute = 'one-ux-keyboard-only'

document.addEventListener(
  'mousemove',
  () => {
    clearTimeout(mouseMoveTimeout)
    mouseMoveTimeout = setTimeout(() => {
      mouseMoving = false
    }, 100)
    mouseMoving = true
  },
  {
    capture: true
  }
)

const updateMouseActivity = () => {
  mouseActive = true
  document.body?.removeAttribute(oneUxKeyboardOnlyAttribute)
  clearTimeout(pendingMouseInactive)
  pendingMouseInactive = setTimeout(() => {
    mouseActive = false
  }, 500)
}
document.addEventListener('mousedown', updateMouseActivity, { capture: true })
document.addEventListener('mouseup', updateMouseActivity, { capture: true })

document.addEventListener(
  'focus',
  () => {
    document.body?.toggleAttribute(oneUxKeyboardOnlyAttribute, !mouseActive && !mouseMoving)
  },
  true
)
