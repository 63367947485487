import { Version } from '../Version.js'
import { NumberedVersion } from './NumberedVersion.js'

export class PrereleaseVersion extends NumberedVersion {
  prerelease: string
  constructor(versionString: string) {
    super(versionString, 'prerelease')
    this.prerelease = versionString.split('-').slice(1).join('-')
  }

  fulfills(version: Version): boolean {
    if (version instanceof PrereleaseVersion) {
      return super.equal(version) && this.prerelease === version.prerelease
    }
    return false
  }

  static matches(versionString: string) {
    return versionString.includes('-')
  }
}
