import { CSSResult } from 'lit'
import { OneUxElement } from '../OneUxElement.js'
import { Constructor } from '../utils.js'

export const StyledFactory = (style: CSSResult) => {
  return <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
    class StyledClass extends SuperClass {
      // TODO: fix any
      static styles = [...((SuperClass as any)?.styles ?? []), style]
    }
    return StyledClass as TSuperClass
  }
}
