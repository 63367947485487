import { Version } from '../../Version.js'
import { NumberedVersion } from '../NumberedVersion.js'

export abstract class WildcardVersion extends NumberedVersion {
  constructor(versionString: string) {
    super(versionString, 'wildcard')
  }

  abstract includes(version: Version): boolean
}
