import { Version } from '../Version.js'
import { SpecialVersion } from './SpecialVersion.js'

const keyword = 'stable'
export class StableVersion extends SpecialVersion {
  constructor() {
    super(keyword)
  }

  fulfills(version: Version) {
    return version instanceof StableVersion
  }

  static matches(versionString: string) {
    return versionString === keyword
  }
}
