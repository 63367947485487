import { ReactiveController } from 'lit'
import { ChildRefs, OneUxScrollElement } from './OneUxScrollElement.js'

export class PositionController implements ReactiveController {
  private updateScrollbarsRequest = 0

  public top = 0
  public left = 0
  public width = 0
  public height = 0
  public showY = false
  public showX = false
  public gutterX = false
  public gutterY = false

  constructor(
    private element: OneUxScrollElement,
    private refs: ChildRefs
  ) {
    this.element.addController(this)
  }

  hostConnected() {
    this.updateScrollbars()
  }

  hostDisconnected() {
    cancelAnimationFrame(this.updateScrollbarsRequest)
  }

  private updateScrollbars() {
    cancelAnimationFrame(this.updateScrollbarsRequest)
    this.updateScrollbarsRequest = requestAnimationFrame(() => {
      const $content = this.refs.$content.value!

      const top = (100 * $content.scrollTop) / $content.scrollHeight
      const left = (100 * $content.scrollLeft) / $content.scrollWidth
      const height = (100 * $content.clientHeight) / $content.scrollHeight
      const width = (100 * $content.clientWidth) / $content.scrollWidth
      const showY = $content.scrollHeight - $content.clientHeight > 0
      const showX = $content.scrollWidth - $content.clientWidth > 0

      let shouldUpdate =
        this.top != top ||
        this.left != left ||
        this.height != height ||
        this.width != width ||
        this.showY != showY ||
        this.showX != showX

      this.top = top
      this.left = left
      this.height = height
      this.width = width
      this.showY = showY
      this.showX = showX

      const $element: OneUxScrollElement = this.element
      const gutterX = this.showX && $element.gutter
      const gutterY = this.showY && $element.gutter

      if (this.gutterX !== gutterX || this.gutterY !== gutterY) {
        shouldUpdate = true
      }
      this.gutterX = gutterX
      this.gutterY = gutterY

      if (shouldUpdate) {
        $element.requestUpdate()
      }
      this.updateScrollbars()
    })
  }
}
